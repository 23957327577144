import styled from "styled-components";

export const ModalStyles = styled.div`
.modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-button {
    position: absolute;
    top: 10px;  
    right: 10px; 
    background: transparent; 
    border: none;  
    font-size: 20px; 
    cursor: pointer;   
    color: #333;   
}
`;