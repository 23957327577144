import { useState, useEffect, useRef } from "react";
import fetchMergeRequests from "./fetchMergeRequests";
import { useUserAuth } from "../../UserAuthContext";
import magnifyingGlass from "../../Assets/Images/magnifying-glass.png";
import fetchCurrentFriends from "../friends/fetchCurrentFriends";
import fetchMergeResults from "./fetchMergeResults";
import { SearchBarStyles } from "../profile-helpers/SearchBarStyles";
import { MergeHubStyles } from "./MergeHubStyles";
import checkmarkIcon from "../../Assets/Images/checkmarkIcon.png";
import xIcon from "../../Assets/Images/xIcon.png";
import profileImage from "../../Assets/Images/defaultAccountImage.jpg";
import fetchUserProfile from "../friends/fetchUserProfile";
import Modal from "../../Modals/survey/Modal";
import SelectResultsForMerge from "./SelectResultsForMerge";
import declineMergeRequest from "./declineMergeRequest";
import { performMerge } from "./performMerge";
import { ResultBox, ProfileStyles } from "../profile-helpers/profile-styles";
import DisplayResults from "../game-helpers/DisplayResults";
import { DestinationRankings } from "../survey-helpers/suggestion-algorithm/suggestion-data";
import mergeIcon from "../../Assets/Images/merge.png";
import createMergeRequest from "./createMergeRequest";
import performGroupMerge from "./performGroupMerge";
import fetchGroupResults from "./fetchGroupResults";

const MergeHub = () => {
    const { user, userName, firstName, lastName } = useUserAuth();
    const [mergeResults, setMergeResults] = useState([]);
    const [mergeRequests, setMergeRequests] = useState([]);
    const [currentFriends, setCurrentFriends] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const resultsRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [isFriendCollapsed, setIsFriendCollapsed] = useState({});
    const [isGroupCollapsed, setIsGroupCollapsed] = useState({});
    const [viewingResults, setViewingResults] = useState(false);
    const [selectedResult, setSelectedResult] = useState(null);
    const [selectedFriendId, setSelectedFriendId] = useState(null);
    const [groupResults, setGroupResults] = useState([]);
    const [isGroupMerge, setIsGroupMerge] = useState(false);

    
    //Load result, request, and friend data on startup
    useEffect(() => {
        const fetchFriends = async () => {
            const currentFriendsData = await fetchCurrentFriends(user.uid);

            const detailedFriends = await Promise.all(currentFriendsData.map(async friend => {
                const profile = await fetchUserProfile(friend.userId);
                return {
                    ...friend,
                    ...profile,
                };
            }));

            setCurrentFriends(detailedFriends);
        };

        const fetchRequests = async () => {
            const mergeRequestsData = await fetchMergeRequests(user.uid);
            setMergeRequests(mergeRequestsData);
        };

        const fetchResults = async () => {
            const mergeResultsData = await fetchMergeResults(user.uid);
            
            // Group results by friend (userId)
            const groupedResults = mergeResultsData.reduce((acc, mergeDoc) => {
                mergeDoc.userIds.forEach((userId) => {
                    if (userId !== user.uid) { // Exclude the current user
                        if (!acc[userId]) {
                            acc[userId] = {
                                friendName: mergeDoc.userNames[userId],
                                name: mergeDoc.name,
                                mergedAttributeTotals: [],
                                top8Destinations: [],
                            };
                        }
                        // Merge `mergedAttributeTotals` and `top8Destinations`
                        acc[userId].mergedAttributeTotals.push(mergeDoc.mergedAttributeTotals);
                        acc[userId].top8Destinations.push(mergeDoc.top8Destinations);
                    }
                });
                return acc;
            }, {});

            setMergeResults(groupedResults);

            // Set all groups to be open by default
            const initialCollapseState = {};
            Object.keys(groupedResults).forEach(userId => {
                initialCollapseState[userId] = false;
            });
            setIsFriendCollapsed(initialCollapseState);

            //Group merge results
            const groupResults = await fetchGroupResults(user.uid);

            const groupedResultsByGroup = groupResults.reduce((acc, mergeDoc) => {
                const groupId = mergeDoc.groupId;
                if (!acc[groupId]) {
                    acc[groupId] = {
                        groupName: mergeDoc.name.replace(/'s merge$/, '').trim(),
                        mergeName: mergeDoc.name,
                        mergedAttributeTotals: [],
                        top8Destinations: [],
                        includedMembers: mergeDoc.includedMembers,
                    };
                }
        
                acc[groupId].mergedAttributeTotals.push(mergeDoc.attributeTotals);
                acc[groupId].top8Destinations.push(mergeDoc.top8Destinations);
        
                return acc;
            }, {});

            setGroupResults(groupedResultsByGroup);
            console.log(groupResults);
            
            //Set group initial collapse state
            const initialCollapseStateByGroup = {};
            Object.keys(groupedResultsByGroup).forEach(groupId => {
                initialCollapseStateByGroup[groupId] = false;
            });
            setIsGroupCollapsed(initialCollapseStateByGroup);
        };
        
        fetchFriends();
        fetchRequests();
        fetchResults();
    }, [user.uid]);

    //Hide search results when clicking outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (resultsRef.current && !resultsRef.current.contains(e.target)) {
                setSearchResults([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value === "") {
            setSearchResults([]);
        }
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const handleSearch = async () => {
        if (searchQuery.includes('@') && !searchQuery.startsWith('@')) {
            const results = currentFriends.filter(friend =>
                friend.email.includes(searchQuery)
            );
            setSearchResults(results);
        } else if (searchQuery.includes(' ')) {
            const [firstName, lastName] = searchQuery.split(' ');
            const capitalizedFirstName = capitalize(firstName);
            const capitalizedLastName = capitalize(lastName);
            const capitalizedName = `${capitalizedFirstName} ${capitalizedLastName}`;

            const results = currentFriends.filter(friend => 
                friend.name === capitalizedName
            );
            setSearchResults(results);
        } else {
            const results = currentFriends.filter(friend => 
                friend.userName === searchQuery
            );
            setSearchResults(results);
        }
    };

    const handleMergeRequestRespnse = async (request, accept) => {
        if (accept) {
            setIsModalOpen(true);
            setSelectedRequest(request);
        } else {
            await declineMergeRequest(user.uid, request.id);
        }
    };

    const handleResultSelect = async (result, subcollection) => {
        //Function used on both ends of request, sending and responding
        if (selectedFriendId) {
            setLoading(true);
            setError(null);
            setSuccess(false);
            
            try {
                await createMergeRequest(user.uid, `${firstName} ${lastName}`, selectedFriendId, result, subcollection);
                setSuccess(true);
            } catch (error) {
                console.error('Error sending merge request:', error);
                setError(error.message || 'Failed to send merge request');
            } finally {
                setLoading(false);
                setSelectedFriendId(null);
            }
        } else {
            setIsModalOpen(false);

            if (!isGroupMerge) {
                //Standard merge
                const newMergeResult = await performMerge(user.uid, firstName, lastName, result, selectedRequest);
    
                //Update the mergeResults object by adding the new merge result for the specific userId
                setMergeResults(prevResults => {
                    const userId = selectedRequest.id;
                    return {
                        ...prevResults,
                        [userId]: {
                            ...prevResults[userId],
                            mergedAttributeTotals: [
                                ...prevResults[userId].mergedAttributeTotals,
                                newMergeResult.mergedAttributeTotals
                            ],
                            top8Destinations: [
                                ...prevResults[userId].top8Destinations,
                                ...newMergeResult.top8Destinations
                            ]
                        }
                    };
                });
            } else {
                //Group merge
                const newMergeResult = await performGroupMerge(user.uid, result, selectedRequest);

                console.log(newMergeResult);
            }

            setMergeRequests(prevRequests => prevRequests.filter(req => req.id !== selectedRequest.id));
        }
    };

    const onClose = () => {
        setIsModalOpen(false);
    };

    const toggleFriendCollapse = (id) => {
        setIsFriendCollapsed((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const toggleGroupCollapse = (groupId) => {
        setIsGroupCollapsed((prevState) => ({
            ...prevState,
            [groupId]: !prevState[groupId],
        }));
    };

    const handleViewResults = (top8Destinations) => {
        // Map the city names to their corresponding data from DestinationRankings
        const enrichedRankings = top8Destinations.map(city => ({
          city,
          ...DestinationRankings[city]
        }));
    
        setSelectedResult({
          rankings: enrichedRankings,
        });
        setViewingResults(true);
    }

    const handleMergeClick = (friendUserId) => {
        setSearchResults([]);
        setIsModalOpen(true);
        setSelectedFriendId(friendUserId);
    };

    const handleGroupMergeRequestRespnse = async (request, accept) => {
        if (accept) {
            setIsGroupMerge(true);
            setIsModalOpen(true);
            setSelectedRequest(request);
        } else {
            await declineMergeRequest(request.id, user.uid);
        }
    };

    if (viewingResults) {
        return ( 
            <ProfileStyles>
                <div>
                    <div className='header'>
                        <button onClick={() => setViewingResults(false)} className='back-button'>
                            ← Back
                        </button>
                    </div>
                    <div className='profile-container'>
                        <DisplayResults
                            rankings={selectedResult.rankings}
                            DestinationRankings={DestinationRankings}
                        />
                     </div>
                </div>
            </ProfileStyles>
        )
    }

    return (
        <MergeHubStyles>
            <div className="merge-hub">
                {/* Merge modal to display over merge hub */}
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <SelectResultsForMerge 
                        userId={user.uid}
                        onClose={onClose}
                        onSelect={handleResultSelect}
                        loading={loading}
                        error={error}
                        success={success}
                    />
                </Modal>

                <SearchBarStyles>
                    <div className="search-container">
                        <div className="search-bar-container">
                            <input
                                className="friend-search-bar"
                                type="text"
                                placeholder="Search for friends by name, username, or email"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                            />
                            <button onClick={handleSearch} className="search-button">
                                <img src={magnifyingGlass} className='search-icon' alt="Search" />
                            </button>
                        </div>
                        <div className="search-results" ref={resultsRef}>
                            {searchResults.length > 0 && (
                                <ul>
                                    {searchResults.map((result) => (
                                        <li key={result.id} className="search-item">
                                            <span>{result.firstName} {result.lastName} (@{result.userName})</span>
                                            <button className="merge-button" onClick={() => handleMergeClick(result.userId)}>
                                                Merge Results
                                                <img src={mergeIcon} className="merge-icon" alt="merge"/>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </SearchBarStyles>
                        
                <div className="merge-hub-content">
                    <div className="results-container">
                        <div className="merge-results">
                            <h3>Merge Results</h3>
                            {Object.keys(mergeResults).length > 0 ? (
                                <ul>
                                    {Object.entries(mergeResults).map(([userId, userResults]) => (
                                        <li key={userId}>
                                            {/* Clickable heading to toggle section visibility */}
                                            <h4
                                                onClick={() => toggleFriendCollapse(userId)}
                                                className="friend-group-heading"
                                            >
                                                <span>{userResults.friendName}</span> {/* Friend name on the left */}
                                                <span>{isFriendCollapsed[userId] ? "▲" : "▼"}</span> {/* Arrow on the right */}
                                            </h4>

                                            {/* Conditionally display the friend's results */}
                                            {!isFriendCollapsed[userId] && (
                                                <div className="friend-results">
                                                    {userResults.mergedAttributeTotals.map((mergedTotal, index) => (
                                                      <ResultBox key={index}>
                                                        <div className='heading'>
                                                          <h4>{userResults.name} {index + 1}</h4>
                                                        </div>
                                                        <div className='summary'>
                                                          <p>Top Destination: {userResults.top8Destinations[index][0]}</p>
                                                          <button onClick={() => handleViewResults(userResults.top8Destinations[index])} className='see-more-button'>
                                                            See More →
                                                          </button>
                                                        </div>
                                                      </ResultBox>
                                                    ))}
                                              </div>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="empty-text">No merge results</p>
                            )}
                        </div>
                    </div>

                    <div className="groups-container">
                        <div className="group-results">
                            <h3>Group Results</h3>
                            {Object.keys(groupResults).length > 0 ? (
                                <ul>
                                    {Object.keys(groupResults).map((groupId) => {
                                        const group = groupResults[groupId];

                                        return (
                                            <li key={groupId} className="group-object">
                                                <div className="group-info">
                                                    {/* Clickable heading to toggle section visibility */}
                                                    <h4
                                                        onClick={() => toggleGroupCollapse(groupId)}
                                                        className="friend-group-heading"
                                                    >
                                                        <span>{group.groupName}</span> {/* Group name on the left */}
                                                        <span>{isGroupCollapsed[groupId] ? "▲" : "▼"}</span> {/* Arrow on the right */}
                                                    </h4>
                                        
                                                    {/* Collapsible section */}
                                                    {!isGroupCollapsed[groupId] && (
                                                        <div className="friend-results">
                                                            {group.mergedAttributeTotals.map((mergedTotal, index) => (
                                                                <ResultBox key={index}>
                                                                  <div className='heading'>
                                                                    <h4>{group.mergeName} {index + 1}</h4>
                                                                  </div>
                                                                  <div className='summary'>
                                                                    <p>Top Destination: {group.top8Destinations[index][0]}</p>
                                                                    <button onClick={() => handleViewResults(group.top8Destinations[index])} className='see-more-button'>
                                                                      See More →
                                                                    </button>
                                                                  </div>
                                                                </ResultBox>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                <p className="empty-text">No group results.</p>
                            )}
                        </div>
                    </div>

                    <div className="requests-container">
                        <div className="merge-requests">
                            <h3>Merge Requests</h3>
                            {mergeRequests.length > 0 ? (
                                <ul>
                                    {mergeRequests.map((request) => {
                                        const isGroupRequest = !!request.groupId;

                                        if (isGroupRequest) {
                                            // Handle group merge request
                                            return (
                                                <li key={request.id} className="request-object">
                                                    <div className="friend-info">
                                                        <div className="friend-identifiers">
                                                            <h4>{request.name}</h4>
                                                            <p>Group Merge Request</p>
                                                        </div>
                                                    </div>
                                                    <div className="buttons-container">
                                                        <button className="accept-button">
                                                            <img src={checkmarkIcon} className="checkmark-icon" onClick={() => handleGroupMergeRequestRespnse(request, true)} alt="check"/>
                                                        </button>
                                                        <button className="decline-button">
                                                            <img src={xIcon} className="x-icon" onClick={() => handleGroupMergeRequestRespnse(request, false)} alt="x"/>
                                                        </button>
                                                    </div> 
                                                </li>
                                            );
                                        } else {
                                            // Handle individual merge request
                                            const sender = currentFriends.find(friend => friend.userId === request.id);
                                            const senderUsername = sender ? sender.userName : "Unknown User"; // Fallback if sender is not found
                                    
                                            return (
                                                <li key={request.id} className="request-object">
                                                    <div className="friend-info">
                                                        <img src={profileImage} className="friend-profile-photo" alt="profile-picture" />
                                                        <div className="friend-identifiers">
                                                            <h4>{request.fromUserName}</h4>
                                                            <p>@{senderUsername}</p>
                                                        </div>
                                                    </div>
                                                    <div className="buttons-container">
                                                        <button className="accept-button">
                                                            <img src={checkmarkIcon} className="checkmark-icon" onClick={() => handleMergeRequestRespnse(request, true)} alt="check"/>
                                                        </button>
                                                        <button className="decline-button">
                                                            <img src={xIcon} className="x-icon" onClick={() => handleMergeRequestRespnse(request, false)} alt="x"/>
                                                        </button>
                                                    </div> 
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            ) : (
                                <p className="empty-text">No merge requests</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </MergeHubStyles>
    )
}

export default MergeHub;