import { db } from "../../firebase";
import { doc, getDoc, setDoc, collection, deleteDoc } from "firebase/firestore";
import { DestinationRankings } from "../survey-helpers/suggestion-algorithm/suggestion-data";

const fetchResultData = async (request) => {
    const friendResultRef = doc(db, `Results/${request.id}/${request.subcollection}/${request.resultId}`);
    const friendResultDoc = await getDoc(friendResultRef);

    if (!friendResultDoc.exists()) {
        throw new Error("Friend's result document does not exist.");
    }

    return friendResultDoc.data();
}

const generateMergeData = (userResult, friendResult) => {
    let friendSelectionCount = 0;
    const friendAttributeTotals = {};
    let userSelectionCount = 0;
    const userAttributeTotals = {};

    /* Friend */
    //Count total selections for average
    for (const key in friendResult.selectionCounts) {
        const selection = friendResult.selectionCounts[key];
        friendSelectionCount += selection.selected + selection.notSelected;
    };

    //Calculate average
    for (const [key, total] of Object.entries(friendResult.attributeTotals)) {
        const average = total / friendSelectionCount;
        friendAttributeTotals[key] = average;
    };

    /* User */
    //Count total selections for average
    for (const key in userResult.selectionCounts) {
        const selection = userResult.selectionCounts[key];
        userSelectionCount += selection.selected + selection.notSelected;
    };

    //Calculate average
    for (const [key, total] of Object.entries(userResult.attributeTotals)) {
        const average = total / userSelectionCount;
        userAttributeTotals[key] = average;
    };


    //Add together for combined total
    const mergedAttributeTotals = {};

    for (const key in friendAttributeTotals) {
        mergedAttributeTotals[key] = friendAttributeTotals[key];
    
        if (userAttributeTotals[key] !== undefined) {
            mergedAttributeTotals[key] += userAttributeTotals[key];
        }
    };

    //Calculate Rankings
    const rankings = {};

    for (const destination in DestinationRankings) {
        if (DestinationRankings.hasOwnProperty(destination)) {
            const destinationData = DestinationRankings[destination];
            let score = 0;

            for (const attribute in mergedAttributeTotals) {
                if (mergedAttributeTotals.hasOwnProperty(attribute) && destinationData.hasOwnProperty(attribute)) {
                    score += mergedAttributeTotals[attribute] * destinationData[attribute];
                }
            }

            rankings[destination] = score;
        }
    };

    // TODO: top8Destinations is returning 16 items with 8 duplicates
    const sortedRankings = Object.entries(rankings).sort(([, scoreA], [, scoreB]) => scoreB - scoreA);
    const top8Destinations = sortedRankings.slice(0, 8).map(item => item[0]);
    
    return {
        mergedAttributeTotals,
        top8Destinations
    };
};

const postMergeResult = async (userId, userFirstName, userLastName, request, mergeData) => {
    const mergeDocRef = doc(collection(db, 'Merges')); // Auto-generated ID
    const mergeName = `${userFirstName} and ${request.fromUserName.split(' ')[0]}'s merge`;

    const mergeDocData = {
        name: mergeName,
        mergedAttributeTotals: mergeData.mergedAttributeTotals,
        top8Destinations: mergeData.top8Destinations,
        userIds: [userId, request.id],
        userNames: {
            [userId]: `${userFirstName} ${userLastName}`,
            [request.id]: request.fromUserName
        },
        createdAt: new Date()
    };

    await setDoc(mergeDocRef, mergeDocData);

    // Delete request document
    const requestDocRef = doc(db, `Users/${userId}/mergeRequests/${request.id}`);
    await deleteDoc(requestDocRef);

    return { id: mergeDocRef.id, ...mergeDocData };
};

//Main function
const performMerge = async (userId, userFirstName, userLastName, userResult, request) => {
    try {
        //Fetch friend result data
        const friendResult = await fetchResultData(request);

        //Generate merged data
        const mergeData = generateMergeData(userResult, friendResult);

        //Post merge result
        return await postMergeResult(userId, userFirstName, userLastName, request, mergeData);
    } catch (error) {
        console.error(error.message);
    }
};

export {performMerge, generateMergeData};