import { useUserAuth } from "../../UserAuthContext";
import { SignOutStyles } from "./SignOutStyles";

const SignOut = () => {
    const { logOut } = useUserAuth();

    const handleLogout = async () => {
        try {
          await logOut();
          window.location.href = '/discover';
        } catch (error) {
          console.error('Failed to log out:', error);
        }
    };

    return (
      <SignOutStyles>
        <div class="sign-out-container">
          <h1>Are you sure you want to sign out?</h1>
          <button class="sign-out-button" onClick={() => handleLogout()}>Sign Out</button>
        </div>
      </SignOutStyles>
    );
}

export default SignOut;