import { db } from "../../firebase";
import { doc, collection, getDoc, setDoc, deleteDoc } from "firebase/firestore";

const pinResult = async (userId, resultId) => {
    try {
        console.log(resultId);
        const userResultsRef = doc(db, 'Results', userId);
        const gameResultsRef = collection(userResultsRef, 'Game');
        const resultDocRef = doc(gameResultsRef, resultId)
  
        const resultDoc = await getDoc(resultDocRef);
  
        if (resultDoc.exists()) {
          const resultData = resultDoc.data();
  
          const pinnedResultsRef = doc(db, `Results`, userId);
          const pinnedCollectionRef = collection(pinnedResultsRef, 'Pinned');
          const newDocRef = doc(pinnedCollectionRef, resultId);
  
          await setDoc(newDocRef, resultData);
          await deleteDoc(resultDocRef);
  
          console.log("Document moved successfully!");
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error moving document: ", error);
      }
};

export default pinResult;