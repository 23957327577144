import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

const fetchFriendRequestsReceived = async (userId) => {
    try {
        const friendRequestsRef = collection(db, `Users/${userId}/friendRequestsReceived`);
        const friendRequestsSnapshot = await getDocs(friendRequestsRef);

        const friendRequests = [];
        friendRequestsSnapshot.forEach((doc) => {
            friendRequests.push({
                id: doc.id,
                ...doc.data()
            });
        });

        return friendRequests;
    } catch (error) {
        console.error("Error fetching received friend requests:", error);
        return [];
    }
};

const fetchFriendRequestsSent = async (userId) => {
    try {
        const friendRequestsRef = collection(db, `Users/${userId}/friendRequestsSent`);
        const friendRequestsSnapshot = await getDocs(friendRequestsRef);

        const friendRequests = [];
        friendRequestsSnapshot.forEach((doc) => {
            friendRequests.push({
                id: doc.id,
                ...doc.data()
            });
        });

        return friendRequests;
    } catch (error) {
        console.error("Error fetching sent friend requests:", error);
        return [];
    }
};

export { fetchFriendRequestsReceived, fetchFriendRequestsSent };