import styled from "styled-components";

export const MergeHubStyles = styled.div`
.merge-hub {
    position: relative;
    width: 100%;
    margin-top: 2%;
}

.merge-hub-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 1em;
    min-height: 600px;
    margin-bottom: 1em;
}

h3 {
    font-size: 1.2em;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 0px;
    padding-left: 10px;
}

.results-container {
    width: 35%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    min-height: 100%;
}

.requests-container {
    width: 25%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.merge-requests {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    height: 100%;
}

ul {
    padding: 0;
    background-color: #ffffff;
    list-style-type: none;
}

.friend-object {
    width: 100%;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 50px;
    align-items: center;
    padding: 0 .5em;
}

.friend-info {
    display: flex;
    flex-direction: row;
    gap: .5em;
    align-items: center;
}

.friend-profile-photo {
    width: 2.5em;
    height: 2.5em;
    border: 2px solid grey;
    border-radius: 25px;
}

.friend-identifiers {
    display: flex;
    flex-direction: column;
}

h4 {
    margin: 0;
    font-size: 1.2em;    
    padding-top: 5px;
}

p {
    margin: 0;
    font-size: .9em;
}
    
.buttons-container {
    display: flex;
    gap: 10px;
}

.unfriend-icon {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-bottom: 3px;
    filter: invert(1);
}

.request-object {
    width: 100%;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 50px;
    align-items: center;
    padding: 0px .5em;
}

.accept-button, .decline-button {
    border: none;
    background: none;
    text-align: center;
    justify-content: center;
    padding: 0;
}

.checkmark-icon, .x-icon {
    width: 30px;
    height: 30px;
}

.friend-results {
  padding: 10px;
  background-color: #f0f0f0; /* Slight background color for results */
}

.friend-group-heading {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5em 1em;
}

.merge-button {
    border: none;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    font-size: 12pt;
    font-family "Myriad Pro"
    align-self: flex-end;
    background-color: #009999;
    color: white;
}

.merge-icon {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-bottom: 3px;
    filter: invert(1);
}

.groups-container {
    width: 35%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.group-results {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    height: 100%;
}

.empty-text {
    text-align: center;
    padding-top: 10px;
}

@media (max-width: 850px) {
    .merge-hub-content {
        flex-direction: column;
        gap: 20px;
        min-height: 0;
    }

    .results-container, .groups-container, .requests-container {
        width: 100%;
    }

    .requests-container {
        order: 1;
    }

    .merge-requests {
        min-height: 100px;
    }

    .groups-container {
        order: 2;
    }

    .results-container {
        order: 3;
    }
}
`