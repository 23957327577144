import sendFriendRequest from "./sendFriendRequest";
import { useState, useRef, useEffect } from "react";
import SearchUsers from "./SearchUsers";
import { FriendsPageStyles } from "./FriendsPageStyles";
import magnifyingGlass from "../../Assets/Images/magnifying-glass.png";
import { fetchFriendRequestsReceived, fetchFriendRequestsSent } from "./fetchFriendRequests";
import { useUserAuth } from "../../UserAuthContext";
import friendRequestResponse from "./friendRequestResponse";
import fetchCurrentFriends from "./fetchCurrentFriends";
import profileImage from "../../Assets/Images/defaultAccountImage.jpg";
import mergeIcon from "../../Assets/Images/merge.png";
import unfriendIcon from "../../Assets/Images/unfriend.png";
import checkmarkIcon from "../../Assets/Images/checkmarkIcon.png";
import xIcon from "../../Assets/Images/xIcon.png";
import unfriend from "./unfriend";
import fetchUserProfile from "./fetchUserProfile";
import SelectResultsForMerge from "../merge-helpers/SelectResultsForMerge";
import Modal from "../../Modals/survey/Modal";
import createMergeRequest from "../merge-helpers/createMergeRequest";
import { SearchBarStyles } from "../profile-helpers/SearchBarStyles";
import SelectFriendsForGroup from "./SelectFriendsForGroup";
import createFriendGroup from "./createFriendGroup";
import fetchFriendGroups from "./fetchFriendGroups";
import FriendGroupDetails from "./FriendGroupDetails";
import defaultProfileImage from "../../Assets/Images/defaultAccountImage.jpg";

const FriendsPage = () => {
    const { user, userName, firstName, lastName } = useUserAuth();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const resultsRef = useRef(null);
    const [currentFriends, setCurrentFriends] = useState([]);
    const [friendRequestsReceived, setFriendRequestsReceived] = useState([]);
    const [currentFriendsIds, setCurrentFriendsIds] = useState([]);
    const [friendRequestsReceivedIds, setFriendRequestsReceivedIds] = useState([]);
    const [friendRequestsSentIds, setFriendRequestsSentIds] = useState([]);
    const [selectedFriendId, setSelectedFriendId] = useState(null);
    const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [friendGroups, setFriendGroups] = useState([]);
    const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
    const [isViewingGroup, setIsViewingGroup] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedGroupMembers, setSelectedGroupMembers] = useState(null);

    //Fetch friend information on startup
    useEffect(() => {
        const fetchFriendRequests = async () => {
            const requestsReceived = await fetchFriendRequestsReceived(user.uid);
            const requestsSent = await fetchFriendRequestsSent(user.uid);

            setFriendRequestsReceived(requestsReceived);

            const requestsReceivedIds = requestsReceived.map(request => request.id);
            const requestsSentIds = requestsSent.map(request => request.id);
            setFriendRequestsReceivedIds(requestsReceivedIds);
            setFriendRequestsSentIds(requestsSentIds);
        };

        const fetchFriends = async () => {
            const friends = await fetchCurrentFriends(user.uid);
            const friendsIds = friends.map(friend => friend.userId);
            setCurrentFriendsIds(friendsIds);

            //Fetch additional information for each friend
            const detailedFriends = await Promise.all(friends.map(async friend => {
                const profile = await fetchUserProfile(friend.userId);
                return {
                    ...friend,
                    ...profile,
                };
            }));

            setCurrentFriends(detailedFriends);
        }

        const fetchGroups = async () => {
            const groups = await fetchFriendGroups(user.uid);
            setFriendGroups(groups);
        }

        fetchFriendRequests();
        fetchFriends();
        fetchGroups();
    }, [user.uid]);

    //Hide search results when clicking outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (resultsRef.current && !resultsRef.current.contains(e.target)) {
                setSearchResults([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value === "") {
            setSearchResults([]);
        }
    };

    const handleSearch = async () => {
        if (searchQuery.trim() === "") return;
    
        const results = await SearchUsers(searchQuery);

        const filteredResults = results.filter(result => result.id !== user.uid);
        setSearchResults(filteredResults);
    };

    const handleSendRequest = async (selectedUserId) => {
        try {    
            await sendFriendRequest(user.uid, selectedUserId, firstName, lastName, userName);
            setFriendRequestsSentIds((prevIds) => [...prevIds, selectedUserId]);
            setSearchResults([]);
        } catch (error) {
            console.error('Failed to send friend request:', error);
            alert('Failed to send friend request.');
        }
    };

    const handleFriendRequestResponse = async (friendUserId, friendName, accept) => {
        try {
            setFriendRequestsReceivedIds(prevRequests =>
                prevRequests.filter(request => request.id !== friendUserId)
            );

            setFriendRequestsReceived(prevRequests =>
                prevRequests.filter(request => request.id !== friendUserId)
            );

            if (accept) {
                const newFriend = {
                    userId: friendUserId,
                    name: friendName
                };

                setCurrentFriends(prevFriends => [...prevFriends, newFriend])
                setCurrentFriendsIds(prevIds => [...prevIds, friendUserId]);
            }

            await friendRequestResponse(user.uid, firstName, lastName, friendUserId, friendName, accept)
            
            setSearchResults([]);
        } catch (error) {
            console.error('Failed to handle friend request:', error);
            alert('Failed to handle friend request.');
        }
    };

    const handleUnfriend = async (friendUserId) => {
        setCurrentFriends(prevFriends =>
            prevFriends.filter(friend => friend.userId !== friendUserId)
        );

        setCurrentFriendsIds(prevIds => 
            prevIds.filter(id => id !== friendUserId)
        );

        await unfriend(user.uid, friendUserId);
    };

    const handleMergeClick = (friendUserId) => {
        setIsMergeModalOpen(true);
        setSelectedFriendId(friendUserId);
    };

    const onClose = () => {
        setIsMergeModalOpen(false);
        setIsGroupModalOpen(false);
        setIsViewingGroup(false);
        setSuccess(false);
        setLoading(false);
        setError(null);
    };

    const handleResultSelect = async (result, subcollection) => {
        setLoading(true);
        setError(null);
        setSuccess(false);
    
        try {
            await createMergeRequest(user.uid, `${firstName} ${lastName}`, selectedFriendId, result, subcollection);
            setSuccess(true);
        } catch (error) {
            console.error('Error sending merge request:', error);
            setError(error.message || 'Failed to send merge request');
        } finally {
            setLoading(false);
        }
    };

    const handleCreateGroup = async (selectedFriends, groupName) => {
        console.log(selectedFriends);
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const newGroup = await createFriendGroup(user.uid, firstName, lastName, selectedFriends, groupName, friendGroups);
            setSuccess(true);
            setFriendGroups(prevGroups => [...prevGroups, newGroup]);
        } catch (error) {
            console.log(error.message);
            setError(`Failed to create friend group: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleViewGroup = (group, members) => {
        setSelectedGroup(group);
        setSelectedGroupMembers(members);
        setIsViewingGroup(true);
    };

    const deleteGroupDisplay = (groupId) => {
        const updatedGroups = friendGroups.filter(group => group.id !== groupId);
        setFriendGroups(updatedGroups);
    }

    return (
        <FriendsPageStyles>
            <div className="friends-page">
                {/* Merge modal to display over friends page */}
                <Modal isOpen={isMergeModalOpen} onClose={() => setIsMergeModalOpen(false)}>
                    <SelectResultsForMerge 
                        userId={user.uid}
                        onClose={onClose}
                        onSelect={handleResultSelect}
                        loading={loading}
                        error={error}
                        success={success}
                    />
                </Modal>

                {/* Friend Group Creation Modal */}
                <Modal isOpen={isGroupModalOpen} onClose={() => setIsGroupModalOpen(false)}>
                    <SelectFriendsForGroup
                        currentFriends={currentFriends}
                        onClose={onClose}
                        onSubmit={handleCreateGroup}
                        loading={loading}
                        error={error}
                        success={success}
                    />
                </Modal>

                {/* View Friend Group Modal */}
                <Modal isOpen={isViewingGroup} onClose={() => setIsViewingGroup(false)}>
                    <FriendGroupDetails
                        userId={user.uid}
                        group={selectedGroup}
                        members={selectedGroupMembers}
                        deleteGroupDisplay={deleteGroupDisplay}
                        sendFriendRequest={handleSendRequest}
                        onClose={onClose}
                    />
                </Modal>

                <SearchBarStyles>
                    <div className="search-container">
                        <div className="search-bar-container">
                            <input
                                className="friend-search-bar"
                                type="text"
                                placeholder="Search for friends by name, username, or email"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                            />
                            <button onClick={handleSearch} className="search-button">
                                <img src={magnifyingGlass} className='search-icon' alt="Search" />
                            </button>
                        </div>
                        <div className="search-results" ref={resultsRef}>
                            {searchResults.length > 0 && (
                                <ul>
                                    {searchResults.map((result) => (
                                        <li key={result.id} className="search-item">
                                            <span>{result.firstName} {result.lastName} (@{result.userName})</span>

                                            {/* Conditionally render button based on friend status */}
                                            {currentFriendsIds.includes(result.id) ? (
                                                <span>Current Friend</span>
                                            ) : friendRequestsSentIds.includes(result.id) ? (
                                                <button className="request-sent-button" disabled>Request Sent</button> 
                                            ) : friendRequestsReceivedIds.includes(result.id) ? (
                                                <div className="buttons-container">
                                                    <button className="accept-button">
                                                        <img src={checkmarkIcon} className="checkmark-icon" onClick={() => handleFriendRequestResponse(result.id, `{${result.firstName} ${result.lastName}}`, true)} alt="check"/>
                                                    </button>
                                                    <button className="decline-button">
                                                        <img src={xIcon} className="x-icon" onClick={() => handleFriendRequestResponse(result.id, `{${result.firstName} ${result.lastName}}`, false)} alt="x"/>
                                                    </button>
                                                </div>
                                            ) : (
                                                <button className="add-friend-button" onClick={() => handleSendRequest(result.id)}>Add Friend</button> // Default add friend button
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </SearchBarStyles>
                
                <div className="friends-container">
                    <div className="current-friends">
                        <h3>Friends</h3>
                        {currentFriends.length > 0 ? (
                            <ul>
                                {currentFriends.map((friend) => (
                                    <li key={friend.userId} className="friend-object">
                                        <div className="friend-info">
                                            <img src={friend.profileImage} className="friend-profile-photo" alt="profile-picture" />
                                            <div className="friend-identifiers">
                                                <h4>{friend.name}</h4>
                                                <p>@{friend.userName}</p>
                                            </div>
                                        </div>
                                        <div className="buttons-container">
                                            <button className="merge-button" onClick={() => handleMergeClick(friend.userId)}>
                                                Merge
                                                <img src={mergeIcon} className="merge-icon" alt="merge"/>
                                            </button>
                                            <button className="unfriend-button" onClick={() => handleUnfriend(friend.userId)}>
                                                Unfriend
                                                <img src={unfriendIcon} className="unfriend-icon" alt="unfriend"/>
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="empty-text">No current friends</p>
                        )}
                    </div>
                    <div className="groups-container">
                        <div className="friend-groups">
                            <div className="friend-groups-header">
                                <h3>Groups</h3>
                                <button className="create-group-button" onClick={() => setIsGroupModalOpen(true)}>
                                    +
                                </button>
                            </div>
                            {friendGroups.length > 0 ? (
                                <ul>
                                    {friendGroups.map((group) => {
                                        //Get the names of the friends in the current group based on user IDs
                                        const membersToDisplay = Object.keys(group.members)
                                            .filter(userId => userId !== user.uid) // Exclude the current user
                                            .map(userId => ({ userId, fullName: group.members[userId]     
                                        }));

                                        // Get detailed member information
                                        const members = membersToDisplay.map(member => {
                                            const friend = currentFriends.find(friend => friend.userId === member.userId);
                                            return {
                                                ...member,
                                                profileImage: friend ? friend.profileImage : defaultProfileImage,  // Fallback image
                                                userName: friend ? friend.userName : 'Unknown',  // Fallback userName
                                                isFriend: !!friend  //True if friend exists, converted to boolean
                                            };
                                        });

                                        return (
                                            <li key={group.id} className="group-object">
                                                <div className="group-info">
                                                    <h4>{group.groupName}</h4>
                                                    <p>
                                                        Members: {membersToDisplay.map((member, index) => (
                                                            <span key={member.userId}>
                                                                {member.fullName}{index < membersToDisplay.length - 1 && ', '}
                                                            </span>
                                                        ))}
                                                    </p>
                                                </div>
                                                <button className="view-group-button" onClick={() => handleViewGroup(group, members)}>View</button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                <p className="empty-text">No friend groups</p>
                            )}
                        </div>
                    </div>
                    <div className="requests-container">
                        <div className="friend-requests">
                            <h3>Friend Requests</h3>
                            {friendRequestsReceived.length > 0 ? (
                                <ul>
                                    {friendRequestsReceived.map((request) => (
                                        <li key={request.id} className="request-object">
                                            <div className="friend-info">
                                                <img src={profileImage} className="friend-profile-photo" alt="profile-picture" />
                                                <div className="friend-identifiers">
                                                    <h4>{request.from}</h4>
                                                    <p>@{request.fromUserName}</p>
                                                </div>
                                            </div>
                                            <div className="buttons-container">
                                                <button className="accept-button">
                                                    <img src={checkmarkIcon} className="checkmark-icon" onClick={() => handleFriendRequestResponse(request.id, request.from, true)} alt="check"/>
                                                </button>
                                                <button className="decline-button">
                                                    <img src={xIcon} className="x-icon" onClick={() => handleFriendRequestResponse(request.id, request.from, false)} alt="x"/>
                                                </button>
                                            </div> 
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="empty-text">No friend requests</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </FriendsPageStyles>
    );
}

export default FriendsPage;