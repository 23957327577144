export const ModalStyles = {
    modalContent: {
      fontFamily: 'Myriad Pro',
      top: '50%',
      left: '50%',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      maxWidth: '90%',
      width: '400px',
      margin: '1% auto',
      zIndex: 1000,
    },
    h2: {
      color: '#005e84',
      textAlign: 'center',
    },
    h3: {
      fontFamily: 'Myriad Pro',
      color: '#9d9d9d',
      textAlign: 'center',
      fontSize: '100%',
    },
    inputGroup: {
      marginBottom: '20px',
    },
    inputRow: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
    },
    input: {
      width: '100%',
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    },
    inputHalf: {
      flex: 1,
    },
    error: {
      color: 'red',
      margin: '10px 0',
    },
    submitButton: {
      display: 'block',
      width: '100px',
      margin: '0 auto',
      padding: '10px',
      backgroundColor: '#009999',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    },
    submitButtonHover: {
      backgroundColor: '#005e84',
    },
    buttonRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '10px',
    },
    toggleButton: {
      marginTop: '10px',
      backgroundColor: 'transparent',
      border: 'none',
      color: '#009999',
      cursor: 'pointer',
    },
    closeButton: {
      marginTop: '10px',
      right: '10px',
      backgroundColor: 'transparent',
      border: 'none',
      color: '#009999',
      cursor: 'pointer',
    },
  };