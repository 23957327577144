import styled from "styled-components";

export const GroupDetailsStyles = styled.div`
    .section-title {
        margin: .5em 0;
    }

    .members-list {
        border: 1px solid gray;
        border-radius: 10px;
        padding: .5em .5em;
    }

    .remove-button {
        background-color: red;
        color: white;
        border: none;
        border-radius: 10px;
    }

    .buttons-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .delete-group-button {
        border: none;
        border-radius: 5px;
        text-align: center;
        justify-content: center;
        font-size: 15pt;
        font-family "Myriad Pro"
        align-self: flex-end;
        background-color: red;
        color: white;
    }

    .message-section {
        margin: 1em 0;
        text-align: center;
    }

    .cancel-button {
        background-color: red;
        color: white;
        border: none;
        background-color: #005fff;
        border-radius: 10px;
    }

    .add-friend-button {
        border: none;
        border-radius: 5px;
        text-align: center;
        justify-content: center;
        font-size: 12pt;
        font-family "Myriad Pro";
        background-color: #005fff;
        color: white;
    }

    .add-friend-button:disabled {
        background-color: #cccccc;
        color: #666666;
        cursor: not-allowed;
    }
`;