import { db } from "../../firebase";
import { getDocs, collection, query, where } from "firebase/firestore";

const fetchMergeResults = async (userId) => {
    try {
        const mergesRef = collection(db, 'Merges');
        const q = query(mergesRef, where('userIds', 'array-contains', userId));
        const querySnapshot = await getDocs(q);

        const mergeResults = [];
        querySnapshot.forEach((doc) => {
            mergeResults.push({
                id: doc.id,
                ...doc.data()
            });
        });

        return mergeResults;
    } catch (error) {
        console.error('Error fetching merge results:', error);
        return [];
    }
};

export default fetchMergeResults;