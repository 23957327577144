import { db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";

const changeUserName = async (userId, newUsername) => {
    try {
        const userDocRef = doc(db, 'Users', userId);
        await setDoc(userDocRef, { userName: newUsername }, { merge: true });
        console.log('Username successfully updated:', newUsername);
    } catch (error) {
        console.error('Error updating username:', error);
        throw new Error('Failed to update username. Please try again.');
    }
};

export default changeUserName;