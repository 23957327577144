import styled from "styled-components";

export const ProfileParentStyles = styled.div`
    .profile-page-container {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .nav-container {
        width: 12.5%;
        height: 500px;
        position: absolute;
        top: 35%;
        margin-left: 0; 
    }

    .content-area {
        width: 75%;
        margin-left: 12%;
        padding-left: 20px;
    }

    @media (max-width: 1052px) {
        .profile-page-container {
            flex-direction: column;
            align-items: center;
        }

        .content-area {
            width: 98%;
            margin: 0;
            padding: 0;
        }

        .nav-container {
            width: 50%;
            position: relative;
            height: auto;
            margin: 1em 0;
        }
    }

    @media (max-width: 750px) {
        .nav-container {
            width: 79%;
        }
    }
`;