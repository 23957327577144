import React from 'react';
import { TripSuggestionStyles } from '../survey-helpers/trip-suggestion/trip-suggestion-styles';

const DisplayResults = ({ rankings, DestinationRankings, handleViewResults }) => {
    return (
      <TripSuggestionStyles>
        <div className="header">
          <div className="title-container">
            <h1 className="your-preferences">Your Suggestions:</h1>
          </div>
          <div className="description-container">
            <p className="description">
              All prices represent estimated cost per-person, per-day
            </p>
          </div>
        </div>
        <div>
          {rankings.slice(0, 8).map((ranking, index) => {
            const destination = DestinationRankings[ranking.city];
            if (!destination) return null; // Ensure destination exists
          
            return (
              <div key={index} className="row gy-3 box p-3 mb-5">
                <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                  <span className="package-description text-center text-md-start">
                    {ranking.city}
                  </span>
                  
                  <img className="img-fluid mt-2" src={destination.image} alt={ranking.city} />
                </div>
                <div className="col d-flex flex-column justify-content-center">
                  <div className="row gy-3">
                    <div className="col-12">
                      <div className="cost d-flex flex-column text-center text-md-end">
                        Activities $
                        {destination.cost_activities * 1 * 1} {/* Adjust numNights and numTraveling as needed */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="cost d-flex flex-column text-center text-md-end">
                        Flight ${destination.cost_flight * 1} {/* Adjust numTraveling as needed */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="cost d-flex flex-column text-center text-md-end">
                        Food $
                        {destination.cost_food * 1 * 1} {/* Adjust numNights and numTraveling as needed */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="cost d-flex flex-column text-center text-md-end">
                        Hotels $
                        {destination.cost_hotel * 1 * Math.ceil(1 / 2)} {/* Adjust numNights and numTraveling as needed */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </TripSuggestionStyles>
    );
  };
  
  export default DisplayResults;