import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

const fetchCurrentFriends = async (userId) => {
    try {
        const friendsRef = collection(db, `Users/${userId}/friends`)
        const friendsSnapshot = await getDocs(friendsRef);

        const friends = [];
        friendsSnapshot.forEach((doc) => {
            friends.push({
                userId: doc.userId,
                ...doc.data()
            });
        });

        return friends;
    } catch (error) {
        console.error("Error fetching friends:", error);
        return [];
    }
};

export default fetchCurrentFriends;