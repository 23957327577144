import { useState } from 'react';
import ProfileNav from '../../Helpers/profile-helpers/ProfileNav';
import Profile from '../../Helpers/profile-helpers/profile';
import { ProfileParentStyles } from './ProfileParentStyles';
import SignOut from '../../Helpers/profile-helpers/SignOut';
import SignInSignUpModal from '../../Modals/survey/signInSignUpModal';
import { useUserAuth } from '../../UserAuthContext';
import FriendsPage from '../../Helpers/friends/FriendsPage';
import MergeHub from '../../Helpers/merge-helpers/MergeHub';

const ProfileParent = () => {
    const [activeTab, setActiveTab] = useState('profile');
    const { user } = useUserAuth();

    const renderContent = () => {
        switch (activeTab) {
          case 'profile':
            return <Profile />;
          case 'friends':
            return <FriendsPage />;
          case 'merge':
            return <MergeHub />;
          case 'signOut':
            return <SignOut />;
          default:
            return <Profile />;
        }
    };

    //If not signed in display modal
    if (!user) {
        return <SignInSignUpModal heading="Sign in to view your profile"/>;
    }

    return (
        <ProfileParentStyles>
          <div className="profile-page-container">
            <div className='nav-container'>
                <ProfileNav activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>
            <div className="content-area">
              {renderContent()}
            </div>
          </div>
        </ProfileParentStyles>
    );
};

export default ProfileParent;