import React, { useEffect, useState } from 'react';
import { useUserAuth } from "../../UserAuthContext"
import bannerImage1 from '../../Assets/Images/profileBanner1.jpg'
import { ProfileStyles, ResultBox } from './profile-styles';
import DisplayResults from '../game-helpers/DisplayResults';
import { DestinationRankings } from '../survey-helpers/suggestion-algorithm/suggestion-data';
import mergeIcon from '../../Assets/Images/merge.png';
import changeUserName from './changeUserName';
import pinIcon from '../../Assets/Images/thumbtacks.png';
import pinResult from '../results/pinResult';
import unpinResult from '../results/unpinResult';
import fetchResults from '../results/fetchResults.js';
import Modal from '../../Modals/survey/Modal.js';
import SelectFriendForMerge from '../merge-helpers/SelectFriendForMerge.js';
import createMergeRequest from '../merge-helpers/createMergeRequest.js';

export default function Profile() {
    const { user, userName, firstName, lastName, profileImage, updateUsername } = useUserAuth();
    const [isEditing, setIsEditing] = useState(false);
    const [newUsername, setNewUsername] = useState(userName);
    const [viewingResults, setViewingResults] = useState(false);
    const [selectedResult, setSelectedResult] = useState({ id: null, rankings: null });
    const [pinnedResults, setPinnedResults] = useState([]);
    const [pastResults, setPastResults] = useState([]);
    const [editingResultId, setEditingResultId] = useState(null);
    const [newResultId, setNewResultId] = useState("");
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedResultId, setSelectedResultId] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                const { pinnedData, pastData } = await fetchResults(user.uid);
                setPinnedResults(pinnedData);
                setPastResults(pastData);
            } catch (err) {
                console.error("Error loading results:", err);
            }
        };

        if (user.uid) {
            loadData();
        }
    }, [user.uid]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        await changeUserName(user.uid, newUsername);
        updateUsername(newUsername);
        setIsEditing(false);
    };

    const handleCancelClick = () => {
        setNewUsername(userName);
        setIsEditing(false);
    };

    const handleViewResults = (result, subcollection) => {
        // Map the city names to their corresponding data from DestinationRankings
        const enrichedRankings = result.top8Destinations.map(city => ({
          city,
          ...DestinationRankings[city]
        }));
    
        setSelectedResult({
          id: result.id,
          rankings: enrichedRankings,
          subcollection: subcollection
        });
        setViewingResults(true);
    }

    const handlePinClick = (result, pin) => {
        if (pin) {
            pinResult(user.uid, result.id);
            setPastResults(prevResults => prevResults.filter(r => r.id !== result.id));
            setPinnedResults(prevPinned => [...prevPinned, result]);
        } else {
            unpinResult(user.uid, result.id);
            setPinnedResults(prevPinned => prevPinned.filter(r => r.id !== result.id));
            setPastResults(prevResults => {
                // Insert back into the correct position using the Time field
                const newResults = [...prevResults, result];
                return newResults.sort((a, b) => new Date(b.Time) - new Date(a.Time));
            });
        }
    };

    const handleResultIdClick = (resultId) => {
      setEditingResultId(resultId);
      setNewResultId(resultId);
    };

    const handleResultIdChange = (e) => {
      setNewResultId(e.target.value);
    };

    const handleResultIdSave = async (originalId, isPinned) => {
      //await changeResultId(user.uid, originalId, newResultId, isPinned);
      setEditingResultId(null);
    };

    const handleMergeClick = () => {
      setIsModalOpen(true);
    };

    const onClose = () => {
      setIsModalOpen(false);
    };

    const handleFriendSelect = async (selectedFriendId) => {
      setLoading(true);
      setError(null);
      setSuccess(false);
      
      try {
        await createMergeRequest(user.uid, `${firstName} ${lastName}`, selectedFriendId, selectedResult, selectedResult.subcollection);
        setSuccess(true);
      } catch (error) {
          console.error('Error sending merge request:', error);
          setError(error.message || 'Failed to send merge request');
      } finally {
          setLoading(false);
      }
    };

    if (viewingResults) {
        return ( 
            <ProfileStyles>
                <div>
                    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                        <SelectFriendForMerge 
                            userId={user.uid}
                            onClose={onClose}
                            onSelect={handleFriendSelect}
                            loading={loading}
                            error={error}
                            success={success}
                        />
                    </Modal>
                    <div className='header'>
                        <button onClick={() => setViewingResults(false)} className='back-button'>
                            ← Back
                        </button>
                        <button className='merge-button' onClick={() => handleMergeClick(selectedResult)}>
                            Merge Results
                            <img src={mergeIcon} className='merge-icon' />
                        </button>
                    </div>
                    <div className='profile-container'>
                        <DisplayResults
                            rankings={selectedResult.rankings}
                            DestinationRankings={DestinationRankings}
                        />
                     </div>
                </div>
            </ProfileStyles>
        )
    }
    

    return (
        <ProfileStyles>
        <div className='profile-container'>
            {/* Editable Profile Banner */}
            <img
                src={bannerImage1}
                className={`banner-image ${isEditing ? 'editable-banner' : ''}`}
                alt="Profile Banner"
            />

            <div className='profile-content'>
                <div className='profile-info'>
                    <div className='profile-left'>
                    {/* Editable Profile Image */}
                    <img
                        src={profileImage}
                        className={`profile-image ${isEditing ? 'editable-image' : ''}`}
                        alt="Profile"
                    />
                    <div className='profile-text'>
                        <h2>{firstName} {lastName}</h2>
                        {isEditing ? (
                            <input
                                type="text"
                                value={newUsername}
                                onChange={(e) => setNewUsername(e.target.value)}
                                className="username-input"
                            />
                        ) : (
                            <p className='username'>@{userName}</p>
                        )}
                    </div>
                    </div>
                    <div className='buttons-container'>
                        {isEditing ? (
                            <>
                                <button onClick={handleSaveClick} className="save-button">Save</button>
                                <button onClick={handleCancelClick} className="cancel-button">Cancel</button>
                            </>
                        ) : (
                            <button onClick={handleEditClick} className='edit-profile-button'>Edit Profile</button>
                        )}
                    </div>
                </div>

                {/* Results Section */}
                <div className='results-content'>
                    <div className='results'>
                      <div className="pinned-results">
                        <h3>Pinned Results</h3>
                        {pinnedResults.length > 0 ? (
                          pinnedResults.map(result => (
                            <ResultBox key={result.id}>
                              <div className='heading'>
                              {editingResultId === result.id ? (
                                <input
                                    type="text"
                                    value={newResultId}
                                    onChange={handleResultIdChange}
                                    onBlur={() => handleResultIdSave(result.id, true)}
                                    className="result-id-input"
                                />
                              ) : (
                                <h4 onClick={() => handleResultIdClick(result.id)}>{result.id}</h4>
                              )}
                                <button onClick={() => handlePinClick(result, false)} className='pin-button'>
                                  <img src={pinIcon} alt='unpin'/>
                                </button>
                              </div>
                              <div className='summary'>
                                <p>Top Destination: {result.top8Destinations[0]}</p>
                                <button onClick={() => handleViewResults(result, "Pinned")} className='see-more-button'>
                                  See More →
                                </button>
                              </div>
                            </ResultBox>
                          ))
                        ) : (
                          <p>No pinned results found.</p>
                        )}
                      </div>
                    
                      <div className="past-results">
                        <h3>Past Results</h3>
                        {pastResults.length > 0 ? (
                          pastResults.map(result => (
                            <ResultBox key={result.id}>
                              <div className='heading'>
                              {editingResultId === result.id ? (
                                <input
                                    type="text"
                                    value={newResultId}
                                    onChange={handleResultIdChange}
                                    onBlur={() => handleResultIdSave(result.id, false)}
                                    className="result-id-input"
                                />
                              ) : (
                                <h4 onClick={() => handleResultIdClick(result.id)}>{result.id}</h4>
                              )}
                                <button onClick={() => handlePinClick(result, true)} className='pin-button'>
                                  <img src={pinIcon} alt='pin'/>
                                </button>
                              </div>
                              <div className='summary'>
                                <p>Top Destination: {result.top8Destinations[0]}</p>
                                <button onClick={() => handleViewResults(result, "Game")} className='see-more-button'>
                                  See More →
                                </button>
                              </div>
                            </ResultBox>
                          ))
                        ) : (
                          <p>No past results found.</p>
                        )}
                      </div>
                    </div>
                </div>
            </div>
        </div>
        </ProfileStyles>
    );
}