import styled from "styled-components";

export const SelectFriendStyles = styled.div`
.friends-section {
    margin-top: 20px;
}

.select-friends-container {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    overflow-y: auto;
    width: auto !important;
    max-height: 500px;
}

h3 {
    padding: 0;
    margin: 0;
    font-size: 150%;
}

.heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: .5em;
}

.submit-button {
    background-color: green;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

.submit-button:disabled {
    opacity: 50%;
    background-color: gray;
}

h4 {
    font-size: 125%;
}

.friend-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    padding: 10px 0;
    border-bottom: 1px solid #eee; 
}

.friend-item:last-child {
    border-bottom: none;
}

.friend-info {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-image {
    width: 50px;
    height: 50px;
}

.select-button {
    align-self: center;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.select-button:hover {
    background-color: #0056b3;
}

.remove-button {
    background-color: red !important;
    color: white;
}

.message {
    margin: 2em 0;
}

.naming-header {
    margin: 1em 0 .5em;
}

.input-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.group-name-input {
    width: 75%;
}

.close-button {
    position: absolute;
    top: 10px;  
    right: 10px; 
    background: transparent; 
    border: none;  
    font-size: 20px; 
    cursor: pointer;   
    color: #333;       
}

.close-button:hover {
    color: red;        /* Change color on hover for visibility */
}
`;