import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";

const createGroupMergeRequest = async (group, userId, result, subcollection) => {
    try {
        //Create group merge request doc
        const groupMergeRequestColRef = collection(db, "Groups", group.id, "mergeRequests");

        const requestData = {
            resultId: result.id,
            sentBy: userId,
            subcollection: subcollection,
            mergeDocId: null,
            includedMembers: [userId]
        };

        const requestDoc = await addDoc(groupMergeRequestColRef, requestData);

        //Add group merge request reference to members mergeRequests subcollection
        const userDocData = {
            groupId: group.id,
            name: group.groupName,
            groupRequestId: requestDoc.id
        }

        await Promise.all(
            Object.keys(group.members).map(async (memberId) => {
                const userMergeRequestsColRef = collection(db, "Users", memberId, "mergeRequests");
                await addDoc(userMergeRequestsColRef, userDocData);
            })
        );
    } catch (error) {
        console.error("Error creating group merge request:", error.message);
    }
};

export default createGroupMergeRequest;