import { ProfileNavStyles } from "./ProfileNavStyles";

const ProfileNav = ({ activeTab, setActiveTab }) => {
    return (
        <ProfileNavStyles>
            <div className="profile-nav">
                <ul>
                    <li
                      className={activeTab === 'profile' ? 'active' : ''}
                      onClick={() => setActiveTab('profile')}
                    >
                      Profile
                    </li>
                    <li
                      className={activeTab === 'friends' ? 'active' : ''}
                      onClick={() => setActiveTab('friends')}
                    >
                      Friends
                    </li>
                    <li
                      className={activeTab === 'merge' ? 'active' : ''}
                      onClick={() => setActiveTab('merge')}
                    >
                      Merge Hub
                    </li>
                    <li
                      className={activeTab === 'Sign Out' ? 'active' : ''}
                      onClick={() => setActiveTab('signOut')}
                    >
                      Sign Out
                    </li>
                </ul>
            </div>
        </ProfileNavStyles>
    );
};

export default ProfileNav;