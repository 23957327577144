import styled from "styled-components";

export const SearchBarStyles = styled.div`

.search-container {
    position: relative;
}

.search-bar-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.5);
    border-radius: 50px;
}

.friend-search-bar {
    width: 100%;
    padding: 10px 20px;
    border: none;
    outline: none;
    background: none;
    font-size: 16px;
    color: #333; /* Text color */
    border-radius: 50px;
}

.search-button {
    background: none;
    border: none;
    padding-right: 1em;
}

.search-icon {
    width: 20px;
    height: 20px;
    border: none;
}

.search-results {
    position: absolute;
    top: 100%;
    left: 10%;
    width: 75%;
    max-height: 200px;
    overflow-y: auto;
    background-color: rgba(200, 200, 200, 1);
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.search-results ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.search-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.add-friend-button {

}

.search-results li:last-child {
    border-bottom: none;
}

`