import { db } from "../../firebase";
import { doc, deleteDoc } from "firebase/firestore";

const declineMergeRequest = async (userId, requestId) => {
    try {
        const mergeRequestRef = doc(db, `Users/${userId}/mergeRequests/${requestId}`);

        await deleteDoc(mergeRequestRef);
        console.log("Document deleted");
    } catch (error) {
        console.log("Error declining request:", error);
    }
};

export default declineMergeRequest;