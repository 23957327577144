import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import { auth } from "./firebase";

import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "./firebase"
import defaultImage from "./Assets/Images/defaultAccountImage.jpg"
import { fetchFriendRequestsReceived } from "./Helpers/friends/fetchFriendRequests";
import fetchMergeRequests from "./Helpers/merge-helpers/fetchMergeRequests";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [location, setLocation] = useState({});
  const [name, setName] = useState("");
  const [referrer, setReferrer] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [userName, setUserName] = useState("")
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileImage, setProfileImage] = useState(defaultImage);
  const [friendRequests, setFriendRequests] = useState([]);
  const [mergeRequests, setMergeRequests] = useState([]);

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function signUp(email, password, firstName, lastName) {
    return createUserWithEmailAndPassword(auth, email, password).then(
      async (userCredential) => {
        const user = userCredential.user;
        const defaultUserName = user.email.split('@')[0];
  
        // Create a Firestore document for the new user
        const userDocRef = doc(db, "Users", user.uid);
        await setDoc(userDocRef, {
          email: email,
          firstName: firstName,
          lastName: lastName,
          userName: defaultUserName,
          profileImage: defaultImage,
        });
  
        setUserEmail(email);
        setFirstName(firstName);
        setLastName(lastName);
        setUserName(defaultUserName);
        setProfileImage(defaultImage);
      }
    );
  }

  function logOut() {
    return signOut(auth);
  }

  function updateUsername(newUsername) {
    setUserName(newUsername);
  }

  const fetchNotifications = async (userId) => {
    const friendRequests = await fetchFriendRequestsReceived(userId);
    const mergeRequests = await fetchMergeRequests(userId);
    setFriendRequests(friendRequests);
    setMergeRequests(mergeRequests);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentuser) => {
      setUser(currentuser);
      if (currentuser) {
        console.log("auth state changed: ", currentuser);

        const userDocRef = doc(db, "Users", currentuser.uid);
  
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserEmail(userData.email);
          setUserName(userData.userName);
          setProfileImage(userData.profileImage);
          setFirstName(userData.firstName);
          setLastName(userData.lastName);
        }
      }
    });
  
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user) {
      fetchNotifications(user.uid);
    }
  }, [user]);

  return (
    <userAuthContext.Provider value={{
      location, 
      setLocation, 
      userName, 
      setUserName,
      userEmail, 
      updateUsername,
      setUserEmail, 
      user, 
      logIn, 
      signUp, 
      logOut, 
      firstName,
      setFirstName,
      lastName,
      setLastName,
      referrer, 
      setReferrer, 
      profileImage, 
      setProfileImage,
      friendRequests,
      setFriendRequests,
      mergeRequests,
      setMergeRequests
      }}>
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
