import { db } from "../../firebase";
import { addDoc, setDoc, collection, doc } from "firebase/firestore";

const createFriendGroup = async (userId, firstName, lastName, selectedFriends, groupName, existingGroups) => {
    try {
        const userCreatedGroups = existingGroups.filter(group => group.createdBy === userId);

        const sortedSelectedFriends = [...selectedFriends]
            .sort((a, b) => a.userId.localeCompare(b.userId)); // Sort by userId keys

        for (const group of userCreatedGroups) {
            // Get the userId keys from group.members and sort them.
            const sortedGroupMembers = Object.keys(group.members)
            .filter(memberId => memberId !== userId)  // Exclude the creator's userId
            .sort();
        
            //Check if the length is same
            if (sortedGroupMembers.length === sortedSelectedFriends.length) {
                const isDuplicate = sortedGroupMembers.every((memberId, index) => {
                    return memberId === sortedSelectedFriends[index].userId;
                });
            
                if (isDuplicate) {
                    throw new Error("This group already exists.");
                }
            }
        }

        //Create group document
        const friendGroupRef = collection(db, "Groups");
        const newGroupDoc = await addDoc(friendGroupRef, {
            groupName: groupName,
            members: {
                [userId]: `${firstName} ${lastName}`,
                ...selectedFriends.reduce((acc, friend) => {
                    // Make sure to use friend.userId and not just friend
                    acc[friend.userId] = `${friend.firstName} ${friend.lastName}`;
                    return acc;
                }, {})
            },
            createdBy: userId
        });

        //Add group doc id to user subcollection for querying groups
        const allMembers = [{ userId, firstName, lastName }, ...selectedFriends]; // Add creator first

        for (const member of allMembers) {
            const userGroupRef = doc(db, "Users", member.userId, "groups", newGroupDoc.id);

            const role = member.userId === userId ? "creator" : "member";
            const userGroupData = {
                role: role
            };

            await setDoc(userGroupRef, userGroupData);
        }

        //Group data needs doc reference
        const groupData = {
            id: newGroupDoc.id,
            groupName: groupName,
            members: {
                [userId]: `${firstName} ${lastName}`,
                ...selectedFriends.reduce((acc, friend) => {
                    acc[friend.userId] = `${friend.firstName} ${friend.lastName}`;
                    return acc;
                }, {})
            },
            createdBy: userId
        };

        return groupData;
    } catch (error) {
        console.error("Error creating friend group:", error.message);
        throw error;
    }
};

export default createFriendGroup;