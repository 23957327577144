import { SelectFriendStyles } from "../merge-helpers/SelectFriendStyles";
import { useState } from "react";

const SelectFriendsForGroup = ({ currentFriends, onClose, onSubmit, loading, error, success }) => {
    const [selectedFriends, setSelectedFriends] = useState([]);
    const [isNaming, setIsNaming] = useState(false);
    const [groupName, setGroupName] = useState("");

    const onSelect = (friend) => {
        if (selectedFriends.some(f => f.userId === friend.userId)) {
            //Remove friend if already selected
            setSelectedFriends(selectedFriends.filter(f => f.userId !== friend.userId));
        } else {
            //Add friend if not selected
            setSelectedFriends([...selectedFriends, friend]);
        }
    };

    const handleSubmitGroup = () => {
        if (groupName.trim() !== "") {
            onSubmit(selectedFriends, groupName);
            setIsNaming(false);
        } else {
            alert("Please enter a group name!");
        }
    };

    return (
        <SelectFriendStyles>
            <div className="modal-overlay">
                {!isNaming ? (
                    <div className="modal-content">
                        <h3>Choose friends to add to group</h3>
                        <button className="close-button" onClick={onClose}>x</button>
                    
                        {loading ? (
                            <p className="message">Loading...</p>
                        ) : error ? (
                            <p className="message">{error}</p>
                        ) : success ? (
                            <p className="message">Group created successfully!</p>
                        ) : (

                        <>
                        <div className="friends-section">
                            <div className="heading">
                                <h4>Current Friends</h4>
                                <button className="submit-button" 
                                    onClick={() => setIsNaming(true)}
                                    disabled={selectedFriends.length <= 1}
                                >
                                    Next
                                </button>
                            </div>
                            <div className="select-friends-container">
                                {currentFriends.length > 0 ? (
                                    currentFriends.map(friend => (
                                        <div key={friend.userId} className="friend-item">
                                            <div className="friend-info">
                                                <img src={friend.profileImage} className="profile-image"/>
                                                <h5>{friend.name} @{friend.userName}</h5>
                                            </div>
                                            <button 
                                                className={`select-button ${selectedFriends.some(f => f.userId === friend.userId) ? 'remove-button' : 'add-button'}`}
                                                onClick={() => onSelect(friend)}
                                            >
                                                {selectedFriends.some(f => f.userId === friend.userId) ? 'Remove' : 'Add'}
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <p>No current friends.</p>
                                )}
                            </div>
                        </div>
                        </>
                        )}
                    </div>
                ) : (
                    <div className="modal-content">
                        <h3>Name your group</h3>
                        <button className="close-button" onClick={onClose}>x</button>
                        <h4 className="naming-header">What would you like to name this group?</h4>
                        <div className="input-button-container">
                            <input 
                                type="text" 
                                value={groupName} 
                                onChange={(e) => setGroupName(e.target.value)}
                                placeholder="Enter group name"
                                className="group-name-input"
                            />
                            <button 
                                className="submit-button" 
                                onClick={handleSubmitGroup}
                                disabled={groupName.trim() === ""}
                            >
                                Submit Group
                            </button>
                        </div>
                    </div>
                )}    
            </div>
        </SelectFriendStyles>
    )
};

export default SelectFriendsForGroup;