import { useEffect, useState } from "react";
import fetchResults from "../results/fetchResults";
import { SelectResultsStyles } from "./SelectResultsStyles";

const SelectResultsForMerge = ({ userId, onClose, onSelect, loading, error, success}) => {
    const [pinnedResults, setPinnedResults] = useState([]);
    const [pastResults, setPastResults] = useState([]);
    const [err, setErr] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                const { pinnedData, pastData } = await fetchResults(userId);
                setPinnedResults(pinnedData);
                setPastResults(pastData);
            } catch (err) {
                console.error('Error loading results in SelectResultsForMerge:', err);
                setErr('Failed to load results');
            }
        };

        if (userId) {
            loadData();
        }
    }, [userId]);

    if (err) {
        return (
            <SelectResultsStyles>
                <h3>Error</h3>
                <p className="message">Error: {err}</p>
            </SelectResultsStyles>
        )
    }

    return (
        <SelectResultsStyles>
            <div className="modal-overlay">
                <div className="modal-content">
                    <h3>Choose results to use in merge</h3>
                    <button className="close-button" onClick={onClose}>x</button>
                    
                    {loading ? (
                        <p className="message">Loading...</p>
                    ) : error ? (
                        <p className="message">{error}</p>
                    ) : success ? (
                        <p className="message">Merge request sent successfully!</p>
                    ) : (
                    <>
                    <div className="results-section">
                        <h4>Pinned Results</h4>
                        <div className="results-container pinned-results">
                            {pinnedResults.length > 0 ? (
                                pinnedResults.map(result => (
                                    <div key={result.id} className="result-item">
                                        <div className="result-info">
                                            <h4>{result.id}</h4>
                                            <p>Top destination: {result.top8Destinations[0]}</p>
                                        </div>
                                        <button className="select-button" onClick={() => onSelect(result, "Pinned")}>Select</button>
                                    </div>
                                ))
                            ) : (
                                <p>No pinned results found.</p>
                            )}
                        </div>
                    </div>
                        
                    <div className="results-section">
                        <h4>Past Results</h4>
                        <div className="results-container past-results">
                            {pastResults.length > 0 ? (
                                pastResults.map(result => (
                                    <div key={result.id} className="result-item">
                                        <div className="result-info">
                                            <h4>{result.id}</h4>
                                            <p>Top destination: {result.top8Destinations[0]}</p>
                                        </div>
                                        <button className="select-button" onClick={() => onSelect(result, "Game")}>Select</button>
                                    </div>
                                ))
                            ) : (
                                <p>No past results found.</p>
                            )}
                        </div>
                    </div>
                    </>
                    )}
                </div>
            </div>
        </SelectResultsStyles>
    );  
};

export default SelectResultsForMerge;
