import styled from "styled-components";

export const SignOutStyles = styled.div`
.sign-out-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    background-color: #f5f5f5;
    font-family: "Myriad Pro";
    text-align: center;
}

.sign-out-container h1 {
    color: #333;
    margin-bottom: 20px;
}

.sign-out-button {
    padding: 10px 20px;
    font-size: 1.1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sign-out-button:hover {
    background-color: #0056b3;
}
`;