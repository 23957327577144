import amsterdam from './photos/amsterdam.jpg';
import anchorage from './photos/anchorage.jpg';
import aspen from './photos/aspen.jpg';
import athens from './photos/athens.jpg';
import baku from './photos/baku.jpg';
import bali from './photos/bali.jpg';
import banff from './photos/banff.jpg';
import bangkok from './photos/bangkok.jpg';
import barHarbor from './photos/barHarbor.jpg';
import barcelona from './photos/barcelona.jpg';
import berlin from './photos/berlin.jpg';
import boston from './photos/boston.jpg';
import brussels from './photos/brussels.jpg';
import budapest from './photos/budapest.jpg';
import buenosAires from './photos/buenosAires.jpg';
import burma from './photos/burma.jpg';
import cairo from './photos/cairo.jpg';
import charleston from './photos/charleston.jpg';
import chicago from './photos/chicago.jpg';
import copenhagen from './photos/copenhagen.jpg';
import delhi from './photos/delhi.jpg';
import doha from './photos/doha.jpg';
import dubai from './photos/dubai.jpg';
import dublin from './photos/dublin.jpg';
import galapagosIslands from './photos/galapagosIslands.jpg';
import glacierNationalPark from './photos/glacierNationalPark.jpg';
import goa from './photos/goa.jpg';
import granada from './photos/granada.jpg';
import grandCanyon from './photos/grandCanyon.jpg';
import grandTetonsNationalPark from './photos/grandTetonsNationalPark.jpg';
import halongBay from './photos/halongBay.jpg';
import havana from './photos/havana.jpg';
import ibiza from './photos/ibiza.jpg';
import istanbul from './photos/istanbul.jpg';
import jacksonHole from './photos/jacksonHole.jpg';
import jamaica from './photos/jamaica.jpg';
import jerusalem from './photos/jerusalem.jpg';
import johannesburg from './photos/johannesburg.jpg';
import kampot from './photos/kampot.jpg';
import kandy from './photos/kandy.jpg';
import kauai from './photos/kauai.jpg';
import kualaLumpur from './photos/kualaLumpur.jpg';
import kyoto from './photos/kyoto.jpg';
import ladakh from './photos/ladakh.jpg';
import lakeTahoe from './photos/lakeTahoe.jpg';
import lasVegas from './photos/lasVegas.jpg';
import london from './photos/london.jpg';
import losAngeles from './photos/losAngeles.jpg';
import lucerne from './photos/lucerne.jpg';
import machuPicchu from './photos/machuPicchu.jpg';
import madagascar from './photos/madagascar.jpg';
import maldives from './photos/maldives.jpg';
import manama from './photos/manama.jpg';
import maui from './photos/maui.jpg';
import medellin from './photos/medellin.jpg';
import mexicoCity from './photos/mexicoCity.jpg';
import miami from './photos/miami.jpg';
import mongolia from './photos/mongolia.jpg';
import nashville from './photos/nashville.jpg';
import newOrleans from './photos/newOrleans.jpg';
import newYorkCity from './photos/newYorkCity.jpg';
import newZealand from './photos/newZealand.jpg';
import niagaraFalls from './photos/niagaraFalls.jpg';
import orlando from './photos/orlando.jpg';
import outerBanks from './photos/outerBanks.jpg';
import paris from './photos/paris.jpg';
import phuket from './photos/phuket.jpg';
import portland from './photos/portland.jpg';
import porto from './photos/porto.jpg';
import prague from './photos/prague.jpg';
import reykjavik from './photos/reykjavik.jpg';
import rioDeJanerio from './photos/rioDeJanerio.jpg';
import rome from './photos/rome.jpg';
import saintLucia from './photos/saintLucia.jpg';
import salzburg from './photos/salzburg.jpg';
import sanDiego from './photos/sanDiego.jpg';
import sanFrancisco from './photos/sanFrancisco.jpg';
import sanJose from './photos/sanJose.jpg';
import sanJuan from './photos/sanJuan.jpg';
import santiago from './photos/santiago.jpg';
import santoDomingo from './photos/santoDomingo.jpg';
import seattle from './photos/seattle.jpg';
import sedona from './photos/sedona.jpg';
import shanghai from './photos/shanghai.jpg';
import singapore from './photos/singapore.jpg';
import seoul from './photos/seoul.jpg';
import sydney from './photos/sydney.jpg';
import theRepublicOfSeychelles from './photos/theRepublicOfSeychelles.jpg';
import tokyo from './photos/tokyo.jpg';
import tulum from './photos/tulum.jpg';
import vancouver from './photos/vancouver.jpg';
import venice from './photos/venice.jpg';
import washington from './photos/washington.jpg';
import xiAn from './photos/xiAn.jpg';
import yellowstone from './photos/yellowstone.jpg';
import zanzibar from './photos/zanzibar.jpg';
import zionNationalPark from './photos/zionNationalPark.jpg';
import zurich from './photos/zurich.jpg';

export const DestinationRankings = {
  "Amsterdam, Holland": {
    "image": amsterdam,
    "travel_type": "international",
    "cost_flight": 500,
    "cost_hotel": 154,
    "cost_food": 35,
    "cost_activities": 50,
    "cost_week": 3268,
    "celebration": 0.85,
    "relaxation": 0.25,
    "experience": 1,
    "family": 0.35,
    "beaches": 0.15,
    "nightlife": 1,
    "adventure": 1,
    "historical": 0.85,
    "culture": 0.85,
    "New_Zealand": 0.05,
    "Brazil": 0.2,
    "Italy": 0.85,
    "Dominican_Republic": 0,
    "Spain": 0.6,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.25,
    "gramworthy": 0.8,
    "winter_activities": 0,
    "food_experience": 0.75,
    "sightseeing": 0.85
  },
  "Anchorage, AK": {
    "image": anchorage,
    "travel_type": "international",
    "cost_flight": 700,
    "cost_hotel": 200,
    "cost_food": 65,
    "cost_activities": 40,
    "cost_week": 3270,
    "celebration": 0.3,
    "relaxation": 0.4,
    "experience": 0.35,
    "family": 0.45,
    "beaches": 0.2,
    "nightlife": 0.75,
    "adventure": 0.3,
    "historical": 0.2,
    "culture": 0.4,
    "New_Zealand": 0.1,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0.1,
    "Spain": 0.1,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.3,
    "gramworthy": 0.4,
    "winter_activities": 1,
    "food_experience": 0.3,
    "sightseeing": 0.4
  },
  "Aspen, CO": {
    "image": aspen,
    "travel_type": "international",
    "cost_flight": 450,
    "cost_hotel": 271,
    "cost_food": 68,
    "cost_activities": 45,
    "cost_week": 4379,
    "celebration": 0.35,
    "relaxation": 0.5,
    "experience": 0.7,
    "family": 0.85,
    "beaches": 0,
    "nightlife": 0.2,
    "adventure": 0.7,
    "historical": 0.1,
    "culture": 0.1,
    "New_Zealand": 0.05,
    "Brazil": 0,
    "Italy": 0,
    "Dominican_Republic": 0,
    "Spain": 0,
    "Egypt": 0.1,
    "India": 0,
    "Thailand": 0,
    "Japan": 0.15,
    "nature_activities": 0.75,
    "gramworthy": 0.75,
    "winter_activities": 1,
    "food_experience": 0.1,
    "sightseeing": 0.65
  },
  "Athens, Greece": {
    "image": athens,
    "travel_type": "international",
    "cost_flight": 900,
    "cost_hotel": 100,
    "cost_food": 33,
    "cost_activities": 32,
    "cost_week": 3410,
    "celebration": 0.8,
    "relaxation": 0.9,
    "experience": 0.2,
    "family": 0.75,
    "beaches": 0.9,
    "nightlife": 0.8,
    "adventure": 0.2,
    "historical": 1,
    "culture": 0.8,
    "New_Zealand": 0.1,
    "Brazil": 0.2,
    "Italy": 0.9,
    "Dominican_Republic": 0.1,
    "Spain": 0.35,
    "Egypt": 0.15,
    "India": 0.05,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.35,
    "gramworthy": 0.85,
    "winter_activities": 0,
    "food_experience": 0.75,
    "sightseeing": 0.95
  },
  "Baku, Azerbaijan": {
    "image": baku,
    "travel_type": "international",
    "cost_flight": 1000,
    "cost_hotel": 117,
    "cost_food": 35,
    "cost_activities": 15,
    "cost_week": 2169,
    "celebration": 0.5,
    "relaxation": 0.5,
    "experience": 0.8,
    "family": 0.4,
    "beaches": 0.6,
    "nightlife": 0.4,
    "adventure": 0.7,
    "historical": 0.9,
    "culture": 0.8,
    "New_Zealand": 0.2,
    "Brazil": 0.2,
    "Italy": 0.2,
    "Dominican_Republic": 0.1,
    "Spain": 0.2,
    "Egypt": 0.85,
    "India": 0.6,
    "Thailand": 0.4,
    "Japan": 0.6,
    "nature_activities": 0.7,
    "gramworthy": 0.5,
    "winter_activities": 0.3,
    "food_experience": 0.8,
    "sightseeing": 0.4
  },
  "Bali, Indonesia": {
    "image": bali,
    "travel_type": "international",
    "cost_flight": 1300,
    "cost_hotel": 277,
    "cost_food": 17,
    "cost_activities": 15,
    "cost_week": 4987,
    "celebration": 0.8,
    "relaxation": 0.85,
    "experience": 0.85,
    "family": 0.6,
    "beaches": 1,
    "nightlife": 0.35,
    "adventure": 0.8,
    "historical": 0.6,
    "culture": 0.8,
    "New_Zealand": 0.5,
    "Brazil": 0.4,
    "Italy": 0.1,
    "Dominican_Republic": 0.8,
    "Spain": 0.1,
    "Egypt": 0.25,
    "India": 0.55,
    "Thailand": 0.85,
    "Japan": 0.85,
    "nature_activities": 0.7,
    "gramworthy": 0.9,
    "winter_activities": 0,
    "food_experience": 0.8,
    "sightseeing": 0.7
  },
  "Banff, Canada": {
    "image": banff,
    "travel_type": "international",
    "cost_flight": 400,
    "cost_hotel": 176,
    "cost_food": 44,
    "cost_activities": 17,
    "cost_week": 2006,
    "celebration": 0.2,
    "relaxation": 0.2,
    "experience": 1,
    "family": 0.85,
    "beaches": 0,
    "nightlife": 0.2,
    "adventure": 1,
    "historical": 0.1,
    "culture": 0.1,
    "New_Zealand": 0.15,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0,
    "Spain": 0.1,
    "Egypt": 0.25,
    "India": 0.1,
    "Thailand": 0,
    "Japan": 0.1,
    "nature_activities": 1,
    "gramworthy": 0.85,
    "winter_activities": 0.75,
    "food_experience": 0.25,
    "sightseeing": 0.6
  },
  "Bangkok, Thailand": {
    "image": bangkok,
    "travel_type": "international",
    "cost_flight": 1300,
    "cost_hotel": 60,
    "cost_food": 25,
    "cost_activities": 30,
    "cost_week": 3790,
    "celebration": 0.75,
    "relaxation": 0.5,
    "experience": 0.8,
    "family": 0.2,
    "beaches": 0.5,
    "nightlife": 1,
    "adventure": 0.8,
    "historical": 0.5,
    "culture": 0.65,
    "New_Zealand": 0.25,
    "Brazil": 0.4,
    "Italy": 0.2,
    "Dominican_Republic": 0.5,
    "Spain": 0.1,
    "Egypt": 0.1,
    "India": 0.5,
    "Thailand": 1,
    "Japan": 0.7,
    "nature_activities": 0.65,
    "gramworthy": 0.85,
    "winter_activities": 0,
    "food_experience": 0.8,
    "sightseeing": 0.65
  },
  "Bar Harbor, ME": {
    "image": barHarbor,
    "travel_type": "international",
    "cost_flight": 350,
    "cost_hotel": 188,
    "cost_food": 45,
    "cost_activities": 28,
    "cost_week": 3038,
    "celebration": 0.3,
    "relaxation": 0.2,
    "experience": 0.75,
    "family": 0.8,
    "beaches": 0.2,
    "nightlife": 0.2,
    "adventure": 0.75,
    "historical": 0.1,
    "culture": 0.1,
    "New_Zealand": 0.05,
    "Brazil": 0.25,
    "Italy": 0.2,
    "Dominican_Republic": 0,
    "Spain": 0,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.75,
    "gramworthy": 0.5,
    "winter_activities": 0.65,
    "food_experience": 0.25,
    "sightseeing": 0.65
  },
  "Barcelona, Spain": {
    "image": barcelona,
    "travel_type": "international",
    "cost_flight": 500,
    "cost_hotel": 146,
    "cost_food": 26,
    "cost_activities": 50,
    "cost_week": 3086,
    "celebration": 0.85,
    "relaxation": 0.65,
    "experience": 0.85,
    "family": 0.7,
    "beaches": 0.75,
    "nightlife": 1,
    "adventure": 0.8,
    "historical": 0.75,
    "culture": 0.8,
    "New_Zealand": 0.2,
    "Brazil": 0.2,
    "Italy": 0.9,
    "Dominican_Republic": 0.1,
    "Spain": 1,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.15,
    "gramworthy": 0.75,
    "winter_activities": 0,
    "food_experience": 0.85,
    "sightseeing": 0.9
  },
  "Berlin, Germany": {
    "image": berlin,
    "travel_type": "international",
    "cost_flight": 600,
    "cost_hotel": 138,
    "cost_food": 56,
    "cost_activities": 24,
    "cost_week": 2596,
    "celebration": 0.3,
    "relaxation": 0.3,
    "experience": 0.5,
    "family": 0.7,
    "beaches": 0,
    "nightlife": 0.6,
    "adventure": 0.8,
    "historical": 0.8,
    "culture": 0.65,
    "New_Zealand": 0.1,
    "Brazil": 0.2,
    "Italy": 0.5,
    "Dominican_Republic": 0.2,
    "Spain": 0.5,
    "Egypt": 0.2,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.6,
    "gramworthy": 0.7,
    "winter_activities": 0.5,
    "food_experience": 0.6,
    "sightseeing": 0.7
  },
  "Boston, MA": {
    "image": boston,
    "travel_type": "international",
    "cost_flight": 300,
    "cost_hotel": 233,
    "cost_food": 64,
    "cost_activities": 58,
    "cost_week": 2774,
    "celebration": 0.3,
    "relaxation": 0.2,
    "experience": 0.5,
    "family": 0.5,
    "beaches": 0.25,
    "nightlife": 0.75,
    "adventure": 0.4,
    "historical": 0.7,
    "culture": 0.6,
    "New_Zealand": 0.1,
    "Brazil": 0.1,
    "Italy": 0.2,
    "Dominican_Republic": 0.1,
    "Spain": 0.2,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.35,
    "gramworthy": 0.6,
    "winter_activities": 0.35,
    "food_experience": 0.75,
    "sightseeing": 0.6
  },
  "Brussels, Belguim": {
    "image": brussels,
    "travel_type": "international",
    "cost_flight": 750,
    "cost_hotel": 158,
    "cost_food": 41,
    "cost_activities": 25,
    "cost_week": 2740,
    "celebration": 0.5,
    "relaxation": 0.65,
    "experience": 0.75,
    "family": 0.4,
    "beaches": 0,
    "nightlife": 0.75,
    "adventure": 0.8,
    "historical": 0.7,
    "culture": 0.7,
    "New_Zealand": 0.2,
    "Brazil": 0.2,
    "Italy": 0.7,
    "Dominican_Republic": 0.1,
    "Spain": 0.5,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.5,
    "gramworthy": 0.7,
    "winter_activities": 0.6,
    "food_experience": 0.7,
    "sightseeing": 0.7
  },
  "Budapest, Hungary": {
    "image": budapest,
    "travel_type": "international",
    "cost_flight": 600,
    "cost_hotel": 68,
    "cost_food": 15,
    "cost_activities": 25,
    "cost_week": 2236,
    "celebration": 0.5,
    "relaxation": 0.3,
    "experience": 0.8,
    "family": 0.5,
    "beaches": 0.5,
    "nightlife": 0.85,
    "adventure": 0.8,
    "historical": 0.75,
    "culture": 0.95,
    "New_Zealand": 0.05,
    "Brazil": 0.5,
    "Italy": 0.8,
    "Dominican_Republic": 0,
    "Spain": 0.35,
    "Egypt": 0.2,
    "India": 0.1,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.1,
    "gramworthy": 0.8,
    "winter_activities": 0.35,
    "food_experience": 0.65,
    "sightseeing": 0.65
  },
  "Buenos Aires, Argentina": {
    "image": buenosAires,
    "travel_type": "international",
    "cost_flight": 900,
    "cost_hotel": 68,
    "cost_food": 8,
    "cost_activities": 5,
    "cost_week": 2118,
    "celebration": 0.5,
    "relaxation": 0.25,
    "experience": 0.8,
    "family": 0.4,
    "beaches": 0,
    "nightlife": 1,
    "adventure": 0.7,
    "historical": 0.7,
    "culture": 0.8,
    "New_Zealand": 0,
    "Brazil": 0.8,
    "Italy": 0.1,
    "Dominican_Republic": 0.2,
    "Spain": 0.65,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.3,
    "gramworthy": 0.8,
    "winter_activities": 0,
    "food_experience": 0.8,
    "sightseeing": 0.75
  },
  "Burma, Myanmar": {
    "image": burma,
    "travel_type": "international",
    "cost_flight": 1400,
    "cost_hotel": 19,
    "cost_food": 7,
    "cost_activities": 5,
    "cost_week": 3101,
    "celebration": 0.75,
    "relaxation": 0.85,
    "experience": 0.7,
    "family": 0.2,
    "beaches": 0.85,
    "nightlife": 0.2,
    "adventure": 0.9,
    "historical": 0.3,
    "culture": 0.6,
    "New_Zealand": 0.35,
    "Brazil": 0.4,
    "Italy": 0.2,
    "Dominican_Republic": 0.35,
    "Spain": 0.2,
    "Egypt": 0,
    "India": 0.5,
    "Thailand": 0.6,
    "Japan": 0.4,
    "nature_activities": 0.5,
    "gramworthy": 0.6,
    "winter_activities": 0,
    "food_experience": 0.55,
    "sightseeing": 0.5
  },
  "Cairo, Egypt": {
    "image": cairo,
    "travel_type": "international",
    "cost_flight": 800,
    "cost_hotel": 46,
    "cost_food": 10,
    "cost_activities": 5,
    "cost_week": 2132,
    "celebration": 0.3,
    "relaxation": 0.35,
    "experience": 0.8,
    "family": 0.75,
    "beaches": 0,
    "nightlife": 0.3,
    "adventure": 0.8,
    "historical": 1,
    "culture": 0.85,
    "New_Zealand": 0.05,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0,
    "Spain": 0.1,
    "Egypt": 1,
    "India": 0.35,
    "Thailand": 0.2,
    "Japan": 0.2,
    "nature_activities": 0.15,
    "gramworthy": 1,
    "winter_activities": 0,
    "food_experience": 0.5,
    "sightseeing": 0.95
  },
  "Charleston, SC": {
    "image": charleston,
    "travel_type": "international",
    "cost_flight": 250,
    "cost_hotel": 197,
    "cost_food": 73,
    "cost_activities": 35,
    "cost_week": 2406,
    "celebration": 0.4,
    "relaxation": 0.7,
    "experience": 0.4,
    "family": 0.7,
    "beaches": 0.6,
    "nightlife": 0.5,
    "adventure": 0.7,
    "historical": 0.5,
    "culture": 0.35,
    "New_Zealand": 0.1,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0.2,
    "Spain": 0.1,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.15,
    "Japan": 0.15,
    "nature_activities": 0.5,
    "gramworthy": 0.7,
    "winter_activities": 0.15,
    "food_experience": 0.45,
    "sightseeing": 0.5
  },
  "Chicago, IL": {
    "image": chicago,
    "travel_type": "international",
    "cost_flight": 250,
    "cost_hotel": 153,
    "cost_food": 64,
    "cost_activities": 64,
    "cost_week": 2598,
    "celebration": 0.4,
    "relaxation": 0.3,
    "experience": 0.6,
    "family": 0.5,
    "beaches": 0.25,
    "nightlife": 0.85,
    "adventure": 0.5,
    "historical": 0.6,
    "culture": 0.7,
    "New_Zealand": 0.1,
    "Brazil": 0.3,
    "Italy": 0.2,
    "Dominican_Republic": 0.1,
    "Spain": 0.2,
    "Egypt": 0.1,
    "India": 0.2,
    "Thailand": 0.2,
    "Japan": 0.2,
    "nature_activities": 0.25,
    "gramworthy": 0.35,
    "winter_activities": 0.4,
    "food_experience": 0.85,
    "sightseeing": 0.7
  },
  "Copenhagen, Denmark": {
    "image": copenhagen,
    "travel_type": "international",
    "cost_flight": 600,
    "cost_hotel": 186,
    "cost_food": 70,
    "cost_activities": 40,
    "cost_week": 3112,
    "celebration": 0.6,
    "relaxation": 0.8,
    "experience": 0.75,
    "family": 0.5,
    "beaches": 0.25,
    "nightlife": 0.75,
    "adventure": 0.7,
    "historical": 0.75,
    "culture": 0.75,
    "New_Zealand": 0.2,
    "Brazil": 0.2,
    "Italy": 0.7,
    "Dominican_Republic": 0.1,
    "Spain": 0.65,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.65,
    "gramworthy": 0.7,
    "winter_activities": 0.35,
    "food_experience": 0.7,
    "sightseeing": 0.7
  },
  "Delhi, India": {
    "image": delhi,
    "travel_type": "international",
    "cost_flight": 700,
    "cost_hotel": 53,
    "cost_food": 11,
    "cost_activities": 5,
    "cost_week": 1995,
    "celebration": 0.35,
    "relaxation": 0.25,
    "experience": 0.75,
    "family": 0.3,
    "beaches": 0,
    "nightlife": 0.5,
    "adventure": 0.75,
    "historical": 0.5,
    "culture": 0.9,
    "New_Zealand": 0,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0.1,
    "Spain": 0.1,
    "Egypt": 0.25,
    "India": 1,
    "Thailand": 0.5,
    "Japan": 0.35,
    "nature_activities": 0.1,
    "gramworthy": 0.4,
    "winter_activities": 0,
    "food_experience": 0.6,
    "sightseeing": 0.5
  },
  "Doha, Qatar": {
    "image": doha,
    "travel_type": "international",
    "cost_flight": 1000,
    "cost_hotel": 238,
    "cost_food": 70,
    "cost_activities": 25,
    "cost_week": 5662,
    "celebration": 0.5,
    "relaxation": 0.25,
    "experience": 0.85,
    "family": 0.25,
    "beaches": 0.35,
    "nightlife": 0.5,
    "adventure": 0.5,
    "historical": 0.5,
    "culture": 0.5,
    "New_Zealand": 0.2,
    "Brazil": 0.2,
    "Italy": 0.1,
    "Dominican_Republic": 0.1,
    "Spain": 0.3,
    "Egypt": 0.6,
    "India": 0.4,
    "Thailand": 0.3,
    "Japan": 0.3,
    "nature_activities": 0.3,
    "gramworthy": 0.85,
    "winter_activities": 0,
    "food_experience": 0.75,
    "sightseeing": 0.85
  },
  "Dubai, United Arab Emirates": {
    "image": dubai,
    "travel_type": "international",
    "cost_flight": 900,
    "cost_hotel": 340,
    "cost_food": 75,
    "cost_activities": 25,
    "cost_week": 3880,
    "celebration": 0.6,
    "relaxation": 0.3,
    "experience": 0.9,
    "family": 0.4,
    "beaches": 0.5,
    "nightlife": 0.35,
    "adventure": 0.85,
    "historical": 0.6,
    "culture": 0.95,
    "New_Zealand": 0.25,
    "Brazil": 0.25,
    "Italy": 0.2,
    "Dominican_Republic": 0.1,
    "Spain": 0.2,
    "Egypt": 0.65,
    "India": 0.35,
    "Thailand": 0.35,
    "Japan": 0.3,
    "nature_activities": 0.4,
    "gramworthy": 0.95,
    "winter_activities": 0,
    "food_experience": 1,
    "sightseeing": 0.8
  },
  "Dublin, Ireland": {
    "image": dublin,
    "travel_type": "international",
    "cost_flight": 600,
    "cost_hotel": 148,
    "cost_food": 53,
    "cost_activities": 27,
    "cost_week": 2616,
    "celebration": 0.4,
    "relaxation": 0.75,
    "experience": 0.8,
    "family": 0.5,
    "beaches": 0.65,
    "nightlife": 0.65,
    "adventure": 0.8,
    "historical": 0.7,
    "culture": 0.7,
    "New_Zealand": 0.3,
    "Brazil": 0.2,
    "Italy": 0.5,
    "Dominican_Republic": 0.2,
    "Spain": 0.5,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.5,
    "gramworthy": 0.7,
    "winter_activities": 0.2,
    "food_experience": 0.5,
    "sightseeing": 0.7
  },
  "Galapagos Islands": {
    "image": galapagosIslands,
    "travel_type": "international",
    "cost_flight": 1000,
    "cost_hotel": 104,
    "cost_food": 27,
    "cost_activities": 40,
    "cost_week": 3666,
    "celebration": 0.4,
    "relaxation": 1,
    "experience": 0.85,
    "family": 0.5,
    "beaches": 1,
    "nightlife": 0.1,
    "adventure": 0.85,
    "historical": 0.3,
    "culture": 0.5,
    "New_Zealand": 0.5,
    "Brazil": 0.6,
    "Italy": 0.2,
    "Dominican_Republic": 0.25,
    "Spain": 0.25,
    "Egypt": 0,
    "India": 0.05,
    "Thailand": 0.2,
    "Japan": 0.2,
    "nature_activities": 0.85,
    "gramworthy": 0.95,
    "winter_activities": 0,
    "food_experience": 0.35,
    "sightseeing": 0.5
  },
  "Glacier National Park, MT": {
    "image": glacierNationalPark,
    "travel_type": "international",
    "cost_flight": 600,
    "cost_hotel": 200,
    "cost_food": 43,
    "cost_activities": 40,
    "cost_week": 2762,
    "celebration": 0.3,
    "relaxation": 0.5,
    "experience": 1,
    "family": 1,
    "beaches": 0,
    "nightlife": 0.1,
    "adventure": 1,
    "historical": 0.5,
    "culture": 0.2,
    "New_Zealand": 0.1,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0.1,
    "Spain": 0.1,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 1,
    "gramworthy": 0.95,
    "winter_activities": 0.5,
    "food_experience": 0.1,
    "sightseeing": 1
  },
  "Goa, India": {
    "image": goa,
    "travel_type": "international",
    "cost_flight": 1150,
    "cost_hotel": 75,
    "cost_food": 25,
    "cost_activities": 19,
    "cost_week": 3441,
    "celebration": 0.6,
    "relaxation": 0.75,
    "experience": 0.75,
    "family": 0.25,
    "beaches": 0.8,
    "nightlife": 0.35,
    "adventure": 0.75,
    "historical": 0.5,
    "culture": 0.8,
    "New_Zealand": 0.5,
    "Brazil": 0.25,
    "Italy": 0.1,
    "Dominican_Republic": 0.5,
    "Spain": 0.1,
    "Egypt": 0.25,
    "India": 1,
    "Thailand": 0.75,
    "Japan": 0.5,
    "nature_activities": 0.6,
    "gramworthy": 0.75,
    "winter_activities": 0,
    "food_experience": 0.6,
    "sightseeing": 0.75
  },
  "Granada, Spain": {
    "image": granada,
    "travel_type": "international",
    "cost_flight": 700,
    "cost_hotel": 77,
    "cost_food": 55,
    "cost_activities": 35,
    "cost_week": 1869,
    "celebration": 0.3,
    "relaxation": 0.6,
    "experience": 0.65,
    "family": 0.5,
    "beaches": 0,
    "nightlife": 0.5,
    "adventure": 0.7,
    "historical": 0.9,
    "culture": 0.85,
    "New_Zealand": 0.1,
    "Brazil": 0.3,
    "Italy": 0.5,
    "Dominican_Republic": 0.2,
    "Spain": 1,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.3,
    "gramworthy": 0.7,
    "winter_activities": 0.25,
    "food_experience": 0.5,
    "sightseeing": 0.8
  },
  "Grand Canyon": {
    "image": grandCanyon,
    "travel_type": "international",
    "cost_flight": 400,
    "cost_hotel": 142,
    "cost_food": 39,
    "cost_activities": 40,
    "cost_week": 2900,
    "celebration": 0.3,
    "relaxation": 0.25,
    "experience": 1,
    "family": 1,
    "beaches": 0,
    "nightlife": 0.1,
    "adventure": 1,
    "historical": 0.65,
    "culture": 0,
    "New_Zealand": 0.1,
    "Brazil": 0,
    "Italy": 0.35,
    "Dominican_Republic": 0,
    "Spain": 0,
    "Egypt": 0.25,
    "India": 0.15,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 1,
    "gramworthy": 0.95,
    "winter_activities": 0,
    "food_experience": 0.05,
    "sightseeing": 1
  },
  "Grand Tetons National Park": {
    "image": grandTetonsNationalPark,
    "travel_type": "international",
    "cost_flight": 400,
    "cost_hotel": 127,
    "cost_food": 37,
    "cost_activities": 40,
    "cost_week": 2767,
    "celebration": 0.2,
    "relaxation": 0.2,
    "experience": 1,
    "family": 0.85,
    "beaches": 0,
    "nightlife": 0.2,
    "adventure": 1,
    "historical": 0.1,
    "culture": 0.1,
    "New_Zealand": 0.15,
    "Brazil": 0.05,
    "Italy": 0.05,
    "Dominican_Republic": 0,
    "Spain": 0,
    "Egypt": 0.25,
    "India": 0.1,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 1,
    "gramworthy": 0.85,
    "winter_activities": 0.65,
    "food_experience": 0.1,
    "sightseeing": 0.6
  },
  "Halong Bay, Vietnam": {
    "image": halongBay,
    "travel_type": "international",
    "cost_flight": 1000,
    "cost_hotel": 65,
    "cost_food": 20,
    "cost_activities": 17,
    "cost_week": 2973,
    "celebration": 0.6,
    "relaxation": 0.65,
    "experience": 1,
    "family": 0.4,
    "beaches": 0.85,
    "nightlife": 0.35,
    "adventure": 1,
    "historical": 0.4,
    "culture": 0.75,
    "New_Zealand": 0.7,
    "Brazil": 0.5,
    "Italy": 0.15,
    "Dominican_Republic": 0.7,
    "Spain": 0.1,
    "Egypt": 0.1,
    "India": 0.65,
    "Thailand": 0.75,
    "Japan": 0.75,
    "nature_activities": 1,
    "gramworthy": 1,
    "winter_activities": 0,
    "food_experience": 0.6,
    "sightseeing": 0.6
  },
  "Havana, Cuba": {
    "image": havana,
    "travel_type": "international",
    "cost_flight": 550,
    "cost_hotel": 99,
    "cost_food": 15,
    "cost_activities": 12,
    "cost_week": 2171,
    "celebration": 1,
    "relaxation": 0.9,
    "experience": 0.65,
    "family": 0.8,
    "beaches": 0.9,
    "nightlife": 0.25,
    "adventure": 0.95,
    "historical": 0.3,
    "culture": 0.5,
    "New_Zealand": 0.35,
    "Brazil": 0.25,
    "Italy": 0.25,
    "Dominican_Republic": 0.75,
    "Spain": 0,
    "Egypt": 0,
    "India": 0.1,
    "Thailand": 0.25,
    "Japan": 0.1,
    "nature_activities": 0.75,
    "gramworthy": 0.95,
    "winter_activities": 0,
    "food_experience": 0.45,
    "sightseeing": 0.25
  },
  "Ibiza, Spain": {
    "image": ibiza,
    "travel_type": "international",
    "cost_flight": 600,
    "cost_hotel": 130,
    "cost_food": 30,
    "cost_activities": 55,
    "cost_week": 3300,
    "celebration": 1,
    "relaxation": 1,
    "experience": 0.6,
    "family": 0.2,
    "beaches": 1,
    "nightlife": 1,
    "adventure": 0.2,
    "historical": 0,
    "culture": 0.55,
    "New_Zealand": 0.2,
    "Brazil": 0.4,
    "Italy": 0.5,
    "Dominican_Republic": 0.5,
    "Spain": 0.9,
    "Egypt": 0,
    "India": 0.05,
    "Thailand": 0.1,
    "Japan": 0,
    "nature_activities": 0.5,
    "gramworthy": 0.95,
    "winter_activities": 0,
    "food_experience": 0.7,
    "sightseeing": 0.5
  },
  "Istanbul, Turkey": {
    "image": istanbul,
    "travel_type": "international",
    "cost_flight": 700,
    "cost_hotel": 54,
    "cost_food": 11,
    "cost_activities": 10,
    "cost_week": 2072,
    "celebration": 0.35,
    "relaxation": 0.2,
    "experience": 0.85,
    "family": 0.35,
    "beaches": 0.2,
    "nightlife": 0.5,
    "adventure": 0.5,
    "historical": 0.85,
    "culture": 0.75,
    "New_Zealand": 0.1,
    "Brazil": 0.3,
    "Italy": 0.6,
    "Dominican_Republic": 0,
    "Spain": 0.35,
    "Egypt": 0.25,
    "India": 0.1,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.15,
    "gramworthy": 0.9,
    "winter_activities": 0.1,
    "food_experience": 0.25,
    "sightseeing": 0.75
  },
  "Jackson Hole, WY": {
    "image": jacksonHole,
    "travel_type": "international",
    "cost_flight": 650,
    "cost_hotel": 121,
    "cost_food": 60,
    "cost_activities": 50,
    "cost_week": 3687,
    "celebration": 0.2,
    "relaxation": 0,
    "experience": 0.85,
    "family": 0.25,
    "beaches": 0,
    "nightlife": 0.2,
    "adventure": 0.85,
    "historical": 0.1,
    "culture": 0.1,
    "New_Zealand": 0.15,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0,
    "Spain": 0,
    "Egypt": 0.15,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.7,
    "gramworthy": 0.75,
    "winter_activities": 1,
    "food_experience": 0.1,
    "sightseeing": 0.5
  },
  "Jamaica": {
    "image": jamaica,
    "travel_type": "international",
    "cost_flight": 550,
    "cost_hotel": 132,
    "cost_food": 20,
    "cost_activities": 10,
    "cost_week": 2444,
    "celebration": 0.75,
    "relaxation": 0.9,
    "experience": 0.85,
    "family": 0.9,
    "beaches": 0.9,
    "nightlife": 0.4,
    "adventure": 0.5,
    "historical": 0.15,
    "culture": 0.6,
    "New_Zealand": 0.35,
    "Brazil": 0.5,
    "Italy": 0,
    "Dominican_Republic": 0.85,
    "Spain": 0,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.65,
    "gramworthy": 0.75,
    "winter_activities": 0,
    "food_experience": 0.35,
    "sightseeing": 0.3
  },
  "Jerusalem, Israel": {
    "image": jerusalem,
    "travel_type": "international",
    "cost_flight": 1000,
    "cost_hotel": 88,
    "cost_food": 26,
    "cost_activities": 35,
    "cost_week": 3470,
    "celebration": 0.1,
    "relaxation": 0.15,
    "experience": 0.5,
    "family": 0.25,
    "beaches": 0.15,
    "nightlife": 0.1,
    "adventure": 0.35,
    "historical": 0.8,
    "culture": 0.65,
    "New_Zealand": 0.05,
    "Brazil": 0.4,
    "Italy": 0.85,
    "Dominican_Republic": 0,
    "Spain": 0.1,
    "Egypt": 0.35,
    "India": 0.25,
    "Thailand": 0.15,
    "Japan": 0.15,
    "nature_activities": 0.25,
    "gramworthy": 0.5,
    "winter_activities": 0,
    "food_experience": 0.5,
    "sightseeing": 0.5
  },
  "Johannesburg, South Africa": {
    "image": johannesburg,
    "travel_type": "international",
    "cost_flight": 1175,
    "cost_hotel": 89,
    "cost_food": 19,
    "cost_activities": 10,
    "cost_week": 3379,
    "celebration": 0.65,
    "relaxation": 0.9,
    "experience": undefined,
    "family": 0.5,
    "beaches": 0.9,
    "nightlife": 0.4,
    "adventure": 0.75,
    "historical": 0.25,
    "culture": 0.65,
    "New_Zealand": 0.1,
    "Brazil": 0.3,
    "Italy": 0.3,
    "Dominican_Republic": 0.1,
    "Spain": 0,
    "Egypt": 0.35,
    "India": 0.1,
    "Thailand": 0.2,
    "Japan": 0.2,
    "nature_activities": 0.85,
    "gramworthy": 0.65,
    "winter_activities": 0,
    "food_experience": 0.65,
    "sightseeing": 0.9
  },
  "Kampot, Cambodia": {
    "image": kampot,
    "travel_type": "international",
    "cost_flight": 1400,
    "cost_hotel": 25,
    "cost_food": 15,
    "cost_activities": 3,
    "cost_week": 3227,
    "celebration": 0.65,
    "relaxation": 0.65,
    "experience": 0.9,
    "family": 0.25,
    "beaches": 0.65,
    "nightlife": 0.5,
    "adventure": 0.9,
    "historical": 0.3,
    "culture": 0.75,
    "New_Zealand": 0.1,
    "Brazil": 0.4,
    "Italy": 0.15,
    "Dominican_Republic": 0.1,
    "Spain": 0.2,
    "Egypt": 0.1,
    "India": 0.5,
    "Thailand": 0.65,
    "Japan": 0.25,
    "nature_activities": 0.35,
    "gramworthy": 0.65,
    "winter_activities": 0,
    "food_experience": 0.45,
    "sightseeing": 0.5
  },
  "Kandy, Sri Lanka": {
    "image": kandy,
    "travel_type": "international",
    "cost_flight": 1500,
    "cost_hotel": 50,
    "cost_food": 15,
    "cost_activities": 10,
    "cost_week": 3700,
    "celebration": 0.75,
    "relaxation": 1,
    "experience": 0.9,
    "family": 0.2,
    "beaches": 1,
    "nightlife": 0.4,
    "adventure": 0.9,
    "historical": 0.3,
    "culture": 0.5,
    "New_Zealand": 0.1,
    "Brazil": 0.25,
    "Italy": 0.1,
    "Dominican_Republic": 0.4,
    "Spain": 0.1,
    "Egypt": 0.05,
    "India": 0.65,
    "Thailand": 0.65,
    "Japan": 0.25,
    "nature_activities": 0.75,
    "gramworthy": 0.75,
    "winter_activities": 0,
    "food_experience": 0.3,
    "sightseeing": 0.5
  },
  "Kauai, HI": {
    "image": kauai,
    "travel_type": "international",
    "cost_flight": 700,
    "cost_hotel": 563,
    "cost_food": 71,
    "cost_activities": 31,
    "cost_week": 3954,
    "celebration": 0.7,
    "relaxation": 1,
    "experience": 0.75,
    "family": 0.9,
    "beaches": 0.85,
    "nightlife": 0.3,
    "adventure": 0.7,
    "historical": 0.5,
    "culture": 0.7,
    "New_Zealand": 0.5,
    "Brazil": 0.3,
    "Italy": 0.3,
    "Dominican_Republic": 0.8,
    "Spain": 0.3,
    "Egypt": 0.1,
    "India": 0.2,
    "Thailand": 0.7,
    "Japan": 0.5,
    "nature_activities": 0.85,
    "gramworthy": 0.9,
    "winter_activities": 0,
    "food_experience": 0.65,
    "sightseeing": 0.75
  },
  "Kuala Lumpur, Malaysia": {
    "image": kualaLumpur,
    "travel_type": "international",
    "cost_flight": 1050,
    "cost_hotel": 25,
    "cost_food": 11,
    "cost_activities": 5,
    "cost_week": 2499,
    "celebration": 0.4,
    "relaxation": 1,
    "experience": 0.85,
    "family": 0.35,
    "beaches": 1,
    "nightlife": 0.4,
    "adventure": 0.85,
    "historical": 0.1,
    "culture": 0.75,
    "New_Zealand": 0.25,
    "Brazil": 0.3,
    "Italy": 0.1,
    "Dominican_Republic": 0.65,
    "Spain": 0.1,
    "Egypt": 0,
    "India": 0.35,
    "Thailand": 0.8,
    "Japan": 0.65,
    "nature_activities": 0.75,
    "gramworthy": 0.55,
    "winter_activities": 0,
    "food_experience": 0.65,
    "sightseeing": 0.65
  },
  "Kyoto, Japan": {
    "image": kyoto,
    "travel_type": "international",
    "cost_flight": 1150,
    "cost_hotel": 137,
    "cost_food": 30,
    "cost_activities": 20,
    "cost_week": 3959,
    "celebration": 0.75,
    "relaxation": 1,
    "experience": 0.7,
    "family": 0.85,
    "beaches": 0.5,
    "nightlife": 0.75,
    "adventure": 0.7,
    "historical": 0.5,
    "culture": 0.85,
    "New_Zealand": 0.05,
    "Brazil": 0.3,
    "Italy": 0.1,
    "Dominican_Republic": 0.2,
    "Spain": 0,
    "Egypt": 0,
    "India": 0.25,
    "Thailand": 0.85,
    "Japan": 1,
    "nature_activities": 0.5,
    "gramworthy": 0.95,
    "winter_activities": 0.1,
    "food_experience": 0.75,
    "sightseeing": 0.95
  },
  "Ladakh, India": {
    "image": ladakh,
    "travel_type": "international",
    "cost_flight": 1150,
    "cost_hotel": 70,
    "cost_food": 20,
    "cost_activities": 16,
    "cost_week": 3294,
    "celebration": 0.25,
    "relaxation": 0.5,
    "experience": 0.65,
    "family": 0.25,
    "beaches": 0,
    "nightlife": 0.25,
    "adventure": 0.9,
    "historical": 0.35,
    "culture": 0.4,
    "New_Zealand": 0.3,
    "Brazil": 0.2,
    "Italy": 0.2,
    "Dominican_Republic": 0.1,
    "Spain": 0.25,
    "Egypt": 0.25,
    "India": 1,
    "Thailand": 0.6,
    "Japan": 0.75,
    "nature_activities": 1,
    "gramworthy": 0.75,
    "winter_activities": 0.45,
    "food_experience": 0.35,
    "sightseeing": 0.65
  },
  "Lake Tahoe": {
    "image": lakeTahoe,
    "travel_type": "international",
    "cost_flight": 300,
    "cost_hotel": 418,
    "cost_food": 53,
    "cost_activities": 50,
    "cost_week": 2878,
    "celebration": 0.6,
    "relaxation": 0.8,
    "experience": 0.6,
    "family": 0.8,
    "beaches": 0.4,
    "nightlife": 0.3,
    "adventure": 0.85,
    "historical": 0.3,
    "culture": 0.2,
    "New_Zealand": 0.2,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0.1,
    "Spain": 0.1,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.2,
    "nature_activities": 1,
    "gramworthy": 0.75,
    "winter_activities": 0.5,
    "food_experience": 0.2,
    "sightseeing": 0.75
  },
  "Las Vegas, NV": {
    "image": lasVegas,
    "travel_type": "international",
    "cost_flight": 300,
    "cost_hotel": 170,
    "cost_food": 80,
    "cost_activities": 78,
    "cost_week": 3152,
    "celebration": 1,
    "relaxation": 0.25,
    "experience": 0.7,
    "family": 0.3,
    "beaches": 0,
    "nightlife": 1,
    "adventure": 0.6,
    "historical": 0.3,
    "culture": 0.7,
    "New_Zealand": 0.1,
    "Brazil": 0.2,
    "Italy": 0.1,
    "Dominican_Republic": 1,
    "Spain": 0.2,
    "Egypt": 0.2,
    "India": 0.1,
    "Thailand": 0.2,
    "Japan": 0.4,
    "nature_activities": 0.25,
    "gramworthy": 0.8,
    "winter_activities": 0,
    "food_experience": 0.8,
    "sightseeing": 0.8
  },
  "London, England": {
    "image": london,
    "travel_type": "international",
    "cost_flight": 500,
    "cost_hotel": 180,
    "cost_food": 100,
    "cost_activities": 100,
    "cost_week": 5060,
    "celebration": 0.75,
    "relaxation": 0.5,
    "experience": 0.5,
    "family": 0.25,
    "beaches": 0.5,
    "nightlife": 1,
    "adventure": 0.5,
    "historical": 0.8,
    "culture": 0.55,
    "New_Zealand": 0.3,
    "Brazil": 0.2,
    "Italy": 0.85,
    "Dominican_Republic": 0,
    "Spain": 0.5,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0.1,
    "nature_activities": 0.25,
    "gramworthy": 0.35,
    "winter_activities": 0.35,
    "food_experience": 0.35,
    "sightseeing": 0.75
  },
  "Los Angeles, CA": {
    "image": losAngeles,
    "travel_type": "international",
    "cost_flight": 350,
    "cost_hotel": 280,
    "cost_food": 85,
    "cost_activities": 50,
    "cost_week": 3150,
    "celebration": 0.7,
    "relaxation": 0.4,
    "experience": 0.7,
    "family": 0.4,
    "beaches": 0.8,
    "nightlife": 1,
    "adventure": 0.7,
    "historical": 0.4,
    "culture": 0.85,
    "New_Zealand": 0.1,
    "Brazil": 0.1,
    "Italy": 0.15,
    "Dominican_Republic": 0.35,
    "Spain": 0.3,
    "Egypt": 0.15,
    "India": 0.1,
    "Thailand": 0.2,
    "Japan": 0.35,
    "nature_activities": 0.15,
    "gramworthy": 0.8,
    "winter_activities": 0.1,
    "food_experience": 1,
    "sightseeing": 0.75
  },
  "Lucerne, Switzerland": {
    "image": lucerne,
    "travel_type": "international",
    "cost_flight": 750,
    "cost_hotel": 223,
    "cost_food": 100,
    "cost_activities": 50,
    "cost_week": 5161,
    "celebration": 0.5,
    "relaxation": 0.25,
    "experience": 0.9,
    "family": 0.6,
    "beaches": 0,
    "nightlife": 0.6,
    "adventure": 0.95,
    "historical": 0.6,
    "culture": 0.7,
    "New_Zealand": 0.25,
    "Brazil": 0.1,
    "Italy": 0.9,
    "Dominican_Republic": 0,
    "Spain": 0.6,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0.3,
    "nature_activities": 0.95,
    "gramworthy": 0.85,
    "winter_activities": 0.95,
    "food_experience": 0.5,
    "sightseeing": 0.75
  },
  "Machu Picchu, Peru": {
    "image": machuPicchu,
    "travel_type": "international",
    "cost_flight": 700,
    "cost_hotel": 70,
    "cost_food": 24,
    "cost_activities": 72,
    "cost_week": 2884,
    "celebration": 0.6,
    "relaxation": 0.5,
    "experience": 0.8,
    "family": 0.6,
    "beaches": 0,
    "nightlife": 0.25,
    "adventure": 1,
    "historical": 0.85,
    "culture": 0.8,
    "New_Zealand": 0.4,
    "Brazil": 0.7,
    "Italy": 0.1,
    "Dominican_Republic": 0.2,
    "Spain": 0.35,
    "Egypt": 0.1,
    "India": 0.2,
    "Thailand": 0.2,
    "Japan": 0.2,
    "nature_activities": 1,
    "gramworthy": 0.8,
    "winter_activities": 0.25,
    "food_experience": 0.5,
    "sightseeing": 0.9
  },
  "Madagascar": {
    "image": madagascar,
    "travel_type": "international",
    "cost_flight": 1300,
    "cost_hotel": 71,
    "cost_food": 31,
    "cost_activities": 18,
    "cost_week": 3428,
    "celebration": 0.35,
    "relaxation": 0.75,
    "experience": 0.85,
    "family": 0.25,
    "beaches": 0.95,
    "nightlife": 0.3,
    "adventure": 0.95,
    "historical": 0.3,
    "culture": 0.6,
    "New_Zealand": 0.85,
    "Brazil": 0.5,
    "Italy": 0.1,
    "Dominican_Republic": 0.6,
    "Spain": 0.1,
    "Egypt": 0.2,
    "India": 0.2,
    "Thailand": 0.3,
    "Japan": 0.3,
    "nature_activities": 0.85,
    "gramworthy": 0.4,
    "winter_activities": 0.1,
    "food_experience": 0.7,
    "sightseeing": 0.85
  },
  "Maldives": {
    "image": maldives,
    "travel_type": "international",
    "cost_flight": 1050,
    "cost_hotel": 188,
    "cost_food": 62,
    "cost_activities": 50,
    "cost_week": 4984,
    "celebration": 1,
    "relaxation": 1,
    "experience": 0.5,
    "family": 0.2,
    "beaches": 1,
    "nightlife": 0.3,
    "adventure": 0.5,
    "historical": 0.2,
    "culture": 0.4,
    "New_Zealand": 0.4,
    "Brazil": 0.35,
    "Italy": 0.2,
    "Dominican_Republic": 0.75,
    "Spain": 0.2,
    "Egypt": 0.05,
    "India": 0.35,
    "Thailand": 0.35,
    "Japan": 0.2,
    "nature_activities": 0.5,
    "gramworthy": 1,
    "winter_activities": 0,
    "food_experience": 0.5,
    "sightseeing": 0.5
  },
  "Manama, Bahrain": {
    "image": manama,
    "travel_type": "international",
    "cost_flight": 1000,
    "cost_hotel": 92,
    "cost_food": 60,
    "cost_activities": 40,
    "cost_week": 2344,
    "celebration": 0.5,
    "relaxation": 0.7,
    "experience": 0.7,
    "family": 0.7,
    "beaches": 0.7,
    "nightlife": 0.5,
    "adventure": 0.6,
    "historical": 0.5,
    "culture": 0.8,
    "New_Zealand": 0.1,
    "Brazil": 0.2,
    "Italy": 0.1,
    "Dominican_Republic": 0.1,
    "Spain": 0.2,
    "Egypt": 0.8,
    "India": 0.6,
    "Thailand": 0.3,
    "Japan": 0.1,
    "nature_activities": 0.3,
    "gramworthy": 0.6,
    "winter_activities": 0,
    "food_experience": 0.8,
    "sightseeing": 0.65
  },
  "Maui, HI": {
    "image": maui,
    "travel_type": "international",
    "cost_flight": 800,
    "cost_hotel": 225,
    "cost_food": 75,
    "cost_activities": 50,
    "cost_week": 4925,
    "celebration": 0.85,
    "relaxation": 1,
    "experience": 0.85,
    "family": 0.8,
    "beaches": 0.85,
    "nightlife": 0.2,
    "adventure": 1,
    "historical": 0.1,
    "culture": 0.75,
    "New_Zealand": 0.5,
    "Brazil": 0.2,
    "Italy": 0.3,
    "Dominican_Republic": 0.7,
    "Spain": 0.2,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.6,
    "Japan": 0.3,
    "nature_activities": 1,
    "gramworthy": 0.85,
    "winter_activities": 0,
    "food_experience": 0.5,
    "sightseeing": 0.6
  },
  "Medellin, Columbia": {
    "image": medellin,
    "travel_type": "international",
    "cost_flight": 600,
    "cost_hotel": 38,
    "cost_food": 12,
    "cost_activities": 15,
    "cost_week": 1055,
    "celebration": 0.4,
    "relaxation": 0.5,
    "experience": 0.75,
    "family": 0.3,
    "beaches": 0,
    "nightlife": undefined,
    "adventure": 0.6,
    "historical": 0.5,
    "culture": 0.7,
    "New_Zealand": 0.1,
    "Brazil": 0.9,
    "Italy": 0.1,
    "Dominican_Republic": 0.2,
    "Spain": 0.4,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.5,
    "gramworthy": 0.6,
    "winter_activities": 0,
    "food_experience": 0.6,
    "sightseeing": 0.7
  },
  "Mexico City, Mexico": {
    "image": mexicoCity,
    "travel_type": "international",
    "cost_flight": 600,
    "cost_hotel": 65,
    "cost_food": 30,
    "cost_activities": 15,
    "cost_week": 1370,
    "celebration": 0.5,
    "relaxation": 0.35,
    "experience": 0.8,
    "family": 0.4,
    "beaches": 0,
    "nightlife": 1,
    "adventure": 0.7,
    "historical": 0.7,
    "culture": 0.8,
    "New_Zealand": 0,
    "Brazil": 0.7,
    "Italy": 0.1,
    "Dominican_Republic": 0.1,
    "Spain": 0.5,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.3,
    "gramworthy": 0.7,
    "winter_activities": 0,
    "food_experience": 0.9,
    "sightseeing": 0.75
  },
  "Miami, FL": {
    "image": miami,
    "travel_type": "international",
    "cost_flight": 250,
    "cost_hotel": 140,
    "cost_food": 56,
    "cost_activities": 55,
    "cost_week": 2334,
    "celebration": 0.75,
    "relaxation": 0.3,
    "experience": 0.8,
    "family": 0.3,
    "beaches": 0.9,
    "nightlife": 0.9,
    "adventure": 0.75,
    "historical": 0.3,
    "culture": 0.7,
    "New_Zealand": 0.2,
    "Brazil": 0.2,
    "Italy": 0.1,
    "Dominican_Republic": 0.5,
    "Spain": 0.2,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.2,
    "nature_activities": 0.35,
    "gramworthy": 0.7,
    "winter_activities": 0,
    "food_experience": 0.25,
    "sightseeing": 0.7
  },
  "Mongolia": {
    "image": mongolia,
    "travel_type": "international",
    "cost_flight": 1550,
    "cost_hotel": 54,
    "cost_food": 12,
    "cost_activities": 7,
    "cost_week": 2061,
    "celebration": 0.5,
    "relaxation": 0.3,
    "experience": 0.8,
    "family": 0.3,
    "beaches": 0.2,
    "nightlife": 0.3,
    "adventure": 1,
    "historical": 0.7,
    "culture": 0.8,
    "New_Zealand": 0.6,
    "Brazil": 0.3,
    "Italy": 0.1,
    "Dominican_Republic": 0.2,
    "Spain": 0.1,
    "Egypt": 0.3,
    "India": 0.4,
    "Thailand": 0.4,
    "Japan": 0.5,
    "nature_activities": 0.9,
    "gramworthy": 0.75,
    "winter_activities": 0,
    "food_experience": 0.5,
    "sightseeing": 0.3
  },
  "Nashville, TN": {
    "image": nashville,
    "travel_type": "international",
    "cost_flight": 250,
    "cost_hotel": 164,
    "cost_food": 56,
    "cost_activities": 45,
    "cost_week": 2242,
    "celebration": 0.3,
    "relaxation": 0.4,
    "experience": 0.5,
    "family": 0.5,
    "beaches": 0,
    "nightlife": 0.75,
    "adventure": 0.6,
    "historical": 0.3,
    "culture": 0.6,
    "New_Zealand": 0.1,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0.1,
    "Spain": 0.2,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.25,
    "gramworthy": 0.4,
    "winter_activities": 0.3,
    "food_experience": 0.5,
    "sightseeing": 0.6
  },
  "New Orleans, LA": {
    "image": newOrleans,
    "travel_type": "international",
    "cost_flight": 350,
    "cost_hotel": 200,
    "cost_food": 36,
    "cost_activities": 40,
    "cost_week": 3164,
    "celebration": 1,
    "relaxation": 0.2,
    "experience": 1,
    "family": 0.4,
    "beaches": 0.2,
    "nightlife": 1,
    "adventure": 0.1,
    "historical": 0.25,
    "culture": 0.75,
    "New_Zealand": 0.2,
    "Brazil": 0.2,
    "Italy": 0.15,
    "Dominican_Republic": 0.2,
    "Spain": 0,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.15,
    "gramworthy": 0.5,
    "winter_activities": 0,
    "food_experience": 0.65,
    "sightseeing": 0.6
  },
  "New York City, United States of America": {
    "image": newYorkCity,
    "travel_type": "international",
    "cost_flight": 400,
    "cost_hotel": 385,
    "cost_food": 60,
    "cost_activities": 25,
    "cost_week": 4685,
    "celebration": 1,
    "relaxation": 0.5,
    "experience": 0.1,
    "family": 0.75,
    "beaches": 0.5,
    "nightlife": 1,
    "adventure": 0.1,
    "historical": 0.6,
    "culture": 1,
    "New_Zealand": 0,
    "Brazil": 0.4,
    "Italy": 0.5,
    "Dominican_Republic": 0,
    "Spain": 0,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0,
    "gramworthy": 0.85,
    "winter_activities": 0.6,
    "food_experience": 0.75,
    "sightseeing": 0.9
  },
  "New Zealand": {
    "image": newZealand,
    "travel_type": "international",
    "cost_flight": 1700,
    "cost_hotel": 107,
    "cost_food": 23,
    "cost_activities": 30,
    "cost_week": 4891,
    "celebration": 0.6,
    "relaxation": 0.75,
    "experience": 0.8,
    "family": 0.6,
    "beaches": 0.75,
    "nightlife": 0.6,
    "adventure": 0.8,
    "historical": 0.2,
    "culture": 0.5,
    "New_Zealand": 1,
    "Brazil": 0.5,
    "Italy": 0.5,
    "Dominican_Republic": 0.35,
    "Spain": 0.1,
    "Egypt": 0,
    "India": 0.05,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.85,
    "gramworthy": 0.95,
    "winter_activities": 0,
    "food_experience": 0.5,
    "sightseeing": 0.85
  },
  "Niagara Falls, Canada": {
    "image": niagaraFalls,
    "travel_type": "international",
    "cost_flight": 500,
    "cost_hotel": 100,
    "cost_food": 40,
    "cost_activities": 25,
    "cost_week": 2610,
    "celebration": 0.75,
    "relaxation": 0.6,
    "experience": 0.5,
    "family": 0.6,
    "beaches": 0,
    "nightlife": 0.85,
    "adventure": 0.5,
    "historical": 0.5,
    "culture": 0.75,
    "New_Zealand": 0,
    "Brazil": 0.4,
    "Italy": 0.5,
    "Dominican_Republic": 0,
    "Spain": 0.1,
    "Egypt": 0,
    "India": 0.1,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.5,
    "gramworthy": 0.85,
    "winter_activities": 0.25,
    "food_experience": 0.5,
    "sightseeing": 0.75
  },
  "Orlando, FL": {
    "image": orlando,
    "travel_type": "international",
    "cost_flight": 300,
    "cost_hotel": 225,
    "cost_food": 50,
    "cost_activities": 100,
    "cost_week": 4275,
    "celebration": 0.5,
    "relaxation": 0.6,
    "experience": 0.85,
    "family": 1,
    "beaches": 0.5,
    "nightlife": 0.35,
    "adventure": 0,
    "historical": 0.05,
    "culture": 0.4,
    "New_Zealand": 0.05,
    "Brazil": 0.5,
    "Italy": 0.25,
    "Dominican_Republic": 0.1,
    "Spain": 0.1,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.2,
    "gramworthy": 0.5,
    "winter_activities": 0,
    "food_experience": 0.15,
    "sightseeing": 0.75
  },
  "Outer Banks, NC": {
    "image": outerBanks,
    "travel_type": "international",
    "cost_flight": 325,
    "cost_hotel": 128,
    "cost_food": 33,
    "cost_activities": 32,
    "cost_week": 2456,
    "celebration": 0.5,
    "relaxation": 0.85,
    "experience": 0.2,
    "family": 0.85,
    "beaches": 0.85,
    "nightlife": 0.2,
    "adventure": 0.2,
    "historical": 0.1,
    "culture": 0.1,
    "New_Zealand": 0.15,
    "Brazil": 0.3,
    "Italy": 0.25,
    "Dominican_Republic": 0.2,
    "Spain": 0,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.65,
    "gramworthy": 0.5,
    "winter_activities": 0,
    "food_experience": 0.1,
    "sightseeing": 0.35
  },
  "Paris, France": {
    "image": paris,
    "travel_type": "international",
    "cost_flight": 600,
    "cost_hotel": 120,
    "cost_food": 68,
    "cost_activities": 65,
    "cost_week": 3902,
    "celebration": 1,
    "relaxation": 0,
    "experience": 0.2,
    "family": 0.75,
    "beaches": 0,
    "nightlife": 1,
    "adventure": 0.2,
    "historical": 0.5,
    "culture": 0.9,
    "New_Zealand": 0.05,
    "Brazil": 0.1,
    "Italy": 1,
    "Dominican_Republic": 0,
    "Spain": 0.5,
    "Egypt": 0.1,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.2,
    "gramworthy": 1,
    "winter_activities": 0,
    "food_experience": 1,
    "sightseeing": 1
  },
  "Phuket, Thailand": {
    "image": phuket,
    "travel_type": "international",
    "cost_flight": 1100,
    "cost_hotel": 100,
    "cost_food": 38,
    "cost_activities": 45,
    "cost_week": 4062,
    "celebration": 0.7,
    "relaxation": 0.85,
    "experience": 0.85,
    "family": 0.5,
    "beaches": 1,
    "nightlife": 0.35,
    "adventure": 0.8,
    "historical": 0.25,
    "culture": 0.6,
    "New_Zealand": 0.4,
    "Brazil": 0.5,
    "Italy": 0.1,
    "Dominican_Republic": 0.8,
    "Spain": 0.1,
    "Egypt": 0,
    "India": 0.35,
    "Thailand": 1,
    "Japan": 0.75,
    "nature_activities": 0.8,
    "gramworthy": 0.9,
    "winter_activities": 0,
    "food_experience": 0.7,
    "sightseeing": 0.8
  },
  "Portland, Oregon": {
    "image": portland,
    "travel_type": "international",
    "cost_flight": 300,
    "cost_hotel": 160,
    "cost_food": 58,
    "cost_activities": 45,
    "cost_week": 2362,
    "celebration": 0.5,
    "relaxation": 0.6,
    "experience": 0.4,
    "family": 0.7,
    "beaches": 0.15,
    "nightlife": 0.65,
    "adventure": 0.35,
    "historical": 0.35,
    "culture": 0.5,
    "New_Zealand": 0.1,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0.1,
    "Spain": 0.1,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.5,
    "gramworthy": 0.5,
    "winter_activities": 0.35,
    "food_experience": 0.5,
    "sightseeing": 0.65
  },
  "Porto, Portugal": {
    "image": porto,
    "travel_type": "international",
    "cost_flight": 725,
    "cost_hotel": 120,
    "cost_food": 50,
    "cost_activities": 50,
    "cost_week": 3690,
    "celebration": 0.25,
    "relaxation": 0.5,
    "experience": 0.4,
    "family": 0.45,
    "beaches": 0.5,
    "nightlife": 0.65,
    "adventure": 0.4,
    "historical": 0.65,
    "culture": 0.75,
    "New_Zealand": 0.05,
    "Brazil": 0.25,
    "Italy": 0.9,
    "Dominican_Republic": 0.1,
    "Spain": 0.85,
    "Egypt": 0.05,
    "India": 0,
    "Thailand": 0.1,
    "Japan": 0.05,
    "nature_activities": 0.3,
    "gramworthy": 0.5,
    "winter_activities": 0.05,
    "food_experience": 0.5,
    "sightseeing": 0.25
  },
  "Prague, Czech Republic": {
    "image": prague,
    "travel_type": "international",
    "cost_flight": 800,
    "cost_hotel": 100,
    "cost_food": 35,
    "cost_activities": 20,
    "cost_week": 3070,
    "celebration": 0.8,
    "relaxation": 0.3,
    "experience": 0.85,
    "family": 0.5,
    "beaches": 0.2,
    "nightlife": 0.8,
    "adventure": 0.2,
    "historical": 0.5,
    "culture": 0.65,
    "New_Zealand": 0.05,
    "Brazil": 0.1,
    "Italy": 1,
    "Dominican_Republic": 0,
    "Spain": 0.35,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.15,
    "gramworthy": 0.65,
    "winter_activities": 0.15,
    "food_experience": 0.7,
    "sightseeing": 0.7
  },
  "Reykjavik, Iceland": {
    "image": reykjavik,
    "travel_type": "international",
    "cost_flight": 600,
    "cost_hotel": 156,
    "cost_food": 45,
    "cost_activities": 42,
    "cost_week": 3510,
    "celebration": 0.5,
    "relaxation": 0.75,
    "experience": 0.7,
    "family": 0.25,
    "beaches": 0.25,
    "nightlife": 0.85,
    "adventure": 0.7,
    "historical": 0.5,
    "culture": 0.55,
    "New_Zealand": 0.4,
    "Brazil": 0.2,
    "Italy": 0.6,
    "Dominican_Republic": 0,
    "Spain": 0.3,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0.1,
    "Japan": 0.15,
    "nature_activities": 0.6,
    "gramworthy": 0.7,
    "winter_activities": 0.7,
    "food_experience": 0.35,
    "sightseeing": 0.75
  },
  "Rio De Janerio": {
    "image": rioDeJanerio,
    "travel_type": "international",
    "cost_flight": 800,
    "cost_hotel": 108,
    "cost_food": 17,
    "cost_activities": 10,
    "cost_week": 2734,
    "celebration": 1,
    "relaxation": 1,
    "experience": 0.2,
    "family": 0.5,
    "beaches": 1,
    "nightlife": 1,
    "adventure": 0.2,
    "historical": 0.2,
    "culture": 0.8,
    "New_Zealand": 0.15,
    "Brazil": 1,
    "Italy": 0.2,
    "Dominican_Republic": 0.5,
    "Spain": 0.5,
    "Egypt": 0,
    "India": 0.1,
    "Thailand": 0.15,
    "Japan": 0.1,
    "nature_activities": 0.6,
    "gramworthy": 0.8,
    "winter_activities": 0.05,
    "food_experience": 0.5,
    "sightseeing": 0.5
  },
  "Rome, Italy": {
    "image": rome,
    "travel_type": "international",
    "cost_flight": 550,
    "cost_hotel": 145,
    "cost_food": 45,
    "cost_activities": 65,
    "cost_week": 3655,
    "celebration": 0.7,
    "relaxation": 0.65,
    "experience": 0.5,
    "family": 0.75,
    "beaches": 0,
    "nightlife": 0.5,
    "adventure": 0.5,
    "historical": 1,
    "culture": 0.9,
    "New_Zealand": 0.1,
    "Brazil": 0.2,
    "Italy": 1,
    "Dominican_Republic": 0.1,
    "Spain": 0.75,
    "Egypt": 0.1,
    "India": 0.05,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.1,
    "gramworthy": 0.85,
    "winter_activities": 0,
    "food_experience": 0.9,
    "sightseeing": 0.95
  },
  "Saint Lucia": {
    "image": saintLucia,
    "travel_type": "international",
    "cost_flight": 400,
    "cost_hotel": 209,
    "cost_food": 41,
    "cost_activities": 75,
    "cost_week": 3887,
    "celebration": 1,
    "relaxation": 0.9,
    "experience": 0.65,
    "family": 0.8,
    "beaches": 0.9,
    "nightlife": 0.25,
    "adventure": 0.95,
    "historical": 0.3,
    "culture": 0.5,
    "New_Zealand": 0.35,
    "Brazil": 0.25,
    "Italy": 0.25,
    "Dominican_Republic": 0.75,
    "Spain": 0,
    "Egypt": 0,
    "India": 0.1,
    "Thailand": 0.25,
    "Japan": 0.1,
    "nature_activities": 0.75,
    "gramworthy": 0.95,
    "winter_activities": 0,
    "food_experience": 0.45,
    "sightseeing": 0.25
  },
  "Salzburg, Austria": {
    "image": salzburg,
    "travel_type": "international",
    "cost_flight": 750,
    "cost_hotel": 202,
    "cost_food": 52,
    "cost_activities": 37,
    "cost_week": 4160,
    "celebration": 0.6,
    "relaxation": 0.75,
    "experience": 0.55,
    "family": 0.35,
    "beaches": 0,
    "nightlife": 0.6,
    "adventure": 0.5,
    "historical": 0.85,
    "culture": 0.85,
    "New_Zealand": 0.1,
    "Brazil": 0.2,
    "Italy": 1,
    "Dominican_Republic": 0.1,
    "Spain": 0.75,
    "Egypt": 0.1,
    "India": 0.05,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.1,
    "gramworthy": 0.85,
    "winter_activities": 0.35,
    "food_experience": 0.9,
    "sightseeing": 0.8
  },
  "San Diego, CA": {
    "image": sanDiego,
    "travel_type": "international",
    "cost_flight": 400,
    "cost_hotel": 275,
    "cost_food": 60,
    "cost_activities": 55,
    "cost_week": 2960,
    "celebration": 0.4,
    "relaxation": 0.7,
    "experience": 0.5,
    "family": 0.6,
    "beaches": 0.7,
    "nightlife": 0.65,
    "adventure": 0.5,
    "historical": 0.2,
    "culture": 0.2,
    "New_Zealand": 0.2,
    "Brazil": 0.2,
    "Italy": 0.1,
    "Dominican_Republic": 0.2,
    "Spain": 0.1,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.5,
    "gramworthy": 0.7,
    "winter_activities": 0,
    "food_experience": 0.5,
    "sightseeing": 0.6
  },
  "San Francisco, CA": {
    "image": sanFrancisco,
    "travel_type": "international",
    "cost_flight": 400,
    "cost_hotel": 212,
    "cost_food": 35,
    "cost_activities": 20,
    "cost_week": 3012,
    "celebration": 0.5,
    "relaxation": 0.3,
    "experience": 0.5,
    "family": 0.65,
    "beaches": 0.3,
    "nightlife": 0.5,
    "adventure": 0.5,
    "historical": 0.1,
    "culture": 0.6,
    "New_Zealand": undefined,
    "Brazil": 0.35,
    "Italy": 0.6,
    "Dominican_Republic": 0.1,
    "Spain": 0,
    "Egypt": 0.1,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.65,
    "gramworthy": 0.65,
    "winter_activities": 0,
    "food_experience": 0.8,
    "sightseeing": 0.75
  },
  "San Jose, Costa Rica": {
    "image": sanJose,
    "travel_type": "international",
    "cost_flight": 400,
    "cost_hotel": 73,
    "cost_food": 15,
    "cost_activities": 20,
    "cost_week": 1801,
    "celebration": 0.6,
    "relaxation": 1,
    "experience": 0.8,
    "family": 0.45,
    "beaches": 1,
    "nightlife": 0.25,
    "adventure": 0.8,
    "historical": 0.1,
    "culture": 0.6,
    "New_Zealand": 0.25,
    "Brazil": 0.5,
    "Italy": 0,
    "Dominican_Republic": 0.6,
    "Spain": 0.35,
    "Egypt": 0,
    "India": 0.05,
    "Thailand": 0.2,
    "Japan": 0.1,
    "nature_activities": 0.85,
    "gramworthy": 0.75,
    "winter_activities": 0,
    "food_experience": 0.25,
    "sightseeing": 0.75
  },
  "San Juan, Puerto Rico": {
    "image": sanJuan,
    "travel_type": "international",
    "cost_flight": 250,
    "cost_hotel": 177,
    "cost_food": 41,
    "cost_activities": 49,
    "cost_week": 2119,
    "celebration": 0.5,
    "relaxation": 0.7,
    "experience": 0.7,
    "family": 0.5,
    "beaches": 0.8,
    "nightlife": 0.8,
    "adventure": 0.7,
    "historical": 0.5,
    "culture": 0.8,
    "New_Zealand": 0.25,
    "Brazil": 0.5,
    "Italy": 0.1,
    "Dominican_Republic": 0.8,
    "Spain": 0.35,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.6,
    "gramworthy": 0.7,
    "winter_activities": 0,
    "food_experience": 0.65,
    "sightseeing": 0.7
  },
  "Santiago, Chile": {
    "image": santiago,
    "travel_type": "international",
    "cost_flight": 900,
    "cost_hotel": 73,
    "cost_food": 18,
    "cost_activities": undefined,
    "cost_week": 2870,
    "celebration": 0.6,
    "relaxation": 0.6,
    "experience": 0.8,
    "family": 0.4,
    "beaches": 0,
    "nightlife": 0.6,
    "adventure": 1,
    "historical": 0.7,
    "culture": 0.8,
    "New_Zealand": 0.3,
    "Brazil": 0.8,
    "Italy": 0.1,
    "Dominican_Republic": 0.2,
    "Spain": 0.5,
    "Egypt": 0.1,
    "India": 0.2,
    "Thailand": 0.2,
    "Japan": 0.2,
    "nature_activities": 1,
    "gramworthy": 0.65,
    "winter_activities": 0.5,
    "food_experience": 0.5,
    "sightseeing": 0.75
  },
  "Santo Domingo, Dominican Republic": {
    "image": santoDomingo,
    "travel_type": "international",
    "cost_flight": 435,
    "cost_hotel": 78,
    "cost_food": 37,
    "cost_activities": 20,
    "cost_week": 1380,
    "celebration": 0.9,
    "relaxation": 0.85,
    "experience": 0.75,
    "family": 0.5,
    "beaches": 0.9,
    "nightlife": 0.75,
    "adventure": 0.7,
    "historical": 0.65,
    "culture": 0.65,
    "New_Zealand": 0.3,
    "Brazil": 0.4,
    "Italy": 0.2,
    "Dominican_Republic": 1,
    "Spain": 0.5,
    "Egypt": 0.1,
    "India": 0.2,
    "Thailand": 0.5,
    "Japan": 0.35,
    "nature_activities": 0.85,
    "gramworthy": 0.9,
    "winter_activities": 0,
    "food_experience": 0.5,
    "sightseeing": 0.5
  },
  "Seattle, WA": {
    "image": seattle,
    "travel_type": "international",
    "cost_flight": 250,
    "cost_hotel": 179,
    "cost_food": 66,
    "cost_activities": 25,
    "cost_week": 2132,
    "celebration": 0.35,
    "relaxation": 0.2,
    "experience": 0.6,
    "family": 0.5,
    "beaches": 0.2,
    "nightlife": 0.7,
    "adventure": 0.25,
    "historical": 0.25,
    "culture": 0.5,
    "New_Zealand": 0.1,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0.1,
    "Spain": 0.1,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.25,
    "gramworthy": 0.5,
    "winter_activities": 0.3,
    "food_experience": 0.6,
    "sightseeing": 0.6
  },
  "Sedona, Az": {
    "image": sedona,
    "travel_type": "international",
    "cost_flight": 500,
    "cost_hotel": 229,
    "cost_food": 63,
    "cost_activities": 30,
    "cost_week": 2760,
    "celebration": 0.3,
    "relaxation": 0.8,
    "experience": 0.7,
    "family": 0.4,
    "beaches": 0,
    "nightlife": 0.2,
    "adventure": 0.7,
    "historical": 0.1,
    "culture": 0.1,
    "New_Zealand": 0.2,
    "Brazil": 0.2,
    "Italy": 0.1,
    "Dominican_Republic": 0.15,
    "Spain": 0.1,
    "Egypt": 0.3,
    "India": 0.2,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.85,
    "gramworthy": 0.7,
    "winter_activities": 0,
    "food_experience": 0.25,
    "sightseeing": 0.7
  },
  "Shanghai, China": {
    "image": shanghai,
    "travel_type": "international",
    "cost_flight": 1500,
    "cost_hotel": 140,
    "cost_food": 19,
    "cost_activities": 10,
    "cost_week": 4386,
    "celebration": 0.5,
    "relaxation": 0.45,
    "experience": 0.85,
    "family": 0.35,
    "beaches": 0.3,
    "nightlife": 0.7,
    "adventure": 0.6,
    "historical": 0.3,
    "culture": 0.85,
    "New_Zealand": 0.05,
    "Brazil": 0.45,
    "Italy": 0.3,
    "Dominican_Republic": 0.1,
    "Spain": 0,
    "Egypt": 0,
    "India": 0.25,
    "Thailand": 0.75,
    "Japan": 0.85,
    "nature_activities": 0.1,
    "gramworthy": 0.65,
    "winter_activities": 0,
    "food_experience": 0.5,
    "sightseeing": 0.75
  },
  "Singapore": {
    "image": singapore,
    "travel_type": "international",
    "cost_flight": 1175,
    "cost_hotel": 165,
    "cost_food": 66,
    "cost_activities": 60,
    "cost_week": 5269,
    "celebration": 0.7,
    "relaxation": 1,
    "experience": 0.8,
    "family": 0.5,
    "beaches": 1,
    "nightlife": 0.7,
    "adventure": 0.8,
    "historical": 0.4,
    "culture": 0.65,
    "New_Zealand": 0.1,
    "Brazil": 0.4,
    "Italy": 0,
    "Dominican_Republic": 0.2,
    "Spain": 0.1,
    "Egypt": 0.05,
    "India": 0.25,
    "Thailand": 0.65,
    "Japan": 0.85,
    "nature_activities": 0.25,
    "gramworthy": 0.85,
    "winter_activities": 0,
    "food_experience": 1,
    "sightseeing": 0.85
  },
  "Seoul, South Korea": {
    "image": seoul,
    "travel_type": "international",
    "cost_flight": 1100,
    "cost_hotel": 108,
    "cost_food": 30,
    "cost_activities": 26,
    "cost_week": 3200,
    "celebration": 0.4,
    "relaxation": 0.5,
    "experience": 0.9,
    "family": 0.5,
    "beaches": 0.5,
    "nightlife": 0.75,
    "adventure": 0.85,
    "historical": 0.5,
    "culture": 0.7,
    "New_Zealand": 0.3,
    "Brazil": 0.3,
    "Italy": 0.3,
    "Dominican_Republic": 0.4,
    "Spain": 0.2,
    "Egypt": 0.2,
    "India": 0.1,
    "Thailand": 0.5,
    "Japan": 0.8,
    "nature_activities": 0.45,
    "gramworthy": 0.7,
    "winter_activities": 0.4,
    "food_experience": 0.7,
    "sightseeing": 0.75
  },
  "Sydney, Australia": {
    "image": sydney,
    "travel_type": "international",
    "cost_flight": 1650,
    "cost_hotel": 132,
    "cost_food": 66,
    "cost_activities": 115,
    "cost_week": 6758,
    "celebration": 0.25,
    "relaxation": 1,
    "experience": 0.9,
    "family": 0.6,
    "beaches": 1,
    "nightlife": 0.1,
    "adventure": 0.9,
    "historical": 0.3,
    "culture": 0.6,
    "New_Zealand": 0.9,
    "Brazil": 0.5,
    "Italy": 0,
    "Dominican_Republic": 0.5,
    "Spain": 0.1,
    "Egypt": 0.15,
    "India": 0.05,
    "Thailand": 0.15,
    "Japan": 0.1,
    "nature_activities": 0.75,
    "gramworthy": 0.65,
    "winter_activities": 0.2,
    "food_experience": 0.35,
    "sightseeing": 0.75
  },
  "The Republic of Seychelles": {
    "image": theRepublicOfSeychelles,
    "travel_type": "international",
    "cost_flight": 1200,
    "cost_hotel": 172,
    "cost_food": 60,
    "cost_activities": 40,
    "cost_week": 5004,
    "celebration": 0.8,
    "relaxation": 1,
    "experience": 0.8,
    "family": 0.25,
    "beaches": 1,
    "nightlife": 0.5,
    "adventure": 0.85,
    "historical": 0.25,
    "culture": 0.75,
    "New_Zealand": 0.35,
    "Brazil": 0.4,
    "Italy": 0,
    "Dominican_Republic": 0.5,
    "Spain": 0.1,
    "Egypt": 0.1,
    "India": 0.25,
    "Thailand": 0.6,
    "Japan": 0.5,
    "nature_activities": 0.9,
    "gramworthy": 0.85,
    "winter_activities": 0,
    "food_experience": 0.7,
    "sightseeing": 0.85
  },
  "Tokyo, Japan": {
    "image": tokyo,
    "travel_type": "international",
    "cost_flight": 1150,
    "cost_hotel": 147,
    "cost_food": 39,
    "cost_activities": 20,
    "cost_week": 4155,
    "celebration": 0.75,
    "relaxation": 1,
    "experience": 0.7,
    "family": 0.85,
    "beaches": 0.5,
    "nightlife": 1,
    "adventure": 0.7,
    "historical": 0.5,
    "culture": 0.75,
    "New_Zealand": 0.05,
    "Brazil": 0.3,
    "Italy": 0.1,
    "Dominican_Republic": 0.2,
    "Spain": 0,
    "Egypt": 0,
    "India": 0.25,
    "Thailand": 0.85,
    "Japan": 1,
    "nature_activities": 0.35,
    "gramworthy": 0.95,
    "winter_activities": 0.1,
    "food_experience": 0.75,
    "sightseeing": 0.95
  },
  "Tulum, Mexico": {
    "image": tulum,
    "travel_type": "international",
    "cost_flight": 600,
    "cost_hotel": 80,
    "cost_food": 23,
    "cost_activities": 15,
    "cost_week": 2292,
    "celebration": 0.85,
    "relaxation": 1,
    "experience": 0.6,
    "family": 0.5,
    "beaches": 1,
    "nightlife": 0.7,
    "adventure": 0.6,
    "historical": 0.1,
    "culture": 0.5,
    "New_Zealand": 0.25,
    "Brazil": 0.5,
    "Italy": 0,
    "Dominican_Republic": 0.75,
    "Spain": 0.4,
    "Egypt": 0.25,
    "India": 0.15,
    "Thailand": 0.25,
    "Japan": 0.1,
    "nature_activities": 0.85,
    "gramworthy": 0.9,
    "winter_activities": 0,
    "food_experience": 0.5,
    "sightseeing": 0.75
  },
  "Vancouver, Canada": {
    "image": vancouver,
    "travel_type": "international",
    "cost_flight": 400,
    "cost_hotel": 175,
    "cost_food": 43,
    "cost_activities": 50,
    "cost_week": 2452,
    "celebration": 0.3,
    "relaxation": 0.4,
    "experience": 0.35,
    "family": 0.45,
    "beaches": 0.2,
    "nightlife": 0.75,
    "adventure": 0.3,
    "historical": 0.2,
    "culture": 0.4,
    "New_Zealand": 0.1,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0.1,
    "Spain": 0.1,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 0.3,
    "gramworthy": 0.4,
    "winter_activities": 1,
    "food_experience": 0.3,
    "sightseeing": 0.4
  },
  "Venice, Italy": {
    "image": venice,
    "travel_type": "international",
    "cost_flight": 675,
    "cost_hotel": 143,
    "cost_food": 78,
    "cost_activities": 111,
    "cost_week": 2999,
    "celebration": 0.6,
    "relaxation": 0.5,
    "experience": 0.65,
    "family": 0.6,
    "beaches": 0.4,
    "nightlife": 0.7,
    "adventure": 0.6,
    "historical": 0.9,
    "culture": 0.8,
    "New_Zealand": 0.2,
    "Brazil": 0.2,
    "Italy": 1,
    "Dominican_Republic": 0.2,
    "Spain": 0.85,
    "Egypt": 0.1,
    "India": 0.2,
    "Thailand": 0.2,
    "Japan": 0.3,
    "nature_activities": 0.4,
    "gramworthy": 0.9,
    "winter_activities": 0.2,
    "food_experience": 0.7,
    "sightseeing": 0.75
  },
  "Washington, D.C.": {
    "image": washington,
    "travel_type": "international",
    "cost_flight": 250,
    "cost_hotel": 181,
    "cost_food": 30,
    "cost_activities": 37,
    "cost_week": 2705,
    "celebration": 0.7,
    "relaxation": 0,
    "experience": 0.1,
    "family": 0.75,
    "beaches": 0,
    "nightlife": 0.7,
    "adventure": 0.1,
    "historical": 0.75,
    "culture": 0.7,
    "New_Zealand": 0.05,
    "Brazil": 0.05,
    "Italy": 0.5,
    "Dominican_Republic": 0,
    "Spain": 0,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.1,
    "gramworthy": 0.3,
    "winter_activities": 0.1,
    "food_experience": 0.25,
    "sightseeing": 0.65
  },
  "Xi an, China": {
    "image": xiAn,
    "travel_type": "international",
    "cost_flight": 1300,
    "cost_hotel": 123,
    "cost_food": 20,
    "cost_activities": undefined,
    "cost_week": 4231,
    "celebration": 0.5,
    "relaxation": 0.3,
    "experience": 0.85,
    "family": 0.35,
    "beaches": 0,
    "nightlife": 0.3,
    "adventure": 0.6,
    "historical": 0.9,
    "culture": 0.85,
    "New_Zealand": 0.05,
    "Brazil": 0.45,
    "Italy": 0.3,
    "Dominican_Republic": 0.1,
    "Spain": 0,
    "Egypt": 0,
    "India": 0.25,
    "Thailand": 0.75,
    "Japan": 0.85,
    "nature_activities": 0.1,
    "gramworthy": 0.65,
    "winter_activities": 0,
    "food_experience": 0.5,
    "sightseeing": 0.65
  },
  "Yellowstone": {
    "image": yellowstone,
    "travel_type": "international",
    "cost_flight": 400,
    "cost_hotel": 118,
    "cost_food": 37,
    "cost_activities": 30,
    "cost_week": 2564,
    "celebration": 0.2,
    "relaxation": 0.2,
    "experience": 1,
    "family": 1,
    "beaches": 0,
    "nightlife": 0.2,
    "adventure": 1,
    "historical": 0.2,
    "culture": 0.2,
    "New_Zealand": 0.15,
    "Brazil": 0.05,
    "Italy": 0.1,
    "Dominican_Republic": 0,
    "Spain": 0,
    "Egypt": 0.25,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 1,
    "gramworthy": 0.85,
    "winter_activities": 0.5,
    "food_experience": 0.1,
    "sightseeing": 0.75
  },
  "Zanzibar, Tanzania": {
    "image": zanzibar,
    "travel_type": "international",
    "cost_flight": 1150,
    "cost_hotel": 66,
    "cost_food": 20,
    "cost_activities": 15,
    "cost_week": 1857,
    "celebration": 0.9,
    "relaxation": 0.85,
    "experience": 0.7,
    "family": 0.4,
    "beaches": 0.9,
    "nightlife": 0.5,
    "adventure": 0.7,
    "historical": 0.4,
    "culture": 0.9,
    "New_Zealand": 0.4,
    "Brazil": 0.6,
    "Italy": 0.2,
    "Dominican_Republic": 0.75,
    "Spain": 0.2,
    "Egypt": 0.3,
    "India": 0.2,
    "Thailand": 0.8,
    "Japan": 0.5,
    "nature_activities": 0.6,
    "gramworthy": 0.8,
    "winter_activities": 0,
    "food_experience": 0.7,
    "sightseeing": 0.6
  },
  "Zion National Park, UT": {
    "image": zionNationalPark,
    "travel_type": "international",
    "cost_flight": 350,
    "cost_hotel": 298,
    "cost_food": 53,
    "cost_activities": 41,
    "cost_week": 2612,
    "celebration": 0.3,
    "relaxation": 0.6,
    "experience": 1,
    "family": 1,
    "beaches": 0,
    "nightlife": 0.1,
    "adventure": 1,
    "historical": 0.5,
    "culture": 0.2,
    "New_Zealand": 0.1,
    "Brazil": 0.1,
    "Italy": 0.1,
    "Dominican_Republic": 0.1,
    "Spain": 0.1,
    "Egypt": 0.1,
    "India": 0.1,
    "Thailand": 0.1,
    "Japan": 0.1,
    "nature_activities": 1,
    "gramworthy": 0.95,
    "winter_activities": 0.5,
    "food_experience": 0.1,
    "sightseeing": 1
  },
  "Zurich, Switzerland": {
    "image": zurich,
    "travel_type": "international",
    "cost_flight": 750,
    "cost_hotel": 243,
    "cost_food": 45,
    "cost_activities": 75,
    "cost_week": 4881,
    "celebration": 0.6,
    "relaxation": 0.35,
    "experience": 0.8,
    "family": 0.7,
    "beaches": 0,
    "nightlife": 0.6,
    "adventure": 0.8,
    "historical": 0.8,
    "culture": 0.8,
    "New_Zealand": 0.05,
    "Brazil": 0.2,
    "Italy": 0.9,
    "Dominican_Republic": 0,
    "Spain": 0.65,
    "Egypt": 0,
    "India": 0,
    "Thailand": 0,
    "Japan": 0,
    "nature_activities": 0.25,
    "gramworthy": 0.85,
    "winter_activities": 0.65,
    "food_experience": 0.85,
    "sightseeing": 0.75
  },
};