import { SurveyStyles } from "./survey-styles";
import Dates from "../../Helpers/survey-helpers/traveling-dates/traveling-dates";
import React, { useState, useEffect } from "react";
import SurveyPopUp from "../../Modals/survey/survey-pop-up";
import WhyTraveling from "../../Helpers/survey-helpers/why-traveling/why-traveling";
import NumPeople from "../../Helpers/survey-helpers/num-people/num-people";
import TravelType from "../../Helpers/survey-helpers/travel-type/travel-type";
import TripCharacteristics from "../../Helpers/survey-helpers/trip-characteristics/trip-characteristics";
import TripBudget from "../../Helpers/survey-helpers/trip-budget/trip-budget";
import WhatCountries from "../../Helpers/survey-helpers/what-countries/what-countries";
import WhatActivities from "../../Helpers/survey-helpers/what-activities/what-activities";
import WhatCelebrating from "../../Helpers/survey-helpers/what-celebrating/what-celebrating";
import TripSuggestion from "../../Helpers/survey-helpers/trip-suggestion/trip-suggestion";
import classNames from "classnames";
import {
  SuggestBudgeted,
  SuggestNoBudget,
  fetchData,
} from "../../Helpers/survey-helpers/suggestion-algorithm/suggestion-algorithm";
import { useUserAuth } from "../../UserAuthContext";
import ReactGA from "react-ga4";
import Start from "../../Helpers/survey-helpers/start/start";
function Survey() {
  // state variable to keep track of the page
  const [budgetType, setBudgetType] = useState("Overall");
  const { setName, setReferrer } = useUserAuth();
  const [pageNum, setPageNum] = useState(1);
  const [suggestion, setSuggestion] = useState([]);
  const [topThree, setTopThree] = useState([]);
  const [destinationsData, setDestinationsData] = useState({});
  const [accessibleQuestions, setAccessibleQuestions] = useState(1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNum]);

  useEffect(() => {
    const name = window.localStorage.getItem("name");
    if (name !== null) setName(JSON.parse(name));
    const referrer = window.localStorage.getItem("referrer");
    if (referrer !== null) setReferrer(JSON.parse(referrer));
    //eslint-disable-next-line
  }, []);

  // Used to render the celebration page
  const [showCelebration, setShowCelebration] = useState(false);
  const [renderCelebration, setRenderCelebration] = useState(false);

  // Boolean state variable for modal
  const [showModal, setShowModal] = useState(false);

  // Data collection for entire survey to be used in suggestion algorithm
  const [whenTraveling, setWhenTraveling] = useState();

  const [numNights, setnumNights] = useState();

  const [whyTraveling, setWhyTraveling] = useState({
    celebration: false,
    relaxation: false,
    experience: false,
    family: false,
  });

  const [celebrationType, setCelebrationType] = useState("");
  const [numTraveling, setNumTraveling] = useState(1);

  const [tripBudget, setTripBudget] = useState({
    overallBudget: 100,
    stayBudget: 100,
    flightBudget: 100,
    activitiesBudget: 100,
    transportationBudget: 100,
    diningBudget: 100,
  });

  const [travelType, setTravelType] = useState({
    international: false,
    domestic: false,
  });

  const [tripCharacteristics, setTripCharacteristics] = useState({
    beaches: false,
    nightlife: false,
    adventure: false,
    historical: false,
    culture: false,
  });

  const [whatCountries, setWhatCountries] = useState({
    Italy: "",
    New_Zealand: "",
    Dominican_Republic: "",
    Spain: "",
    Egypt: "",
    India: "",
    Thailand: "",
    Japan: "",
    Brazil: "",
  });

  const [activities, setActivities] = useState({
    nature_activities: "",
    gramworthy: "",
    winter_activities: "",
    food_experience: "",
    sightseeing: "",
  });

  const backController = (num) => {
    if (pageNum > num) setPageNum(num);
    if (num <= accessibleQuestions) setPageNum(num);
  };

  useEffect(() => {
    if (pageNum > accessibleQuestions) {
      const eventCategory = "Page Navigation";
      const eventAction = `surveypage_${pageNum}`;
      // Send the event to Google Analytics
      ReactGA.event({
        category: eventCategory,
        action: eventAction,
      });

      // Update the accessibleQuestions state
      setAccessibleQuestions(pageNum);
    }
  }, [pageNum, accessibleQuestions]);

  const nextQuestion = () => {
    setRenderCelebration(false);
    // Create a switch statement for the different pages
    switch (pageNum) {
      case 0:
        const eventCategory = "Page Navigation";
        const eventAction = `surveypage_${pageNum+1}`;
        // Send the event to Google Analytics
        ReactGA.event({
          category: eventCategory,
          action: eventAction,
        });
        setPageNum(pageNum + 1);
        return;
      case 1:
        setPageNum(pageNum + 1);
        return;
      case 2:
        if (showCelebration) {
          setShowCelebration(false);
          whyTraveling.celebration = true;
          setRenderCelebration(true);
        } else {
          setPageNum(pageNum + 1);
        }
        return;
      case 3:
        setPageNum(pageNum + 1);
        return;
      case 4:
        setPageNum(pageNum + 1);
        return;

      //Skip international/domestic question
      /* case 5:
        setPageNum(pageNum + 1);
        return; */

      case 5:
        fetchData(setDestinationsData);
        setShowModal(!showModal);
        return;
      case 6:
        setPageNum(pageNum + 1);
        return;
      case 7:
        var suggestionBudgeted = SuggestBudgeted(
          destinationsData,
          numTraveling,
          numNights,
          travelType,
          tripCharacteristics,
          whatCountries,
          activities,
          tripBudget,
          whyTraveling,
          budgetType
        );
        setSuggestion(suggestionBudgeted);
        var suggestionNoBudget = SuggestNoBudget(
          destinationsData,
          numTraveling,
          numNights,
          travelType,
          tripCharacteristics,
          whatCountries,
          activities,
          whyTraveling
        );
        setTopThree(suggestionNoBudget);
        setShowModal(!showModal);
        return;
      default:
        return null;
    }
  };

  if (pageNum === 0){
    return(
   
      <Start nextQuestion={nextQuestion} />

    )
  }
  else

  return pageNum !== 9 ? (
    <SurveyStyles>
      <div className="container mt-5 mb-5" style={{ fontFamily: "Myriad Pro" }}>
        <div className="row">
          <div className="col survey-question text-center">
            <div>
              {pageNum === 1 ? (
                <Dates
                  whenTraveling={whenTraveling}
                  setWhenTraveling={setWhenTraveling}
                  setNumNights={setnumNights}
                  numNights={numNights}
                  nextQuestion={nextQuestion}
                />
              ) : null}
              {pageNum === 2 && !renderCelebration ? (
                <WhyTraveling
                  whyTraveling={whyTraveling}
                  setWhyTraveling={setWhyTraveling}
                  showCelebration={showCelebration}
                  setShowCelebration={setShowCelebration}
                  nextQuestion={nextQuestion}
                />
              ) : null}
              {pageNum === 3 && !renderCelebration ? (
                <NumPeople
                  numTraveling={numTraveling}
                  setNumTraveling={setNumTraveling}
                  nextQuestion={nextQuestion}
                />
              ) : null}
              {pageNum === 4 ? (
                <TripBudget
                  tripBudget={tripBudget}
                  budgetType={budgetType}
                  setBudgetType={setBudgetType}
                  setTripBudget={setTripBudget}
                  nextQuestion={nextQuestion}
                />
              ) : null}
              {/* 
              {pageNum === 5 ? (
                <TravelType
                  travelType={travelType}
                  setTravelType={setTravelType}
                  nextQuestion={nextQuestion}
                />
              ) : null}
               */}
              {pageNum === 5 ? (
                <TripCharacteristics
                  tripCharacteristics={tripCharacteristics}
                  setTripCharacteristics={setTripCharacteristics}
                  nextQuestion={nextQuestion}
                />
              ) : null}
              {pageNum === 6 ? (
                <WhatCountries
                  whatCountries={whatCountries}
                  setWhatCountries={setWhatCountries}
                  nextQuestion={nextQuestion}
                />
              ) : null}
              {pageNum === 7 ? (
                <WhatActivities
                  activities={activities}
                  setActivities={setActivities}
                  nextQuestion={nextQuestion}
                />
              ) : null}
              {/* Todo: fix how this is rendered*/}
              {renderCelebration ? (
                <WhatCelebrating
                  celebrationType={celebrationType}
                  setCelebrationType={setCelebrationType}
                  nextQuestion={nextQuestion}
                />
              ) : null}
              {pageNum !== 0 && (
                <div className="survey-pagination">
                  <span className="survey-number pt-3">
                    Question {pageNum} of 7
                  </span>

                  <div className="d-flex mt-3">
                    <div
                      onClick={() => {
                        backController(pageNum === 1 ? 1 : pageNum - 1);
                      }}
                      className="arrow left"
                      style={{
                        borderColor: pageNum === 1 ? "#9d9d9d" : "#009999",
                      }}
                    ></div>
                    <div
                      onClick={() => backController(1)}
                      className={classNames({
                        circle: pageNum !== 1,
                        "circle-current": pageNum === 1,
                      })}
                    />
                    <div
                      onClick={() => backController(2)}
                      className={classNames({
                        circle: pageNum !== 2,
                        "circle-current": pageNum === 2,
                      })}
                    />
                    <div
                      onClick={() => backController(3)}
                      className={classNames({
                        circle: pageNum !== 3,
                        "circle-current": pageNum === 3,
                      })}
                    />
                    <div
                      onClick={() => backController(4)}
                      className={classNames({
                        circle: pageNum !== 4,
                        "circle-current": pageNum === 4,
                      })}
                    />
                    {/* 
                    <div
                      onClick={() => backController(5)}
                      className={classNames({
                        circle: pageNum !== 5,
                        "circle-current": pageNum === 5,
                      })}
                    />
                    */}
                    <div
                      onClick={() => backController(6)}
                      className={classNames({
                        circle: pageNum !== 5,
                        "circle-current": pageNum === 5,
                      })}
                    />
                    <div
                      onClick={() => backController(7)}
                      className={classNames({
                        circle: pageNum !== 6,
                        "circle-current": pageNum === 6,
                      })}
                    />
                    <div
                      onClick={() => backController(8)}
                      className={classNames({
                        circle: pageNum !== 7,
                        "circle-current": pageNum === 7,
                      })}
                    />
                    <div
                      onClick={() => {
                        backController(pageNum === 8 ? 8 : pageNum + 1);
                      }}
                      className="arrow right"
                      style={{
                        borderColor:
                          pageNum === 8 || accessibleQuestions === pageNum
                            ? "#9d9d9d"
                            : "#009999",
                      }}
                    ></div>
                  </div>
                  <span className="survey-number pt-3">
                    Click the circles or arrows to go back to your previously
                    answered questions.
                  </span>
                </div>
              )}
            </div>
            {showModal ? (
              <SurveyPopUp
                pageNum={pageNum}
                setShowModal={setShowModal}
                setPageNum={setPageNum}
              />
            ) : null}
          </div>
        </div>
      </div>
    </SurveyStyles>
  ) : (
    <SurveyStyles>
      <TripSuggestion
        topThree={topThree}
        suggestion={suggestion}
        numNights={numNights}
        travelType={travelType}
        tripCharacteristics={tripCharacteristics}
        numTraveling={numTraveling}
        whenTraveling={whenTraveling}
        whyTraveling={whyTraveling}
        tripBudget={tripBudget}
        whatCountries={whatCountries}
        activities={activities}
        budgetType={budgetType}
      />
    </SurveyStyles>
  );
}

export default Survey;
