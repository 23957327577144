import { db } from "../../firebase";
import { doc, deleteDoc, getDoc } from "firebase/firestore";

const deleteFriendGroup = async (groupId) => {
    try {
        const groupDoc = doc(db, "Groups", groupId);
        const groupDocSnapshot = await getDoc(groupDoc);

        if (!groupDocSnapshot.exists()) {
            throw new Error("Group not found");
        };

        //Get members to delete each user group doc reference
        const groupData = groupDocSnapshot.data();
        const members = groupData.members;

        // Iterate over members' keys (userId)
        for (const memberId of Object.keys(members)) {
            const userGroupDoc = doc(db, "Users", memberId, "groups", groupId);
            await deleteDoc(userGroupDoc);
        }
        
        await deleteDoc(groupDoc);
    } catch (error) {
        console.error("Error deleting group:", error.message);
        throw new Error(error.message);
    }
};

export default deleteFriendGroup;