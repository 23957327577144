import image0 from '../../Routes/game/images/image0.jpg';
import image1 from '../../Routes/game/images/image1.jpg';
import image10 from '../../Routes/game/images/image10.jpg';
import image11 from '../../Routes/game/images/image11.jpg';
import image12 from '../../Routes/game/images/image12.jpg';
import image13 from '../../Routes/game/images/image13.jpg';
import image14 from '../../Routes/game/images/image14.jpg';
import image15 from '../../Routes/game/images/image15.jpg';
import image16 from '../../Routes/game/images/image16.jpg';
import image17 from '../../Routes/game/images/image17.jpg';
import image18 from '../../Routes/game/images/image18.jpg';
import image19 from '../../Routes/game/images/image19.jpg';
import image2 from '../../Routes/game/images/image2.jpg';
import image20 from '../../Routes/game/images/image20.jpg';
import image21 from '../../Routes/game/images/image21.jpg';
import image22 from '../../Routes/game/images/image22.jpg';
import image23 from '../../Routes/game/images/image23.jpg';
import image24 from '../../Routes/game/images/image24.jpg';
import image25 from '../../Routes/game/images/image25.jpg';
import image26 from '../../Routes/game/images/image26.jpg';
import image27 from '../../Routes/game/images/image27.jpg';
import image28 from '../../Routes/game/images/image28.jpg';
import image29 from '../../Routes/game/images/image29.jpg';
import image3 from '../../Routes/game/images/image3.jpg';
import image30 from '../../Routes/game/images/image30.jpg';
import image31 from '../../Routes/game/images/image31.jpg';
import image32 from '../../Routes/game/images/image32.jpg';
import image33 from '../../Routes/game/images/image33.jpg';
import image34 from '../../Routes/game/images/image34.jpg';
import image35 from '../../Routes/game/images/image35.jpg';
import image36 from '../../Routes/game/images/image36.jpg';
import image37 from '../../Routes/game/images/image37.jpg';
import image38 from '../../Routes/game/images/image38.jpg';
import image39 from '../../Routes/game/images/image39.jpg';
import image4 from '../../Routes/game/images/image4.jpg';
import image40 from '../../Routes/game/images/image40.jpg';
import image41 from '../../Routes/game/images/image41.jpg';
import image42 from '../../Routes/game/images/image42.jpg';
import image43 from '../../Routes/game/images/image43.jpg';
import image44 from '../../Routes/game/images/image44.jpg';
import image45 from '../../Routes/game/images/image45.jpg';
import image46 from '../../Routes/game/images/image46.jpg';
import image47 from '../../Routes/game/images/image47.jpg';
import image48 from '../../Routes/game/images/image48.jpg';
import image49 from '../../Routes/game/images/image49.jpg';
import image5 from '../../Routes/game/images/image5.jpg';
import image6 from '../../Routes/game/images/image6.jpg';
import image7 from '../../Routes/game/images/image7.jpg';
import image8 from '../../Routes/game/images/image8.jpg';
import image9 from '../../Routes/game/images/image9.jpg';

export const imageData = {
    0: {
        image: image0,
        adventure: 0,
        beaches: 462,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 549,
        egypt: 0,
        experience: 0,
        family: 0,
        foodExperience: 0,
        gramworthy: 195,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 249,
        newZealand: 488,
        nightlife: 0,
        relaxation: 564,
        sightseeing: 0,
        spain: 0,
        winterActivities: 0
    },
    1: {
        image: image1,
        adventure: 221,
        beaches: 0,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 242,
        family: 0,
        foodExperience: 0,
        gramworthy: 213,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 399,
        natureActivities: 406,
        newZealand: 798,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 216,
        spain: 299,
        winterActivities: 0
    },
    10: {
        image: image10,
        adventure: 119,
        beaches: 0,
        celebration: 0,
        culture: 78,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 87,
        family: 0,
        foodExperience: 0,
        gramworthy: 342,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 145,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 348,
        spain: 0,
        winterActivities: 0
    },
    11: {
        image: image11,
        adventure: 56,
        beaches: 0,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 589,
        experience: 0,
        family: 0,
        foodExperience: 0,
        gramworthy: 321,
        historical: 490,
        india: 289,
        italy: 589,
        japan: 134,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 326,
        spain: 0,
        winterActivities: 0
    },
    12: {
        image: image12,
        adventure: 0,
        beaches: 354,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 840,
        egypt: 0,
        experience: 0,
        family: 0,
        foodExperience: 0,
        gramworthy: 100,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 190,
        newZealand: 249,
        nightlife: 0,
        relaxation: 768,
        sightseeing: 0,
        spain: 0,
        winterActivities: 0
    },
    13: {
        image: image13,
        adventure: 119,
        beaches: 0,
        celebration: 0,
        culture: 467,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 173,
        family: 0,
        foodExperience: 0,
        gramworthy: 0,
        historical: 784,
        india: 617,
        italy: 0,
        japan: 286,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 348,
        spain: 0,
        winterActivities: 0
    },
    14: {
        image: image14,
        adventure: 84,
        beaches: 770,
        celebration: 183,
        culture: 0,
        dominicanRepublic: 686,
        egypt: 0,
        experience: 0,
        family: 0,
        foodExperience: 0,
        gramworthy: 0,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 0,
        newZealand: 203,
        nightlife: 0,
        relaxation: 627,
        sightseeing: 0,
        spain: 0,
        winterActivities: 0
    },
    15: {
        image: image15,
        adventure: 0,
        beaches: 0,
        celebration: 0,
        culture: 589,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 82,
        family: 0,
        foodExperience: 1022,
        gramworthy: 0,
        historical: 0,
        india: 291,
        italy: 0,
        japan: 809,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 0,
        spain: 0,
        winterActivities: 0
    },
    16: {
        image: image16,
        adventure: 0,
        beaches: 0,
        celebration: 390,
        culture: 177,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 131,
        family: 0,
        foodExperience: 411,
        gramworthy: 0,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 217,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 627,
        relaxation: 0,
        sightseeing: 88,
        spain: 244,
        winterActivities: 0
    },
    17: {
        image: image17,
        adventure: 334,
        beaches: 254,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 602,
        egypt: 0,
        experience: 81,
        family: 0,
        foodExperience: 0,
        gramworthy: 107,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 409,
        newZealand: 535,
        nightlife: 0,
        relaxation: null,
        sightseeing: 0,
        spain: 0,
        winterActivities: 0
    },
    18: {
        image: image18,
        adventure: 181,
        beaches: 0,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 132,
        family: 1262,
        foodExperience: 0,
        gramworthy: 0,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 109,
        natureActivities: 445,
        newZealand: 327,
        nightlife: 0,
        relaxation: 337,
        sightseeing: 0,
        spain: 0,
        winterActivities: 0
    },
    19: {
        image: image19,
        adventure: 548,
        beaches: 0,
        celebration: 0,
        culture: 288,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 481,
        family: 0,
        foodExperience: 0,
        gramworthy: 317,
        historical: 0,
        india: 143,
        italy: 0,
        japan: 0,
        natureActivities: 538,
        newZealand: 264,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 214,
        spain: 0,
        winterActivities: 0
    },
    2: {
        image: image2,
        adventure: 224,
        beaches: 0,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 245,
        family: 779,
        foodExperience: 0,
        gramworthy: 108,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 269,
        natureActivities: 411,
        newZealand: 538,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 219,
        spain: 0,
        winterActivities: 0
    },
    20: {
        image: image20,
        adventure: 217,
        beaches: 0,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 294,
        egypt: 0,
        experience: 238,
        family: 0,
        foodExperience: 0,
        gramworthy: 157,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 131,
        natureActivities: 267,
        newZealand: 262,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 319,
        spain: 294,
        winterActivities: 0
    },
    21: {
        image: image21,
        adventure: 184,
        beaches: 0,
        celebration: 0,
        culture: 242,
        dominicanRepublic: 0,
        egypt: 979,
        experience: 202,
        family: 0,
        foodExperience: 421,
        gramworthy: 178,
        historical: 407,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 180,
        spain: 0,
        winterActivities: 0
    },
    22: {
        image: image22,
        adventure: 202,
        beaches: 0,
        celebration: 0,
        culture: 265,
        dominicanRepublic: 0,
        egypt: 1340,
        experience: 148,
        family: 0,
        foodExperience: 0,
        gramworthy: 97,
        historical: 445,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 296,
        spain: 0,
        winterActivities: 0
    },
    23: {
        image: image23,
        adventure: 0,
        beaches: 0,
        celebration: 678,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 277,
        experience: 0,
        family: 0,
        foodExperience: 0,
        gramworthy: 201,
        historical: 0,
        india: 0,
        italy: 277,
        japan: 251,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 726,
        relaxation: 0,
        sightseeing: 102,
        spain: 282,
        winterActivities: 0
    },
    24: {
        image: image24,
        adventure: 171,
        beaches: 0,
        celebration: 554,
        culture: 168,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 0,
        family: 990,
        foodExperience: 292,
        gramworthy: 246,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 205,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 167,
        spain: 0,
        winterActivities: 0
    },
    25: {
        image: image25,
        adventure: 0,
        beaches: 0,
        celebration: 0,
        culture: 243,
        dominicanRepublic: 0,
        egypt: 491,
        experience: 135,
        family: 0,
        foodExperience: 211,
        gramworthy: 178,
        historical: 612,
        india: 0,
        italy: 491,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 181,
        spain: 251,
        winterActivities: 0
    },
    26: {
        image: image26,
        adventure: 0,
        beaches: 0,
        celebration: 0,
        culture: 242,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 135,
        family: 0,
        foodExperience: 420,
        gramworthy: 177,
        historical: 406,
        india: 0,
        italy: 733,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 180,
        spain: 499,
        winterActivities: 0
    },
    27: {
        image: image27,
        adventure: 0,
        beaches: 0,
        celebration: 0,
        culture: 334,
        dominicanRepublic: 0,
        egypt: 450,
        experience: 124,
        family: 0,
        foodExperience: 193,
        gramworthy: 82,
        historical: 560,
        india: 661,
        italy: 225,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 166,
        spain: 0,
        winterActivities: 0
    },
    28: {
        image: image28,
        adventure: 73,
        beaches: 497,
        celebration: 0,
        culture: 191,
        dominicanRepublic: 393,
        egypt: 0,
        experience: 106,
        family: 0,
        foodExperience: 0,
        gramworthy: 140,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 267,
        newZealand: 0,
        nightlife: 169,
        relaxation: 405,
        sightseeing: 142,
        spain: 0,
        winterActivities: 0
    },
    29: {
        image: image29,
        adventure: 0,
        beaches: 0,
        celebration: 384,
        culture: 93,
        dominicanRepublic: 384,
        egypt: 0,
        experience: 78,
        family: 0,
        foodExperience: 0,
        gramworthy: 68,
        historical: 0,
        india: 0,
        italy: 188,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 822,
        relaxation: 0,
        sightseeing: 0,
        spain: 576,
        winterActivities: 0
    },
    3: {
        image: image3,
        adventure: 0,
        beaches: 0,
        celebration: 0,
        culture: 253,
        dominicanRepublic: 0,
        egypt: 128,
        experience: 211,
        family: 0,
        foodExperience: 0,
        gramworthy: 93,
        historical: 638,
        india: 251,
        italy: 768,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 189,
        spain: 261,
        winterActivities: 0
    },
    30: {
        image: image30,
        adventure: 220,
        beaches: 0,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 161,
        family: 0,
        foodExperience: 0,
        gramworthy: 106,
        historical: 0,
        india: 286,
        italy: 292,
        japan: 265,
        natureActivities: 270,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 0,
        spain: 0,
        winterActivities: 1193
    },
    31: {
        image: image31,
        adventure: 0,
        beaches: 0,
        celebration: 0,
        culture: 188,
        dominicanRepublic: 0,
        egypt: 380,
        experience: 157,
        family: 0,
        foodExperience: 327,
        gramworthy: 138,
        historical: 474,
        india: 746,
        italy: 0,
        japan: 0,
        natureActivities: 0,
        newZealand: 173,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 210,
        spain: 0,
        winterActivities: 0
    },
    32: {
        image: image32,
        adventure: 222,
        beaches: 0,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 81,
        family: 514,
        foodExperience: 0,
        gramworthy: 160,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 204,
        newZealand: 0,
        nightlife: 0,
        relaxation: 412,
        sightseeing: 0,
        spain: 0,
        winterActivities: 1200
    },
    33: {
        image: image33,
        adventure: 260,
        beaches: 0,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 190,
        family: 0,
        foodExperience: 0,
        gramworthy: 251,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 319,
        newZealand: 0,
        nightlife: 0,
        relaxation: 363,
        sightseeing: 0,
        spain: 0,
        winterActivities: 1410
    },
    34: {
        image: image34,
        adventure: 108,
        beaches: 0,
        celebration: 700,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 157,
        family: 0,
        foodExperience: 0,
        gramworthy: 0,
        historical: 0,
        india: 0,
        italy: 286,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 1250,
        relaxation: 0,
        sightseeing: 0,
        spain: 292,
        winterActivities: 0
    },
    35: {
        image: image35,
        adventure: 141,
        beaches: 0,
        celebration: 1533,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 103,
        family: 328,
        foodExperience: 0,
        gramworthy: 272,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 415,
        spain: 0,
        winterActivities: 0
    },
    36: {
        image: image36,
        adventure: 213,
        beaches: 0,
        celebration: 0,
        culture: 140,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 117,
        family: 371,
        foodExperience: 0,
        gramworthy: 0,
        historical: 0,
        india: 0,
        italy: 848,
        japan: 0,
        natureActivities: 131,
        newZealand: 0,
        nightlife: 0,
        relaxation: 396,
        sightseeing: 0,
        spain: 577,
        winterActivities: 0
    },
    37: {
        image: image37,
        adventure: 103,
        beaches: 781,
        celebration: 148,
        culture: 90,
        dominicanRepublic: 371,
        egypt: 0,
        experience: 50,
        family: 159,
        foodExperience: 0,
        gramworthy: 132,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 84,
        newZealand: 494,
        nightlife: 0,
        relaxation: 381,
        sightseeing: 0,
        spain: 0,
        winterActivities: 0
    },
    38: {
        image: image38,
        adventure: 91,
        beaches: 417,
        celebration: 198,
        culture: 120,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 67,
        family: 0,
        foodExperience: 0,
        gramworthy: 220,
        historical: 201,
        india: 0,
        italy: 364,
        japan: 0,
        natureActivities: 224,
        newZealand: 0,
        nightlife: 0,
        relaxation: 340,
        sightseeing: 179,
        spain: 371,
        winterActivities: 0
    },
    39: {
        image: image39,
        adventure: 70,
        beaches: 480,
        celebration: 152,
        culture: 0,
        dominicanRepublic: 380,
        egypt: 0,
        experience: 51,
        family: 488,
        foodExperience: 0,
        gramworthy: 135,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 172,
        newZealand: 338,
        nightlife: 0,
        relaxation: 130,
        sightseeing: 0,
        spain: 0,
        winterActivities: 0
    },
    4: {
        image: image4,
        adventure: 97,
        beaches: 0,
        celebration: 0,
        culture: 383,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 213,
        family: 0,
        foodExperience: 666,
        gramworthy: 47,
        historical: 0,
        india: 759,
        italy: 129,
        japan: 234,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 0,
        spain: 264,
        winterActivities: 0
    },
    40: {
        image: image40,
        adventure: 233,
        beaches: 0,
        celebration: 0,
        culture: 306,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 170,
        family: 0,
        foodExperience: 532,
        gramworthy: 112,
        historical: 0,
        india: 1212,
        italy: 0,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 228,
        spain: 0,
        winterActivities: 0
    },
    41: {
        image: image41,
        adventure: 235,
        beaches: 0,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 86,
        family: 273,
        foodExperience: 0,
        gramworthy: 226,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 288,
        newZealand: 1132,
        nightlife: 0,
        relaxation: 437,
        sightseeing: 115,
        spain: 0,
        winterActivities: 0
    },
    42: {
        image: image42,
        adventure: 123,
        beaches: 0,
        celebration: 532,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 269,
        family: 0,
        foodExperience: 280,
        gramworthy: 118,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 1139,
        relaxation: 0,
        sightseeing: 0,
        spain: 332,
        winterActivities: 0
    },
    43: {
        image: image43,
        adventure: 307,
        beaches: 0,
        celebration: 222,
        culture: 134,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 224,
        family: 0,
        foodExperience: 0,
        gramworthy: 99,
        historical: 226,
        india: 0,
        italy: 272,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 200,
        spain: 1109,
        winterActivities: 0
    },
    44: {
        image: image44,
        adventure: 251,
        beaches: 0,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 92,
        family: 0,
        foodExperience: 0,
        gramworthy: 121,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 303,
        natureActivities: 308,
        newZealand: 0,
        nightlife: 0,
        relaxation: 233,
        sightseeing: 123,
        spain: 0,
        winterActivities: 1362
    },
    45: {
        image: image45,
        adventure: 77,
        beaches: 700,
        celebration: 249,
        culture: 101,
        dominicanRepublic: 624,
        egypt: 0,
        experience: 56,
        family: 0,
        foodExperience: 0,
        gramworthy: 111,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 188,
        newZealand: 185,
        nightlife: 0,
        relaxation: 285,
        sightseeing: 0,
        spain: 0,
        winterActivities: 0
    },
    46: {
        image: image46,
        adventure: 90,
        beaches: 616,
        celebration: 195,
        culture: 0,
        dominicanRepublic: 366,
        egypt: 0,
        experience: 66,
        family: 627,
        foodExperience: 0,
        gramworthy: 0,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 221,
        newZealand: 108,
        nightlife: 0,
        relaxation: 251,
        sightseeing: 0,
        spain: 0,
        winterActivities: 0
    },
    47: {
        image: image47,
        adventure: 86,
        beaches: 0,
        celebration: 0,
        culture: 226,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 63,
        family: 0,
        foodExperience: 196,
        gramworthy: 0,
        historical: 0,
        india: 112,
        italy: 0,
        japan: 829,
        natureActivities: 106,
        newZealand: 0,
        nightlife: 0,
        relaxation: 160,
        sightseeing: 84,
        spain: 0,
        winterActivities: 932
    },
    48: {
        image: image48,
        adventure: 86,
        beaches: 0,
        celebration: 187,
        culture: 113,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 126,
        family: 0,
        foodExperience: 197,
        gramworthy: 0,
        historical: 0,
        india: 0,
        italy: 687,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 802,
        relaxation: 0,
        sightseeing: 127,
        spain: 468,
        winterActivities: 0
    },
    49: {
        image: image49,
        adventure: 85,
        beaches: 0,
        celebration: 0,
        culture: 223,
        dominicanRepublic: 115,
        egypt: 226,
        experience: 124,
        family: 0,
        foodExperience: 582,
        gramworthy: 0,
        historical: 0,
        india: 885,
        italy: 0,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 197,
        relaxation: 0,
        sightseeing: 0,
        spain: 115,
        winterActivities: 0
    },
    5: {
        image: image5,
        adventure: 317,
        beaches: 0,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 231,
        family: 0,
        foodExperience: 0,
        gramworthy: 102,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 508,
        natureActivities: 388,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 103,
        spain: 0,
        winterActivities: 1144
    },
    6: {
        image: image6,
        adventure: 0,
        beaches: 0,
        celebration: 247,
        culture: 150,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 167,
        family: 0,
        foodExperience: 0,
        gramworthy: 330,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 550,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 530,
        relaxation: 0,
        sightseeing: 335,
        spain: 0,
        winterActivities: 0
    },
    7: {
        image: image7,
        adventure: 366,
        beaches: 0,
        celebration: 0,
        culture: 160,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 268,
        family: 425,
        foodExperience: 0,
        gramworthy: 353,
        historical: 0,
        india: 0,
        italy: 0,
        japan: 0,
        natureActivities: 449,
        newZealand: 294,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 477,
        spain: 0,
        winterActivities: 0
    },
    8: {
        image: image8,
        adventure: 100,
        beaches: 0,
        celebration: 0,
        culture: 263,
        dominicanRepublic: 0,
        egypt: 0,
        experience: 146,
        family: 0,
        foodExperience: 457,
        gramworthy: 96,
        historical: 331,
        india: 0,
        italy: 0,
        japan: 1205,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 196,
        spain: 0,
        winterActivities: 0
    },
    9: {
        image: image9,
        adventure: 189,
        beaches: 0,
        celebration: 0,
        culture: 0,
        dominicanRepublic: 0,
        egypt: 1255,
        experience: 138,
        family: 0,
        foodExperience: 0,
        gramworthy: 182,
        historical: 625,
        india: 0,
        italy: 126,
        japan: 0,
        natureActivities: 0,
        newZealand: 0,
        nightlife: 0,
        relaxation: 0,
        sightseeing: 277,
        spain: 0,
        winterActivities: 0
    },
};