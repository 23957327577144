import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const SearchUsers = async (searchQuery) => {
    try {
        const usersRef = collection(db, "Users");

        let userNameSnapshot, emailSnapshot, nameSnapshot;

        //Determine search field
        if (searchQuery.includes('@') && !searchQuery.startsWith('@')) {
            console.log("Searching by email");
            const emailQuery = query(usersRef, where("email", "==", searchQuery));
            emailSnapshot = await getDocs(emailQuery);
        } else if (searchQuery.includes(' ')) {
            console.log("Searching by full name");
            const [firstName, lastName] = searchQuery.split(' ');
            const capitalizedFirstName = capitalize(firstName);
            const capitalizedLastName = capitalize(lastName);
            const nameQuery = query(usersRef, where("firstName", "==", capitalizedFirstName), where("lastName", "==", capitalizedLastName));
            nameSnapshot = await getDocs(nameQuery);
        } else {
            console.log("Searching by username and first name");
            if (searchQuery.startsWith('@')) {
                searchQuery = searchQuery.slice(1).trim();
            }
            const userNameQuery = query(usersRef, where("userName", "==", searchQuery));
            userNameSnapshot = await getDocs(userNameQuery);

            const [firstName, lastName] = searchQuery.split(' ');
            const capitalizedFirstName = capitalize(firstName);
            const nameQuery = query(usersRef, where("firstName", "==", capitalizedFirstName));
            nameSnapshot = await getDocs(nameQuery);
        }

        const users = [];

        if (userNameSnapshot) {
            userNameSnapshot.forEach((doc) => {
                const userData = doc.data();
                users.push({
                    id: doc.id,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    userName: userData.userName,
                });
            });
        }

        if (emailSnapshot) {
            emailSnapshot.forEach((doc) => {
                const userData = doc.data();
                users.push({
                    id: doc.id,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    userName: userData.userName,
                });
            });
        }

        if (nameSnapshot) {
            nameSnapshot.forEach((doc) => {
                const userData = doc.data();
                users.push({
                    id: doc.id,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    userName: userData.userName,
                });
            });
        }

        console.log(users);
        return users;
    } catch (error) {
        console.error("Error searching users:", error);
        return [];
    }
}

export default SearchUsers;