import styled from "styled-components";

export const FriendsPageStyles = styled.div`

.friends-page {
    position: relative;
    width: 100%;
    margin-top: 2%;
}

.friends-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 1em;
    min-height: 600px;
    margin-bottom: 1em;
}

h3 {
    font-size: 1.2em;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 0px;
    padding-left: 10px;
}

.current-friends {
    width: 40%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    min-height: 100%;
}

.groups-container {
    width: 30%;
}

.friend-groups {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    min-height: 100%;
}

.friend-groups-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
}

.friend-groups-header h3 {
    border-bottom: none;
}

.create-group-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    font-weight: bold;
}

.group-object {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .5em .5em;
    align-items: center;
}

.view-group-button {
    border: none;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    font-size: 10pt;
    font-family "Myriad Pro";
    background-color: #005fff;
    color: white;
    max-height: 35px;
}

.requests-container {
    width: 25%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.friend-requests {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    height: 100%;
}

ul {
    padding: 0;
    margin: 0;
    background-color: #ffffff;
    list-style-type: none;
}

.friend-object {
    width: 100%;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 50px;
    align-items: center;
    padding: 0 .5em;
}

.friend-info {
    display: flex;
    flex-direction: row;
    gap: .5em;
    align-items: center;
}

.friend-profile-photo {
    width: 2.5em;
    height: 2.5em;
    border: 2px solid grey;
    border-radius: 25px;
}

.friend-identifiers {
    display: flex;
    flex-direction: column;
}

h4 {
    margin: 0;
    font-size: 1.2em;    
    padding-top: 5px;
    white-space: nowrap; /* Prevent text from wrapping onto a new line */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis;
}

p {
    margin: 0;
    font-size: .9em;
}
    
.buttons-container {
    display: flex;
    gap: 10px;
}

.merge-button {
    border: none;
    border-radius: 5px;
    text-align: center;
    font-size: 10pt;
    font-family "Myriad Pro"
    align-self: flex-end;
    background-color: #009999;
    color: white;
}

.merge-icon {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    margin-bottom: 3px;
    filter: invert(1);
}

.unfriend-button {
    border: none;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    font-size: 10pt;
    font-family "Myriad Pro"
    align-self: flex-end;
    background-color: red;
    color: white;
}

.unfriend-icon {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    margin-bottom: 3px;
    filter: invert(1);
}

.request-object {
    width: 100%;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 50px;
    align-items: center;
    padding: 0px .5em;
}

.accept-button, .decline-button {
    border: none;
    background: none;
    text-align: center;
    justify-content: center;
    padding: 0;
}

.checkmark-icon, .x-icon {
    width: 25px;
    height: 25px;
}

.empty-text {
    text-align: center;
    padding-top: 10px;
}

@media (max-width: 1300px) {
    .current-friends h4, .friend-requests h4 {
        font-size: 1em;
    }

    .buttons-container {
        gap: 5px;
    }
}

@media (max-width: 1052px) {
    .friends-page {
        margin: 0;
    }

    .friend-requests {
        min-height: 100px;
    }
}

@media (max-width: 900px) {
    .friends-container {
        flex-direction: column;
        gap: 20px;
        min-height: 0;
    }

    .current-friends, .groups-container, .requests-container {
        width: 100%;
        min-height: none;
    }

    .requests-container {
        order: 1;
    }

    .groups-container {
        order: 2;
    }

    .current-friends {
        order: 3;
    }
}
`;