import styled from "styled-components";
export const TripSuggestionStyles = styled.div`
  .suggestion-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .destination{
    margin: .5em;
    color: #009999;
    font-family: "Myriad Pro";
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
  }

  .ranking{
    margin: 1.25em;
  }

  .cost{
    color: #009999;
    font-family: "Myriad Pro";
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
  }

  .title{
    color: #005e84;
    font-family: "Myriad Pro";
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .row > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }

  input[type="checkbox"] {
    position: relative;
    visibility: hidden;
    cursor: pointer;
  }

  input[type="checkbox"]:after {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    visibility: visible;
    height: 14px;
    line-height: 0px;
    width: 14px;
    text-align: center;
    border: 1px solid green;
    background: white;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }

  input[type="checkbox"]:checked:after {
    background: #009999;
    
    color: white;
    height: 14px;
    line-height: 10px;
    width: 14px;
    text-align: center;
  }

  .styled-thumb{
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: white;
    color: #fff;
    border-radius: 50%;
    border: 2px solid #009999;
    cursor: grab;
  }

  .package-location{
    font-family: "Myriad Pro";
    font-size: 14pt;
    font-weight: bold;
  }

  .slider-label{
    color: #005E84;
  }
  
  .package-description{
    font-family: "Myriad Pro";
    font-size: 28pt;
    color: #009999;
  }

  input[type=reset] {
    background-color: white;
    border: none;
    text-decoration: none;
    color: #009DFF;
    content: "clear filter";
    cursor: pointer;
 }

  form{
    font-family: "Myriad Pro";
    color: #005E84;
  }

  .box {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background-color: #fff;
    height: 100%;
  }

  .filter-label{
    font-family: "Myriad Pro";
    font-weight: bold;
  }

  .reserve-button{
    color: white;
    background-color: #009999;
    border:none;
    font-family: "Myriad Pro"
    font-size: 20pt;
    width: 100%;
    max-width: 10rem;

  }
  
  .package-rating{
    font-family: "Myriad Pro";
    font-size: 16pt;
    color: #009999;
    padding-left: 130px;
  }
  
 .column{
    flex-direction: column;
 }
  
  .star-row{
    position: relative;
  }
  
  .stars{
    position: absolute;
    bottom:25%;
    left: -0.1%;
  }
  
  .package-header{
    font-family: "Myriad Pro"
    font-weight: bold;
    color: #005E84;
  }
    
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5em 1em 1em;
  }

  .title-container {
    flex: 1;
  }

  .description-container {
    flex: 1;
    text-align: right;
  }

  .your-preferences {
    font-family: "Myriad Pro";
    color: #005e84;
    font-weight: bold;
    font-size: 300%;
  }
  
  .description {
    color: #9d9d9d;
    font-family: "Myriad Pro";
  }

  @media (max-width: 745px) {
    .your-preferences {
      font-size: 6vw;
    }

    .description {
      font-size: 75%;
    }
  }
  `;
