import { db } from "../../firebase";
import { getDocs, collection, doc, getDoc } from "firebase/firestore";

const fetchFriendGroups = async (userId) => {
   try {
        //Get all group doc ids
        const userGroupsRef = collection(db, "Users", userId, "groups");
        const userGroupsSnapshot = await getDocs(userGroupsRef);

        //Get group data
        const groups = [];

        for (const docSnapshot of userGroupsSnapshot.docs) {
            const groupId = docSnapshot.id;

            const groupDocRef = doc(db, "Groups", groupId);
            const groupDoc = await getDoc(groupDocRef);

            if (groupDoc.exists()) {
                groups.push({ id: groupId, ...groupDoc.data() });
            }
        }

        return groups;
   } catch (error) {
        console.error("Error fetching friend groups:", error.message);
        return [];
   }
};

export default fetchFriendGroups;