import { useEffect, useState } from "react";
import fetchCurrentFriends from "../friends/fetchCurrentFriends";
import { SelectFriendStyles } from "./SelectFriendStyles";
import fetchUserProfile from "../friends/fetchUserProfile";

const SelectFriendForMerge = ({ userId, onClose, onSelect, loading, error, success }) => {
    const [currentFriends, setCurrentFriends] = useState([]);
    const [err, setErr] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                const currentFriendsData = await fetchCurrentFriends(userId);

                const detailedFriends = await Promise.all(currentFriendsData.map(async friend => {
                    const profile = await fetchUserProfile(friend.userId);
                    return {
                        ...friend,
                        ...profile,
                    };
                }));

                setCurrentFriends(detailedFriends);
            } catch (error) {
                console.error("Error fetching friends in SelectFriendForMerge:", error.message);
                setErr("Error fetching friends.");
            }
            
        };

        if (userId) {
            loadData();
        }
    }, [userId]);

    if (err) {
        return (
            <SelectFriendStyles>
                <h3>Error</h3>
                <p className="message">Error: {err}</p>
            </SelectFriendStyles>
        )
    }

    return (
        <SelectFriendStyles>
            <div className="modal-overlay">
                <div className="modal-content">
                    <h3>Choose friends to add to group</h3>
                    <button className="close-button" onClick={onClose}>x</button>

                    {loading ? (
                        <p className="message">Loading...</p>
                    ) : error ? (
                        <p className="message">{error}</p>
                    ) : success ? (
                        <p className="message">Group created successfully!</p>
                    ) : (
                    <>
                    <div className="friends-section">
                        <h4>Current Friends</h4>
                        <div className="select-friends-container">
                            {currentFriends.length > 0 ? (
                                currentFriends.map(friend => (
                                    <div key={friend.userId} className="friend-item">
                                        <div className="friend-info">
                                            <img src={friend.profileImage} className="profile-image"/>
                                            <h5>{friend.name} @{friend.userName}</h5>
                                        </div>
                                        <button className="select-button" onClick={() => onSelect(friend.userId)}>Select</button>
                                    </div>
                                ))
                            ) : (
                                <p>No current friends.</p>
                            )}
                        </div>
                        <button className="submit-button">
                            Submit
                        </button>
                    </div>
                    </>
                    )}
                </div>
            </div>
        </SelectFriendStyles>
    )
};

export default SelectFriendForMerge;