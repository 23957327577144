import { db } from "../../firebase";
import { getDocs, collection } from "firebase/firestore";

const fetchMergeRequests = async (userId) => {
    try {
        const mergeRequestsRef = collection(db, `Users/${userId}/mergeRequests`);
        const mergeRequestsSnapshot = await getDocs(mergeRequestsRef);
        
        const mergeRequests = [];
        mergeRequestsSnapshot.forEach((doc) => {
            mergeRequests.push({
                id: doc.id,
                ...doc.data()
            });
        });

        return mergeRequests;
    } catch (error) {
        console.error('Error fetching merge requests:', error);
        return [];
    }
};

export default fetchMergeRequests;