import { db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";

const sendFriendRequest = async (fromUserId, toUserId, fromUserFirstName, fromUserLastName, fromUserUserName) => {
    const friendRequestsReceivedRef = doc(db, `Users/${toUserId}/friendRequestsReceived/${fromUserId}`);
    const friendRequestsSentRef = doc(db, `Users/${fromUserId}/friendRequestsSent/${toUserId}`)
    
    await setDoc(friendRequestsReceivedRef, {
        from: `${fromUserFirstName} ${fromUserLastName}`,
        fromUserName: fromUserUserName
    });

    await setDoc(friendRequestsSentRef, {
        to: `${toUserId}`
    });
};

export default sendFriendRequest;