import styled from "styled-components";

export const GameStyles = styled.div`

  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
  }
  
  @media (prefers-color-scheme: dark) {
    :root {
      --foreground-rgb: 255, 255, 255;
      --background-start-rgb: 0, 0, 0;
      --background-end-rgb: 0, 0, 0;
    }
  }
  
  body {
    color: rgb(var(--foreground-rgb));
    background: linear-gradient(
        to bottom,
        transparent,
        rgb(var(--background-end-rgb))
      )
      rgb(var(--background-start-rgb));
  }

  .popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%); /* Center horizontally and vertically */
    background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    max-width: 90%;
    width: 400px;
    z-index: 1000;
  }

  .text-container {
    text-align: center;
    margin-bottom: 20px;
  }

  .popup-container h1 {
    margin: 10px 0;
    font-size: 250%;
  }

  .popup-container h3 {
    margin: 10px 0;
    font-size: 100%;
  }

  .popup-container p {
    margin: 10px 0;
    font-size: 85%;
  }

  .popup-button {
    display: block;
    width: 100px;
    margin: 0 auto;
    padding: 10px;
    background-color: #009999;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .popup-button:hover {
    background-color: #005e84; /* Darker button on hover */
  }

  .game-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em 0;
  }

  h1 {
    font-family: "Myriad Pro";
    color: #005e84;
    font-weight: bold;
    font-size: 300%;
    text-align: center;
    margin: 0;
  }

  h3 {
    font-family: "Myriad Pro";
    color: #9d9d9d;
    text-align: center;
    font-size: 125%;
  }

  p {
    color: #9d9d9d;
    font-family: "Myriad Pro";
    text-align: center;
  }

  .images-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
  }

  .image-container {
    position: relative;
    display: inline-block;
  }
  
  .pair-image {
    width: 400px;
    height: 550px;
    border-radius: 15px;
    box-shadow: 0 4px 5px 2px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 1005px) {
    .pair-image {
      width: 300px;
      height: 450px;
    }
  }

  @media (max-width: 830px) {
    h1 {
      font-size: 250%;
    }

    h3 {
      font-size: 100%;
    }

    .pair-image {
      width: 250px;
      height: 375px;
    }

    .results-button {
      font-size: 18pt !important;
    }
  }

  @media (max-width: 688px) {
    .pair-image {
      width: 235px;
      height: 360px;
    }

    .results-button {
      font-size: 15pt !important;
    }
  }

  @media (max-width: 634px) {
    .images-wrapper {
      flex-direction: column;
      gap: 1em; 
    }
    
    .pair-image {
      width: 250px;
      height: 350px;
    }
  }
  
  @media (max-width: 502px) {
    h1 {
      font-size: 9vw;
    }

    p {
      font-size: 3vw;
    }
  }

  .pair-image:hover {
    border: 5px solid green;
    border-radius: 15px;
  }
  
  .pair-image.selected {
    border: 5px solid green;
    border-radius: 15px;
  }

  .pair-image.not-selected:hover {
    border: none;
    border-radius: 15px;
  }
  
  .heart {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);  width: 20%;  
    height: 15%;  
    opacity: 0;
  }
  
  .heart.animate {
    opacity: 1;
    filter: brightness(200%) opacity(85%);
    animation: fadeInAndGrow 0.2s ease forwards;
  }
  
  @keyframes fadeInAndGrow {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5);
    }
    80% {
      transform: translate(-50%, -50%) scale(1.1)
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .results-button {
    border: none;
    border-radius: 5px;
    height: 10%;
    text-align: center;
    justify-content: center;
    font-size: 22pt;
    font-family "Myriad Pro"
    align-self: flex-end;
    background-color: #009999;
    color: white;
  }

  .results-button:hover {
    box-shadow: 0 0 5px 2px rgba(0, 128, 128, 0.8);
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
  }

  .continue-button {
    border: none;
    border-radius: 5px;
    min-height: 60px;
    min-width: 160px;
    text-align: center;
    justify-content: center;
    font-size: 22pt;
    font-family "Myriad Pro"
    align-self: flex-end;
    background-color: #009999;
    color: white;
  }
  
  @layer utilities {
    .text-balance {
      text-wrap: balance;
    }
  }

`;