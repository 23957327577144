import { GroupDetailsStyles } from "./GroupDetailsStyles";
import mergeIcon from "../../Assets/Images/merge.png";
import { useState } from "react";
import deleteFriendGroup from "./deleteFriendGroup";
import SelectResultsForMerge from "../merge-helpers/SelectResultsForMerge";
import createGroupMergeRequest from "../merge-helpers/createGroupMergeRequest";

const FriendGroupDetails = ({ userId, group, members, deleteGroupDisplay, sendFriendRequest, onClose }) => {
    const [isDeletingGroup, setIsDeletingGroup] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isMergingResults, setIsMergingResults] = useState(false);
    const [addedFriends, setAddedFriends] = useState({});

    const removeMember = (memberId) => {
        try {

        } catch (error) {
            setError(error.message);
        }
    };

    const handleDeleteGroup = async () => {
        setLoading(true);
        setSuccess(false);
        setError(null);

        try {
            await deleteFriendGroup(group.id);
            setSuccess(true);
            deleteGroupDisplay(group.id);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const onMergeCancel = () => {
        setIsMergingResults(false);
    };

    const handleResultSelect = async (result, subcollection) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            await createGroupMergeRequest(group, userId, result, subcollection);
            setSuccess(true);
        } catch (error) {
            setError("Error sending merge request:", error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleAddFriend = (memberId) => {
        sendFriendRequest(memberId);

        setAddedFriends((prev) => ({
            ...prev,
            [memberId]: true
        }));
    };

    if (isMergingResults) {
        return (
            <SelectResultsForMerge 
                userId={userId}
                onClose={onMergeCancel}
                onSelect={handleResultSelect}
                loading={loading}
                error={error}
                success={success}
            />
        )
    }

    if (isDeletingGroup) {
        return (
            <GroupDetailsStyles>
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>{group.groupName}</h3>
                        <button className="close-button" onClick={onClose}>x</button>
                        <div className="message-section">
                            {loading ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>Error deleting group: {error}</p>
                            ) : success ? (
                                <p>Friend group deleted successfully.</p>
                            ) : (
                                <h4>Are you sure you want to delete this group?</h4>
                            )}
                        </div>
                        {loading || success || error ? (
                            <></>
                        ) : (
                            <div className="buttons-section">
                                <button className="cancel-button" onClick={() => setIsDeletingGroup(false)}>Cancel</button>
                                <button className="remove-button" onClick={() => handleDeleteGroup()}>Delete</button>
                            </div>
                        )}
                    </div>
                </div>
            </GroupDetailsStyles>
        )
    }

    return (
        <GroupDetailsStyles>
            <div className="modal-overlay">
                <div className="modal-content">
                    <h3>{group.groupName}</h3>
                    <button className="close-button" onClick={onClose}>x</button>
                    <div className="members-section">
                        <h4 className="section-title">Members</h4>
                        <ul className="members-list">
                            {members.map((member) => (
                                <li key={member.userId} className="friend-object">
                                    <div className="friend-info">
                                        <img src={member.profileImage} className="friend-profile-photo" alt="profile-picture" />
                                        <div className="friend-identifiers">
                                            <h4>{member.fullName}</h4>
                                            <p>@{member.userName}</p>
                                        </div>
                                    </div>
                                    <div className="buttons-container">
                                        {group.createdBy === userId ? (
                                            <button className="remove-button" onClick={() => removeMember(member.userId)}>Remove</button>
                                        ) : member.isFriend ? (
                                            <button className="add-friend-button" disabled>Friends</button>
                                        ) : (
                                            <button
                                                className="add-friend-button"
                                                onClick={() => handleAddFriend(member.userId)}
                                                disabled={addedFriends[member.userId]} // Disable if already added
                                            >
                                                {addedFriends[member.userId] ? "Request sent" : "Add Friend"}
                                            </button>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="buttons-section">
                        <button className="merge-button" onClick={() => setIsMergingResults(true)}>
                            Group Merge
                            <img src={mergeIcon} className="merge-icon"/>
                        </button>
                        <button className="delete-group-button" onClick={() => setIsDeletingGroup(true)}>Delete Group</button>
                    </div>
                </div>
            </div>
        </GroupDetailsStyles>
    )
};

export default FriendGroupDetails;