import { db } from "../../firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";

const createMergeRequest = async (userId, name, friendId, result, subcollection) => {
    try {
        const mergeRequestDocRef = doc(db, `Users/${friendId}/mergeRequests/${userId}`);

        // Check if a merge request already exists
        const existingRequest = await getDoc(mergeRequestDocRef);

        if (existingRequest.exists()) {
            throw new Error('A pending merge request already exists. Please wait until this request has been accepted');
        }

        const mergeRequestData = {
            resultId: result.id,
            fromUserName: name,
            subcollection: subcollection,
        };

        await setDoc(mergeRequestDocRef, mergeRequestData);
    } catch (error) {
        console.error('Error sending merge request:', error);
        throw new Error(error.message || 'Failed to send merge request');
    }
}

export default createMergeRequest;