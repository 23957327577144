import styled from "styled-components";

export const ProfileNavStyles = styled.div`
    .profile-nav {
        padding-left: 1em;
    }

    ul {
        list-style: none;
    }

    li {
        color: #009999;
        font-size: 100%;
        margin-bottom: 15px;
    }

    .active {
        text-decoration: underline;
        color: #1e647b;
        text-decoration-color: #fcbb07;
        text-decoration-thickness: 2px;
    }

    li:hover {
        font-weight: bold;
        opacity: .8;
        cursor: pointer;
    }

    @media (max-width: 1052px) {
        .profile-nav {
            padding: 0;
        }

        ul {
            display: flex;
            flex-direction: row;
            padding: 0;
            margin: 0;
            justify-content: space-between;
            gap: 20px;
        }

        li {
            margin: 0;
            padding: 0;
        }
    }
`;