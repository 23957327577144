import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

const fetchUserProfile = async (userId) => {
    const docRef = doc(db, 'Users', userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        console.error('No such document!');
        return {};
    }
};

export default fetchUserProfile;