import React from 'react';
import { ModalStyles } from './ModalStyles';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <ModalStyles>
            <div className="modal-overlay">
                <div className="modal-content">
                    <button className="close-button" onClick={onClose}>x</button>
                    {children} {/* Render whatever is passed inside the modal */}
                </div>
            </div>
        </ModalStyles>
    );
};

export default Modal;