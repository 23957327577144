import React, { useState } from 'react';
import { ModalStyles } from './SignInSignUpStyles.js';
import { useUserAuth } from '../../UserAuthContext.js';

export default function SignInSignUpModal({ onClose, handleAuth, heading }) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const { signUp, logIn } = useUserAuth();

  const handleToggle = () => {
    setIsSignUp(!isSignUp);
    setError(''); // Clear any previous errors
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSignUp && password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    try {
      if (isSignUp) {
        const capitalizedFirstName = capitalize(firstName);
        const capitalizedLastName = capitalize(lastName);
        await signUp(email, password, capitalizedFirstName, capitalizedLastName);
      } else {
        await logIn(email, password);
      }
      onClose();
    } catch (err) {
      setError("Error in sign in:", err.message);
    }
  };

  return (
    <div style={ModalStyles.modalContent}>
      <h2 style={ModalStyles.h2}>{heading}</h2>
      <h3 style={ModalStyles.h3}>{isSignUp ? 'Sign Up' : 'Sign In'}</h3>
      <form onSubmit={handleSubmit}>
      {isSignUp && (
       <>
        <div style={ModalStyles.inputRow}>
          <div style={{ ...ModalStyles.inputGroup, ...ModalStyles.inputHalf }}>
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              style={ModalStyles.input}
            />
          </div>
          <div style={{ ...ModalStyles.inputGroup, ...ModalStyles.inputHalf }}>
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              style={ModalStyles.input}
            />
          </div>
        </div>
       </>
      )}
        <div style={ModalStyles.inputGroup}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={ModalStyles.input}
          />
        </div>
        <div style={ModalStyles.inputGroup}>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={ModalStyles.input}
          />
        </div>
        {isSignUp && (
          <div style={ModalStyles.inputGroup}>
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              style={ModalStyles.input}
            />
          </div>
        )}
        {error && <p style={ModalStyles.error}>{error}</p>}
        <button type="submit" style={ModalStyles.submitButton}>
          {isSignUp ? 'Sign Up' : 'Sign In'}
        </button>
      </form>
      <div style={ModalStyles.buttonRow}>
        <button onClick={handleToggle} style={ModalStyles.toggleButton}>
          {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
        </button>
        <button onClick={onClose} style={ModalStyles.closeButton}>Skip</button>
      </div>
    </div>
  );
}